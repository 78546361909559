import { createReducer, on } from '@ngrx/store';
import { STATE_STATUS } from 'src/app/utils/enums/mantle-enums';
import { MailingTemplateState } from 'src/app/utils/interfaces/app.state';
import {
  deleteEmailTemplate,
  deleteEmailTemplateFailure,
  deleteEmailTemplateSuccess,
  loadEmailTemplates,
  loadEmailTemplatesFailure,
  loadEmailTemplatesSuccess,
  sendEmail,
  sendEmailFailure,
  sendEmailSuccess,
  upsertEmailTemplate,
  upsertEmailTemplateFailure,
  upsertEmailTemplateSuccess,
} from './mailing_template.actions';

export const initialTemplateState: MailingTemplateState = {
  templates: [],
  error: null,
  status: STATE_STATUS.PENDING,
};

export const mailingTemplateReducer = createReducer(
  initialTemplateState,
  on(loadEmailTemplates, (state) => ({
    ...state,
    status: STATE_STATUS.LOADING,
  })),

  on(loadEmailTemplatesSuccess, (state, { templates }) => ({
    ...state,
    templates,
    error: null,
    status: STATE_STATUS.SUCCESS,
  })),

  on(loadEmailTemplatesFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: STATE_STATUS.ERROR,
  })),

  on(upsertEmailTemplate, (state) => ({
    ...state,
    status: STATE_STATUS.LOADING,
  })),

  on(upsertEmailTemplateSuccess, (state, { template }) => ({
    ...state,
    templates: mergeStateArray(state.templates, template),
    activeRecord: template,
    error: null,
    status: STATE_STATUS.SUCCESS,
  })),

  on(upsertEmailTemplateFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: STATE_STATUS.ERROR,
  })),

  on(deleteEmailTemplate, (state) => ({
    ...state,
    status: STATE_STATUS.LOADING,
  })),

  on(deleteEmailTemplateSuccess, (state, { template_id }) => ({
    ...state,
    templates: state.templates?.filter((x) => x.template_id != template_id),
    activeRecord: null,
    error: null,
    status: STATE_STATUS.SUCCESS,
  })),

  on(deleteEmailTemplateFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: STATE_STATUS.ERROR,
  })),
  on(sendEmail, (state) => ({
    ...state,
    status: STATE_STATUS.LOADING,
  })),
  on(sendEmailSuccess, (state) => ({
    ...state,
    error: null,
    status: STATE_STATUS.SUCCESS,
  })),
  on(sendEmailFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: STATE_STATUS.ERROR,
  }))
);

const mergeStateArray = (templates: any[], template) => {
  const cloneState = JSON.parse(JSON.stringify(templates));

  const index = cloneState.findIndex(
    (x) => x.template_id == template.template_id
  );
  if (index < 0) {
    cloneState.push(template);
  } else {
    cloneState[index] = template;
  }
  return cloneState.sort((a, b) => a.template_id - b.template_id);
};
