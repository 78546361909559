import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { FormlyFormOptions } from '@ngx-formly/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
import { CommonService } from 'src/app/utils/services/common.service';
import { InventoryService } from 'src/app/utils/services/inventory.service';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';
import { ProductService } from 'src/app/utils/services/product.service';
import { VendorService } from 'src/app/utils/services/vendor.service';
import { MantleConfirmDialogComponent } from '../../mantle-confirm-dialog/mantle-confirm-dialog.component';
//import formConfig from '../../../../configs/forms/magic_form.json';
import { MantleProductDrawerComponent } from '../mantle-product-drawer/mantle-product-drawer.component';

@Component({
  selector: 'mantle-product-update',
  templateUrl: './mantle-product-update.component.html',
  styleUrls: ['./mantle-product-update.component.scss'],
})
export class MantleProductUpdateComponent implements OnInit, OnDestroy {
  @Input() public data;

  productPhotos = [];
  public imagePath;
  imageSrc: any;

  productDetailsForm = this.fb.group({});
  options: FormlyFormOptions = {};
  fields: any; //FormlyFieldConfig[];
  identifierFields: any; //FormlyFieldConfig[];
  model: any = {};
  form_data_available: boolean = false;
  data_toggle: Array<string>;
  data_toggle_1: Array<string>;
  client_data: any = {};
  mantle_data: any = {};
  productTags: Array<string> = [];
  preSelectedToggle: Array<String> = [];
  background_image: String = '';
  //Holds the no# of inventory counts being processed.
  processingChannelsCount = 0;
  private subscription: Subscription = new Subscription();
  drawerOpen: boolean = false;
  files: File[] = [];
  drawerComponent = MantleProductDrawerComponent;
  constructor(
    private fb: UntypedFormBuilder,
    private commonService: CommonService,
    private inventoryService: InventoryService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private productService: ProductService,
    private vendorService: VendorService,
    private mantleModalService: MantleModalService
  ) {
    //disables update button untill all channels are processed
    this.inventoryService.processing_channel_counter.subscribe(
      (count: number) => {
        this.processingChannelsCount = count;
      }
    );
    this.subscription.add(
      this.commonService.onSaveProduct.subscribe({
        next: (value) => {
          this.updateProductDetails();
        },
      })
    );
  }

  recursiveBinding(formObjConfigs) {
    if (formObjConfigs === null || formObjConfigs === undefined)
      return formObjConfigs;
    else if (formObjConfigs.constructor === Array) {
      formObjConfigs.forEach((obj) => {
        this.recursiveBinding(obj);
      });
    } else if (formObjConfigs === Object(formObjConfigs)) {
      if (
        formObjConfigs.templateOptions &&
        formObjConfigs.templateOptions.changeExpr
      ) {
        formObjConfigs.templateOptions.change = Function(
          'field',
          '$event',
          formObjConfigs.templateOptions.changeExpr
        ).bind(this);

        if (formObjConfigs.toggle != undefined) {
          formObjConfigs.defaultValue = this.defaultCheckboxValue(
            formObjConfigs.toggle,
            formObjConfigs
          );
        }
      } else if (
        formObjConfigs.templateOptions &&
        formObjConfigs.templateOptions.options &&
        formObjConfigs.templateOptions.lookupExpr
      ) {
        formObjConfigs.templateOptions.options = this.commonService.getLookupValue(
          'field',
          []
        );
      } else {
        Object.keys(formObjConfigs).forEach((key) => {
          this.recursiveBinding(formObjConfigs[key]);
        });
      }
    }

    return formObjConfigs;
  }

  ngOnInit(): void {
    this.getMantleToggles().subscribe(
      (res) => {
        this.data_toggle_1 =
          res['data_toggle'] !== null
            ? res['data_toggle']['mantle_selection']
            : null;
        this.initFormConfig();
      },
      (err) => {
        this.toastr.error(err.error, 'An Error Has Occurred');
      }
    );

    this.inventoryService.product_edit_loaded.next(false);

    this.inventoryService.inventory_item_updated.subscribe((data) => {
      this.productDetailsForm.patchValue(data);
    });

    this.vendorService.getVendors().subscribe({
      next: (res) => {
        this.options.formState.vendors = res.data.map((x) => {
          return { value: x.vendor_id, label: x.name };
        });
      },
      error: (err) => {},
      complete: () => {},
    });

    this.subscription.add(
      this.commonService.tabularInputRemoved.subscribe((data) => {
        if (data['name'] == 'vendor_product_ids') {
          this.removeVendorIdentifier(data['index'], data['autoRemove']);
        }
      })
    );
  }

  initFormConfig = async () => {
    await this.productService.getFormConfig(this.data['brand_id']).subscribe(
      (res) => {
        try {
          if (res[0].extras != undefined) {
            this.background_image = res[0].extras.background_image;
          }
        } catch (error) {}

        this.fields = res;
        //this.fields = formConfig;
        this.identifierFields = [
          {
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                wrappers: ['mantle-product-identifiers'],
                templateOptions: {
                  label: 'Identifiers',
                },
              },
            ],
          },
        ];
        this.fields = this.fields.map((f) => {
          f = this.recursiveBinding(f);
          this.spinner.hide();
          return f;
        });

        if (this.fields.length > 0) {
          this.form_data_available = true;
        }
        this.getProductDetails(false);
        this.getProductImages();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  };

  getProductDetails(pre_load?: boolean) {
    this.spinner.show();
    this.productService
      .getProductDetails({
        brand_id: this.data['brand_id'],
        child_sku: this.data['child_sku'],
        parent_sku: this.data['parent_sku'],
      })
      .subscribe(
        (res) => {
          this.client_data = this.commonService.JSONNullEmptyCleanup(
            res['client_data'],
            true
          );
          this.mantle_data = this.commonService.JSONNullEmptyCleanup(
            res['mantle_data'],
            true
          );
          this.data_toggle =
            res['data_toggle'] !== null
              ? res['data_toggle']['mantle_selection']
              : null;

          if (!pre_load) {
            //this.inventoryService.inventory_item_selected.next(this.data);

            this.toggleData();
          }
          this.spinner.hide();
          if (this.mantle_data == null) {
            this.toastr.error('Product information is no longer accessible.');
          }
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err.error, 'An Error Has Occurred');
        }
      );
  }

  //This is a work around
  getMantleToggles(): Observable<any> {
    this.spinner.show();
    return this.productService.getProductDetails({
      brand_id: this.data['brand_id'],
      child_sku: this.data['child_sku'],
      parent_sku: this.data['parent_sku'],
    });
  }

  toggleData() {
    const formValue =
      this.client_data !== null
        ? {
            ...this.client_data,
            ...this.mantle_data,
          }
        : this.mantle_data;
    this.model = formValue; //For formArray to work
    this.productDetailsForm.patchValue(formValue);

    if (this.data_toggle !== null && this.mantle_data !== null) {
      this.data_toggle.forEach((element) => {
        let str_obj =
          '{"' + element + '": "' + this.mantle_data[element] + '"}';

        this.productDetailsForm.patchValue(JSON.parse(str_obj));
        this.commonService.mantle_data_toggle.next({
          toggle: element,
          value: true,
        });
      });
    }

    this.emitProductFormData();
  }

  updateProductDetails() {
    this.spinner.show();
    this.productService
      .updateProductDetails(this.productDetailsForm.getRawValue())
      .subscribe({
        next: (res) => {
          this.productDetailsForm.patchValue(res);
          this.spinner.hide();
          this.toastr.success('Product Updated Successfully');
        },
        error: (error) => {
          this.spinner.hide();
          this.toastr.error(error?.error?.message);
        },
        complete: () => {
          this.spinner.hide();
        },
      });
    this.inventoryService.product_update_btn_clicked.next(null);
  }

  onFileChange(files) {
    if (files.length === 0) return;
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.toastr.warning('Only images are supported');
      this.imageSrc = null;
      return;
    }
    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imageSrc = reader.result;
    };
  }

  getProductImages() {
    this.productService
      .getImages({
        brand_id: this.data['brand_id'],
        child_sku: this.data['child_sku'],
        parent_sku: this.data['parent_sku'],
      })
      .subscribe(
        (res) => {
          this.productPhotos = res;
        },
        (err) => {}
      );
  }

  uploadImage() {
    if (this.imageSrc) {
      this.productService
        .createImage({
          brand_id: this.data['brand_id'],
          child_sku: this.data['child_sku'],
          image: this.imageSrc.toString(),
        })
        .subscribe(
          (res) => {
            this.productPhotos.push({
              id: res['id'],
              child_sku: this.data['child_sku'],
              image: this.imageSrc.toString(),
            });

            this.imageSrc = null;
            this.toastr.success('Image Uploaded');
          },
          (err) => {
            this.toastr.error(err.error, 'Image Upload Failed');
          }
        );
    }
  }

  onRemoveImage(index: number, id) {
    if (id !== undefined) {
      this.productService
        .deleteImage({
          id: id,
          child_sku: this.data['child_sku'],
        })
        .subscribe(
          (res) => {
            this.productPhotos.splice(index, 1);
          },
          (err) => {
            this.toastr.error(err.error, 'An Error Has Occurred');
          }
        );
    } else {
      this.productPhotos.splice(index, 1);
    }
  }

  toggleSource($event, field) {
    var toggled_value = null;
    if (field.toggle) {
      if ($event.target.checked) {
        if (this.mantle_data !== null) {
          toggled_value = this.mantle_data[field.toggle];
          toggled_value = toggled_value == undefined ? null : toggled_value;
        }

        this.productDetailsForm.patchValue({
          [field.toggle]: toggled_value,
        });

        this.updateProductToggle(true, field.toggle);
      } else {
        if (this.client_data !== null) {
          toggled_value = this.client_data[field.toggle];
          toggled_value = toggled_value == undefined ? null : toggled_value;
        }

        //let str_obj =
        //  '{"' + field.toggle + '": "' + toggled_value + '"}';

        this.productDetailsForm.patchValue({
          [field.toggle]: toggled_value,
        });
        this.updateProductToggle(false, field.toggle);
      }

      if (field.toggle == 'Color') {
        this.emitProductFormData();
      }
    }
  }

  updateProductToggle(value, field) {
    if (value) {
      if (this.data_toggle == null) {
        this.data_toggle = new Array<any>();
        this.data_toggle.push(field);
      } else if (this.data_toggle.indexOf(field) < 0) {
        this.data_toggle.push(field);
      }
    } else if (!value) {
      if (this.data_toggle != null) {
        this.data_toggle.splice(this.data_toggle.indexOf(field), 1);
      }
    }

    const payload = {
      brand_id: this.data['brand_id'],
      child_sku: this.data['child_sku'],
      mantle_selection: this.data_toggle,
    };
    this.productService.updateToggle(payload).subscribe(
      (res) => {},
      (err) => {}
    );
  }

  defaultCheckboxValue(toggle_name, formObjConfigs): boolean {
    if (toggle_name !== undefined && this.data_toggle_1 !== null) {
      if (this.data_toggle_1.indexOf(toggle_name) >= 0) {
        return true;
      }
    }
    return false;
  }

  onTagEdited($event) {}

  onTagAdded($event) {}

  onTagRemoved($event) {}

  //help update card wrapper bg colour
  emitProductFormData() {
    var mergerData = { ...this.client_data, ...this.mantle_data };
    mergerData.Color = this.productDetailsForm.value['Color'];
    this.commonService.product_form_value.next(mergerData);
  }

  removeVendorIdentifier = (index, autoRemove = false) => {
    var vendorId = this.productDetailsForm.getRawValue().vendor_product_ids[
      index
    ];
    if (!autoRemove) {
      const modalRef = this.mantleModalService.open(
        MantleConfirmDialogComponent,
        MANTLE_MODAL_NAME.CONFIRM_DIALOG,
        {
          ariaLabelledBy: 'modal-basic-title',
          size: 'sm',
          backdrop: 'static',
        }
      );
      modalRef.componentInstance.header = 'Remove Vendor Indetifier';
      modalRef.componentInstance.message =
        'By confriming this, Vendor Indetifier item will be deleted';
      var deleteSubscription = this.commonService.modal_close.subscribe(
        (data: any) => {
          if (data.name === MANTLE_MODAL_NAME.CONFIRM_DIALOG) {
            if (data.data.action_id == 1) {
              if (vendorId.id != undefined) {
                //delete
                this.spinner.show();
                this.productService
                  .deleteProductVendorId(vendorId.id)
                  .subscribe({
                    next: (res) => {
                      this.spinner.hide();
                    },
                    error: (err) => {
                      this.spinner.hide();
                      this.toastr.error(err.error.message);
                    },
                    complete: () => {
                      this.spinner.hide();
                      deleteSubscription.unsubscribe();
                    },
                  });
              }
              //this.frmFilters().removeAt(index);
            } else {
              //return back the item to list
              this.model.vendor_product_ids.splice(index, 0, vendorId);
            }
            modalRef.close();
          }
        }
      );
      this.subscription.add(deleteSubscription);
    } else {
      if (vendorId.id != undefined) {
        //delete
        this.spinner.show();
        this.productService.deleteProductVendorId(vendorId.id).subscribe({
          next: (res) => {
            this.spinner.hide();
          },
          error: (err) => {
            this.spinner.hide();
            this.toastr.error(err.error.message);
          },
          complete: () => {
            this.spinner.hide();
            deleteSubscription.unsubscribe();
          },
        });
      }
    }
  };
  toggleDrawer = () => {
    this.drawerOpen = !this.drawerOpen;
  };
  closeDrawer() {}

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer?.files) {
      this.files = Array.from(event.dataTransfer.files);
      var reader = new FileReader();
      this.imagePath = this.files[0];
      console.log(this.imagePath);
      reader.readAsDataURL(this.files[0]);
      reader.onload = (_event) => {
        this.imageSrc = reader.result;
      };
    }
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
      this.files = Array.from(input.files);
    }
  }

  closeModal() {
    this.commonService.modal_close.next(MANTLE_MODAL_NAME.PRODUCT_UPDATE_MODAL);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
