<form [formGroup]="inventoryFilterForm" (ngSubmit)="onSubmitFilters()">
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">Product Filters</h3>
      <div class="card-tools">
        <btn-new-product *ngIf="product_filter_level == 2"></btn-new-product>
        <mantle-add-inventory-btn
          *ngIf="product_filter_level == 1"
        ></mantle-add-inventory-btn>
        <span class="m-2"></span>
        <mantle-bgg-search-btn
          *ngIf="brand_id == 6 && product_filter_level == 2"
        ></mantle-bgg-search-btn>
        <!-- Collapse Button -->
        <button type="button" class="btn btn-tool" data-card-widget="collapse">
          <i class="fas fa-plus"></i>
        </button>
      </div>
      <!-- /.card-tools -->
    </div>
    <!-- /.card-header -->
    <div class="card-body">
      <div class="row">
        <div class="form-group col-md-3 col-sm-1">
          <label for="brand_id" class="control-label small"
            >Product Line *</label
          >
          <ng-select
            [items]="product_brand"
            bindLabel="name"
            bindValue="id"
            class="ng-select-sm"
            formControlName="brand_id"
            (change)="changeBrand()"
          >
          </ng-select>
        </div>
        <div class="form-group col-md-3 col-sm-1">
          <label class="control-label small">Mantle SKU</label>
          <input
            class="form-control form-control-sm"
            formControlName="Child Sku"
          />
        </div>

        <div class="form-group col-md-3 col-sm-1">
          <label class="control-label small">Product Name</label>
          <input
            class="form-control form-control-sm"
            formControlName="Product Name"
          />
        </div>

        <div class="form-group col-md-3 col-sm-1">
          <button
            class="btn btn-outline-primary btn-sm mt-4"
            type="submit"
            [disabled]="!inventoryFilterForm.valid"
          >
            <i class="fa fa-filter" aria-hidden="true"></i> Apply Filters
          </button>
        </div>
        <!--div class="form-group col-md-3 col-sm-1">
          <label class="control-label">Set Name (s)</label>
          <ng-select [items]="product_sets_filtered" [multiple]="true" bindLabel="name" bindValue="id"
            formControlName="set_id">
          </ng-select>
        </div>

        <div class="form-group col-md-3 col-sm-1">
          <label class="control-label">Condition (s)</label>
          <ng-select [items]="product_conditions_filtered" [multiple]="true" bindLabel="name" bindValue="id"
            formControlName="condition_id">
          </ng-select>
        </div>

        <div class="form-group col-md-3 col-sm-1">
          <label for="rarity_id" class="control-label">Rarity</label>
          <ng-select [items]="product_rarity_filtered" [multiple]="true" bindLabel="name" bindValue="id"
            formControlName="rarity_id">
          </ng-select>
        </div-->
      </div>
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="form-group col-md-3 col-sm-3">
              <label for="brand_id" class="control-label small">Location</label>
              <ng-select
                [items]="store_location"
                [multiple]="true"
                bindLabel="label"
                bindValue="value"
                class="ng-select-sm"
                formControlName="store_ids"
              >
              </ng-select>
            </div>
            <!--div class="form-group col-md-3 col-sm-1">
              <label class="control-label small">Child SKU</label>
              <input class="form-control form-control-sm" formControlName="Child Sku" />
            </div>

            <div class="form-group col-md-3 col-sm-1">
              <label class="control-label small">Product Name</label>
              <input class="form-control form-control-sm" formControlName="Product Name" />
            </div>

            <div class="form-group col-md-3 col-sm-1">
              <label class="control-label small">Short Code</label>
              <input class="form-control form-control-sm" formControlName="Short Code" />
            </div>

            <div class="form-group col-md-2 col-sm-1">
              <button class="btn btn-outline-primary btn-sm mt-4" type="submit" [disabled]="!inventoryFilterForm.valid">
                <i class="fa fa-filter" aria-hidden="true"></i> Apply Filters
              </button>
            </div-->

            <div class="form-group col-md-3 col-sm-3">
              <button
                class="btn btn-link btn-sm mt-4"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#more_filters"
                aria-expanded="false"
                aria-controls="more_filters"
              >
                <i class="fa fa-caret-down" aria-hidden="true"></i> More Filters
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row collapse" id="more_filters">
        <div class="col-12">
          <div class="row">
            <formly-form
              [model]="model"
              [fields]="fields"
              [options]="options"
              [form]="inventoryFilterForm"
            ></formly-form>
            <!--div class="form-group col-md-3 col-sm-1">
              <label class="control-label small">Foil</label>
              <ng-select [items]="foil" bindLabel="name" bindValue="id" class="ng-select-sm"
                formControlName="Foil">
              </ng-select>
            </div>

            <div class="form-group col-md-3 col-sm-1">
              <label class="control-label small">Parent SKU</label>
              <input class="form-control form-control-sm" formControlName="Parent Sku" />
            </div-->
          </div>
          <div class="col-12">
            <div class="row">
              <div class="form-group col-md-3 col-sm-1">
                <label class="control-label small">Sort By</label>
                <ng-select
                  [items]="sortByList"
                  [(ngModel)]="sortBy"
                  [ngModelOptions]="{ standalone: true }"
                  bindLabel="name"
                  bindValue="id"
                >
                </ng-select>
              </div>

              <div class="form-group col-md-3 col-sm-1">
                <label class="control-label small">Sort Order</label>
                <ng-select
                  [items]="sortOrderList"
                  [(ngModel)]="sortOrder"
                  [ngModelOptions]="{ standalone: true }"
                  bindLabel="name"
                  bindValue="id"
                  class="ng-select-sm"
                >
                </ng-select>
              </div>

              <div class="form-group col-md-3 col-sm-1">
                <label class="control-label small">Max. Search results</label>
                <input
                  class="form-control form-control-sm"
                  type="number"
                  [(ngModel)]="searchLimit"
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.card-body -->
  </div>
  <!-- /.card -->
</form>
