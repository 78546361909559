import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from './common.service';
import { Subscription } from 'rxjs';
import { MANTLE_MODAL_NAME } from '../enums/mantle-enums';
import { MantleConfirmDialogComponent } from 'src/app/views/mantle-confirm-dialog/mantle-confirm-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class MantleModalService {
  private modalElement: any;
  constructor(
    private modalService: NgbModal,
    private commonService: CommonService //private modalSubscription: Subscription,
  ) {
    this.modalElement = this.commonService.getFullScreenElement();
  }

  /**
   *
   * @param content
   * @param modalId
   * @param options
   * @param callbackFn Callback function to be executed when modal is closed. Should be an arror function (param1, param1)=> {}
   * @param callbackParameters an array of params ['param1_value', 2 ...]
   * @returns
   */
  open = (
    content: any,
    modalId: MANTLE_MODAL_NAME,
    options?: NgbModalOptions,
    callbackFn: any = () => {},
    callbackParameters?: any
  ) => {
    if (options.container == undefined) options.container = this.modalElement;
    const modalRef = this.modalService.open(content, options);
    var modalSubscription = new Subscription();
    modalSubscription = this.commonService.modal_close.subscribe(
      (data: any) => {
        if (data === modalId) {
          modalRef.close();
          modalSubscription.unsubscribe();
          callbackParameters =
            callbackParameters == undefined ? [] : callbackParameters;
          callbackFn(...callbackParameters);
        } else if (
          data?.name === MANTLE_MODAL_NAME.CONFIRM_DIALOG ||
          data?.name === modalId
        ) {
          if (
            data?.data?.action_id == 1 &&
            modalId === MANTLE_MODAL_NAME.CONFIRM_DIALOG
          ) {
            callbackParameters =
              callbackParameters == undefined ? [] : callbackParameters;
            callbackFn(...callbackParameters, data?.data);
            modalRef.close();
            modalSubscription.unsubscribe();
          } else if (data?.name === modalId) {
            if (data?.data?.action_id == 1) {
              callbackParameters =
                callbackParameters == undefined ? [] : callbackParameters;
              callbackFn(...callbackParameters, data?.data);
            }
            modalRef.close();
            modalSubscription.unsubscribe();
          }
        }
      }
    );
    return modalRef;
  };

  confirmDialog = (
    title?: any,
    message?: any,
    callbackFn: any = () => {},
    callbackParameters?: any,
    icon?: any,
    alertClass?: any
  ) => {
    const modalRef = this.open(
      MantleConfirmDialogComponent,
      MANTLE_MODAL_NAME.CONFIRM_DIALOG,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'sm',
        backdrop: 'static',
      },
      callbackFn,
      callbackParameters
    );

    modalRef.componentInstance.header = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.icon = icon;
    modalRef.componentInstance.alertClass = alertClass;

    return modalRef;
  };
}
