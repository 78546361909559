<div class="card">
  <div class="card-header">
    <div class="card-tools">
      <button type="button" class="btn btn-tool" (click)="closeModal()">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <h5>Active Listing</h5>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12 table-responsive small">
        <table class="table table-striped dataTable">
          <thead>
            <tr>
              <th>Channel</th>
              <th>Product ID</th>
              <th id="skuid">SKU</th>
              <th id="productid">Category</th>
              <th>Listing Name</th>
              <th>QTY</th>
              <th>Cost</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let product of productListing">
              <tr>
                <td>{{ product.sales_channel.sales_channel_description }}</td>
                <td>{{ product.productid }}</td>
                <td>{{ product.skuid }}</td>
                <td>{{ product.category }}</td>
                <td>{{ product.name }}</td>
                <td>{{ product.quantity }}</td>
                <td>{{ product.price }}</td>
                <td>
                  <div class="dropdown" data-bs-toggle="dropdown">
                    <button
                      class="btn btn-outline btn-sm dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Action
                    </button>
                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <button
                        type="button"
                        class="dropdown-item"
                        (click)="onUpdateDetails(product.id)"
                      >
                        <i class="fas fa-edit"></i> Update Details
                      </button>
                      <button type="button" class="dropdown-item">
                        <i class="fas fa-cart-plus"></i> View Orders
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
