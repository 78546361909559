<div class="card" style="background-color: transparent">
  <div class="card-header">
    <h3 class="card-title">{{ to.label }}</h3>
  </div>
  <div class="card-body">
    <form [formGroup]="form">
      <div class="form-group">
        <div class="container">
          <div class="row">
            <div class="col-md-5">
              <!-- Vertical Nav Tabs -->
              <div
                class="nav flex-column nav-pills me-3"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                <ng-container *ngFor="let field of identifierFields">
                  <button
                    class="nav-link"
                    [ngClass]="{ active: activeTab === field.id }"
                    (click)="setActiveTab(field.id)"
                    id="v-pills-home-tab"
                    data-bs-toggle="pill"
                    type="button"
                    role="tab"
                    style="
                      text-align: left;
                      border-bottom: 1px solid #ddd;
                      padding-left: 1rem;
                    "
                  >
                    {{ field.label }}
                  </button>
                </ng-container>
              </div>
            </div>
            <div class="col-md-7">
              <!-- Tab Content -->
              <div class="tab-content" id="v-pills-tabContent">
                <ng-container *ngFor="let field of identifierFields">
                  <div
                    class="tab-pane fade"
                    [ngClass]="{ 'show active': activeTab === field.id }"
                    id="v-pills-home"
                  >
                    <label [for]="field.id">{{ field.label }}</label>
                    <p>
                      <input
                        type="text"
                        class="form-control"
                        [id]="field.id"
                        [formControlName]="field.id"
                        [attr.disabled]="field.disabled ? true : null"
                        placeholder="Enter {{ field.label }}"
                      />
                    </p>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <formly-form
          [form]="form"
          [fields]="fields"
          [model]="model"
          [hidden]="true"
        ></formly-form>
      </div>
    </form>
  </div>
</div>
