<section class="content">
  <div class="container-fluid">
    <div class="card">
      <form [formGroup]="transferForm" (ngSubmit)="submit()">
        <div class="card-header">
          <h3 class="card-title small">Inventory Transfer & Adjustment</h3>
          <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeModal()">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <formly-form
                [model]="model"
                [fields]="fields"
                [options]="options"
                [form]="transferForm"
              ></formly-form>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <div
                    class="row"
                    *ngIf="transferForm.get('status')?.value == 'draft'"
                  >
                    <div class="col">
                      <order-product-search
                        [itemRef]="inventoryTransferItems.length"
                        [notDialog]="true"
                        [have_invetory]="true"
                      ></order-product-search>
                    </div>
                  </div>
                  <div class="row" formArrayName="inventory_transfer_items">
                    <div class="col-md-12 table-responsive">
                      <table class="table table-striped small">
                        <thead>
                          <th scope="col" width="25%">Item</th>
                          <th scope="col">Quantity</th>
                          <th scope="col">Transfer Qty</th>
                          <th scope="col">Adjust Qty (-/+)</th>
                          <th scope="col">&nbsp;</th>
                        </thead>
                        <tbody>
                          <ng-container
                            *ngFor="
                              let item of inventoryTransferItems.controls;
                              let i = index
                            "
                            [formGroupName]="i"
                          >
                            <tr>
                              <td>
                                {{ item.value?.product_description }}
                              </td>
                              <td>
                                {{ item.value?.quantity }}
                              </td>
                              <td>
                                <input
                                  formControlName="transferred_quantity"
                                  class="form-control form-control-sm"
                                  type="number"
                                  min="0"
                                  [readonly]="
                                    transferForm.get('operation_type')?.value !=
                                    'transfer'
                                  "
                                />
                              </td>
                              <td>
                                <input
                                  formControlName="adjusted_quantity"
                                  class="form-control form-control-sm"
                                  type="number"
                                  [readonly]="
                                    transferForm.get('operation_type')?.value !=
                                    'adjustment'
                                  "
                                />
                              </td>
                              <td>
                                <button
                                  *ngIf="
                                    transferForm.get('status')?.value == 'draft'
                                  "
                                  type="button"
                                  (click)="removeTransferItem(i)"
                                  class="btn btn-outline-danger"
                                >
                                  Remove
                                </button>
                              </td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer text-muted">
          <div class="row">
            <div
              class="col-6"
              *ngIf="transferForm.get('status')?.value == 'draft'"
            >
              <button
                type="submit"
                class="btn btn-success btn-sm w-50"
                [disabled]="!transferForm.valid"
              >
                Save
              </button>
            </div>
            <div
              class="col-6"
              *ngIf="
                transferForm.get('transfer_id')?.value &&
                transferForm.get('status')?.value == 'draft'
              "
            >
              <button
                type="button"
                class="btn btn-success btn-sm w-50"
                [disabled]="!transferForm.valid"
                (click)="finalize()"
              >
                Finalize
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
