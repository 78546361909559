import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/utils/services/common.service';
import { InventoryService } from 'src/app/utils/services/inventory.service';

@Component({
  selector: 'mantle-inventory-advanced-summary',
  templateUrl: './inventory-advanced-summary.component.html',
  styleUrl: './inventory-advanced-summary.component.scss',
})
export class InventoryAdvancedSummaryComponent implements OnInit, OnDestroy {
  @Input() inventoryDetails: any;
  @Input() type: any;
  @Input() title: any;
  count: any = 0;
  dataItems: any = [];
  timezone: any = this.commonService.getTimeZone();
  public loading: any = false;
  private subscription: Subscription = new Subscription();
  intervalType = [
    {
      id: 'hours',
      description: 'Hours',
    },
    {
      id: 'days',
      description: 'Days',
    },
    {
      id: 'weeks',
      description: 'Weeks',
    },
    {
      id: 'months',
      description: 'Months',
    },
    {
      id: 'years',
      description: 'Years',
    },
  ];
  public isToggled = false;

  constructor(
    private fb: UntypedFormBuilder,
    private inventoryService: InventoryService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private commonService: CommonService
  ) {
    this.subscription.add(
      this.commonService.inventoryPeriodSelector.subscribe({
        next: (data) => {
          this.patchForm(data);
          this.getData();
        },
        error: (err) => {},
        complete: () => {},
      })
    );
  }

  form = this.fb.group({
    type: [],
    child_sku: [],
    timezone: [],
    interval_period: [1],
    interval_type: ['months'],
    purchase_status_id: [4],
  });

  ngOnInit(): void {
    setTimeout(() => {
      this.patchForm({
        type: this.type,
        child_sku: this.inventoryDetails.child_sku,
        timezone: this.timezone,
      });
      this.getData();
    }, 0);
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  patchForm = (data) => {
    this.form.patchValue(data);
  };

  getData = () => {
    this.loading = true;
    this.inventoryService
      .getAdvancedInventory(
        this.type,
        this.inventoryDetails.child_sku,
        this.form.getRawValue()
      )
      .subscribe({
        next: (res) => {
          this.count = res?.quantity;
          this.dataItems = res?.data;
        },
        error: (err) => {
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
        },
      });
  };
}
