import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { FieldWrapper, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'mantle-product-identifiers',
  templateUrl: './mantle-product-identifiers.component.html',
  styleUrl: './mantle-product-identifiers.component.scss',
})
export class MantleProductIdentifiersComponent
  extends FieldWrapper
  implements OnInit {
  @ViewChild('fieldComponent', { read: ViewContainerRef })
  fieldComponent: ViewContainerRef;
  additionalControls: UntypedFormGroup;
  identifierFields: any = [
    {
      id: 'tcgplayer_id',
      label: 'TCGPlayer Id',
      disabled: true,
    },
    {
      id: 'cardtrader_id',
      label: 'CardTrader Id',
      disabled: true,
    },
    {
      id: 'ASIN',
      label: 'ASIN',
      disabled: true,
    },
    {
      id: 'bigcommerce_id',
      label: 'BigCommerce Id',
      disabled: false,
    },
    {
      id: 'shopify_id',
      label: 'Shopify Id',
      disabled: false,
    },
    {
      id: 'amazon_seller_sku',
      label: 'Amazon SP Seller SKU',
      disabled: false,
    },
    {
      id: 'woocommerce_id',
      label: 'WooCommerce SKU',
      disabled: false,
    },
    {
      id: 'UPC',
      label: 'UPC',
      disabled: false,
    },
  ];
  fields: FormlyFieldConfig[] = [];

  constructor(private fb: UntypedFormBuilder) {
    super();
  }
  activeTab: string = this.identifierFields[0].id;

  setActiveTab(tab: string) {
    this.activeTab = tab;
  }

  ngOnInit(): void {
    this.identifierFields.forEach((field) => {
      this.fields.push({
        key: field.id,
        type: 'input',
        templateOptions: {
          label: `${field.label}`,
          required: false,
          disabled: field.disabled,
        },
      });
    });
  }
}
