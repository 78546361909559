<form [formGroup]="tcgConfigForm">
  <div class="card">
    <div class="card-header">
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="closeModal()">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <h5>Activate TCG Player sales channel</h5>
    </div>
    <div class="card-body">
      <div class="row small">
        <div class="col-md-12">
          <ol>
            <li><strong>An API Developer Key</strong></li>
          </ol>
          <ul>
            <li>
              If you do not yet have a Developer Key, you can apply for one by
              submitting this form:
              <a
                href="https://developer.tcgplayer.com/developer-application-form.html"
                target="_blank"
                title=""
                >https://developer.tcgplayer.com/developer-application-form.html</a
              >
            </li>
          </ul>
          <hr />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label small">Description</label>
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="channel_tcg_description"
            maxlength="50"
          />
        </div>
        <div class="form-group col-md-6">
          <label class="control-label small">Inventory Store Location</label>
          <ng-select
            [items]="storeList"
            [multiple]="true"
            bindLabel="store_name"
            bindValue="store_id"
            formControlName="store_ids"
          >
          </ng-select>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label small">Public Key / Client Id</label>
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="client_id"
          />
        </div>
        <div class="form-group col-md-6">
          <label class="control-label small">Private Key / Client Secret</label>
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="client_secret"
          />
        </div>
      </div>
      <div class="row" *ngIf="tcgConfigForm.value.client_id">
        <div class="form-group col-md-6">
          <label class="control-label small">Authorization Code</label>
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="authorization_code"
          />
        </div>
        <div class="form-group col-md-6 mt-4">
          <a
            href="https://store.tcgplayer.com/admin/Apps/{{
              tcgConfigForm.value.client_id
            }}"
            target="_blank"
            ><i class="fas fa-external-link-alt"></i> Authorization Code</a
          >
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label small">Store</label>
          <select
            class="form-control form-control-sm"
            formControlName="store_key"
          >
            <ng-container *ngFor="let store of storeOptions">
              <option value="{{ store.storeKey }}">{{ store.name }}</option>
            </ng-container>
          </select>
        </div>
        <div class="form-group col-md-6">
          <label class="control-label small">Update Frequency (Minutes)</label>
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="update_frequency"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <button
            type="button"
            class="btn btn-sm btn-info w-50"
            (click)="onValidate()"
          >
            <i class="fas fa-check-double"></i> Validate
          </button>
        </div>
        <div class="form-group col-md-6">
          <button
            *ngIf="validated && !tcgConfigForm.value.channel_tcg_id"
            type="button"
            class="btn btn-sm btn-success w-50"
            (click)="onSave()"
            [disabled]="!tcgConfigForm.valid"
          >
            <i class="far fa-save"></i> Save
          </button>
          <button
            *ngIf="validated && tcgConfigForm.value.channel_tcg_id"
            type="button"
            class="btn btn-sm btn-success w-50"
            (click)="onUpdate()"
            [disabled]="!tcgConfigForm.valid"
          >
            <i class="far fa-save"></i> Update
          </button>
          <button
            *ngIf="!validated && tcgConfigForm.value.channel_tcg_id"
            type="button"
            class="btn btn-sm btn-success w-50"
            (click)="onUpdateFrequency()"
            [disabled]="!tcgConfigForm.valid"
          >
            <i class="far fa-save"></i> Update Frequency
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
