import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom, Subscription } from 'rxjs';
import { CommonService } from 'src/app/utils/services/common.service';
import { InventoryService } from 'src/app/utils/services/inventory.service';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';
import { ProductService } from 'src/app/utils/services/product.service';

@Component({
  selector: 'inventory-audit-details',
  templateUrl: './inventory-audit-details.component.html',
  styleUrl: './inventory-audit-details.component.scss',
})
export class InventoryAuditDetailsComponent implements OnInit, OnDestroy {
  searchLimit: any = '';
  sortBy: any = 'prd.auditedat';
  sortOrder: any = 'desc';
  inventoryItems: Array<any> = [];
  searchValues: any = {};
  timezone = this.commonService.getTimeZone();
  private subscription: Subscription = new Subscription();

  constructor(
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private inventoryService: InventoryService,
    private productService: ProductService,
    private mantleModalService: MantleModalService
  ) {}
  ngOnInit(): void {
    this.subscription.add(
      this.inventoryService.filter_result.subscribe({
        next: (res) => {
          this.initData(res);
        },
        error: (err) => {},
        complete: () => {},
      })
    );
  }
  initData(searchValues: unknown) {
    if (Object.keys(searchValues).length === 0) {
      this.toastr.error('Please enter search criteria to proceed.');
      return;
    }
    this.searchValues = searchValues;
    this.spinner.show();
    this.subscription.add(
      this.inventoryService
        .inventoryProductFilter(
          searchValues,
          this.sortBy,
          this.sortOrder,
          this.searchLimit,
          true
        )
        .subscribe({
          next: (res) => {
            this.inventoryItems = res;
          },
          error: (err) => {
            this.spinner.hide();
          },
          complete: () => {
            this.spinner.hide();
          },
        })
    );
  }
  calculateVariance($event: any, index: any, lIndex: any) {
    var location = this.inventoryItems[index].inventory_locations[lIndex];
    var qty_variance =
      parseInt(location.physical_qty) - parseInt(location.quantity);
    location.qty_variance = isNaN(qty_variance) ? null : qty_variance;
  }

  confirmAudit = () => {
    this.mantleModalService.confirmDialog(
      `Confirm Audit`,
      `Please confirm that all updated physical quantities are accurate.`,
      this.submitAudit,
      []
    );
  };
  submitAudit = async () => {
    const filteredData = this.inventoryItems
      .map((item) => ({
        ...item,
        inventory_locations: item?.inventory_locations?.filter(
          (loc) =>
            loc.qty_variance !== 0 &&
            loc.qty_variance !== null &&
            loc.qty_variance !== undefined
        ),
      }))
      .filter(
        (item) =>
          item.inventory_locations && item.inventory_locations.length > 0
      );
    if (filteredData.length <= 0) return;

    const uniqueStores = Array.from(
      new Set(
        filteredData
          .map((item) => item.inventory_locations)
          .reduce(
            (acc, locations) =>
              acc.concat(locations.map((loc) => loc.store_id)),
            []
          )
      )
    );

    this.spinner.show();
    const adjustmentPromises = uniqueStores.map((store_id) => {
      const locationItems = filteredData.map((item) => ({
        ...item,
        inventory_locations: item?.inventory_locations?.filter(
          (loc) => loc.store_id == store_id
        ),
      }));

      var adjustPayload = {
        operation_type: 'adjustment',
        source_location_id: store_id,
        note: `Inventory Audit - ${new Date().toLocaleString()}`,
        status: 'draft',
        inventory_transfer_items: [],
      };

      locationItems.forEach((locationItem) => {
        locationItem.inventory_locations.forEach((location) => {
          adjustPayload.inventory_transfer_items.push({
            inventory_id: location.inventory_id,
            brand_id: locationItem.brand_id,
            parent_sku: locationItem['Parent Sku'],
            child_sku: location.skuid,
            quantity: location.quantity,
            adjusted_quantity: location.qty_variance,
            product_description: locationItem['Product Name'],
          });
        });
      });
      return lastValueFrom(
        this.inventoryService.createTransfer(adjustPayload, true)
      );
    });
    Promise.all(adjustmentPromises)
      .then((res) => {
        this.spinner.hide();
        this.updateLastAudited();
      })
      .catch((error) => {
        this.spinner.hide();
        console.error('Error updating some inventory locations:', error);
      });
  };

  updateLastAudited = async () => {
    const filteredData = this.inventoryItems
      .map((item) => ({
        ...item,
        inventory_locations: item?.inventory_locations?.filter(
          (loc) => loc.qty_variance !== null && loc.qty_variance !== undefined
        ),
      }))
      .filter(
        (item) =>
          item.inventory_locations && item.inventory_locations.length > 0
      );
    const mergedInventoryLocations = filteredData.reduce((acc, item) => {
      return acc.concat(item.inventory_locations);
    }, []);

    const uniqueInventoryids = [
      ...new Set(mergedInventoryLocations.map((loc) => loc.inventory_id)),
    ];
    const locationIds = mergedInventoryLocations.map((loc) => loc.id);
    this.spinner.show();
    await this.inventoryService
      .updateAuditTimestamp({
        inventory_ids: uniqueInventoryids,
        inventory_location_ids: locationIds,
      })
      .subscribe({
        next: (res) => {},
        error: (err) => {
          this.spinner.hide();
          this.initData(this.searchValues);
        },
        complete: () => {
          this.spinner.hide();
          this.initData(this.searchValues);
        },
      });
  };

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
