import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import formConfig from '../../../../../configs/forms/email_settings.json';
import { MailingService } from 'src/app/utils/services/mailing.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-mantle-email-settings',
  templateUrl: './mantle-email-settings.component.html',
  styleUrl: './mantle-email-settings.component.scss',
})
export class MantleEmailSettingsComponent implements OnInit {
  gmailMoreInfo: boolean = false;
  constructor(
    private fb: UntypedFormBuilder,
    private mailingService: MailingService,
    private spinner: NgxSpinnerService
  ) {}
  ngOnInit(): void {
    setTimeout(() => {
      this.fields = formConfig;
      this.getDefaultSettings();
    }, 1);
  }
  form = this.fb.group({
    id: ['1', Validators.required],
    is_default: true,
    use_default_sender: true,
  });
  options: FormlyFormOptions = {
    formState: {
      service_type: [
        { value: 'SMTP', label: 'SMTP' },
        { value: 'gmail', label: 'GMail' },
      ],
    },
  };
  fields: FormlyFieldConfig[];
  model: any = {};

  getDefaultSettings = () => {
    this.spinner.show();
    this.mailingService.getDefaultSetting().subscribe({
      next: (res) => {
        this.model = res;
        this.form.patchValue(res);
      },
      error: (err) => {
        this.spinner.hide();
      },
      complete: () => {
        this.spinner.hide();
      },
    });
  };

  testSettings = () => {};

  onSubmit(authGmail = false) {
    this.spinner.show();
    this.mailingService.updateEmailSettings(this.form.getRawValue()).subscribe({
      next: (res) => {
        this.form.patchValue(res);
      },
      error: (err) => {
        this.spinner.hide();
      },
      complete: () => {
        this.spinner.hide();
        if (authGmail) this.gmailAuth();
      },
    });
  }
  onGmailAuth = () => {
    this.onSubmit(true);
  };

  gmailAuth = () => {
    const formValues = this.form.getRawValue();
    const CLIENT_ID = formValues.g_client_id;
    const REDIRECT_URI = formValues.g_redirect_uri;
    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=https://www.googleapis.com/auth/gmail.send&access_type=offline`;
  };
  toggleGoogleInfo = () => {
    this.gmailMoreInfo = !this.gmailMoreInfo;
  };
}
