<div class="card">
  <div class="card-header">
    <div class="card-tools">
      <button type="button" class="btn btn-tool">
        <button type="button" class="btn btn-success btn-sm" (click)="onAdd()">
          Add Label Format
        </button>
        <!-- <i class="fas fa-times"></i> -->
      </button>
    </div>
    <h5>Label Format</h5>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12 table-responsive small">
        <table class="table table-striped dataTable_4" style="width: 100%">
          <thead>
            <tr>
              <th>Label Format</th>
              <th>Label Format Description</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let labelformat of labelformatList">
              <tr>
                <td>{{ labelformat.label_format }}</td>
                <td>{{ labelformat.label_format_description }}</td>
                <td>
                  <div class="dropdown" data-bs-toggle="dropdown">
                    <button
                      class="btn btn-outline btn-sm dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Action
                    </button>
                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <button
                        type="button"
                        class="dropdown-item"
                        (click)="onEdit(labelformat.id)"
                      >
                        <i class="fas fa-edit"></i> Edit
                      </button>
                      <button
                        type="button"
                        class="dropdown-item"
                        (click)="onDelete(labelformat.id)"
                      >
                        <i class="fas fa-trash"></i> Delete
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
