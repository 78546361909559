import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '@auth0/auth0-angular';
import { Router } from '@angular/router';
import { AppConfig } from '../services/app.config';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class MantleHttpInterceptor implements HttpInterceptor {
  constructor(
    public _authService: AuthService,
    private _router: Router,
    private _config: AppConfig,
    private toastr: ToastrService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const skipIntercept = request.headers.has('mantle-skip');
    if (skipIntercept) {
      request = request.clone({
        headers: request.headers.delete('mantle-skip'),
      });
      return next.handle(request);
    }

    return from(
      this._authService.getAccessTokenSilently({
        authorizationParams: {
          audience: this._config.getConfig('AUDIENCE'),
        },
      })
    ).pipe(
      switchMap((response: any) => {
        const accessToken = response;
        let updatedRequest = request;
        if (accessToken) {
          updatedRequest = request.clone({
            setHeaders: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
        }
        return next.handle(updatedRequest).pipe(
          tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              if (event.status == HttpStatusCode.Unauthorized) {
                this._authService.logout({
                  logoutParams: {
                    returnTo: this._config.getConfig('LOGOUT'),
                  },
                });
              }
            }
          }),
          catchError((error: HttpErrorResponse) => {
            if (error.status == HttpStatusCode.Unauthorized) {
              this._authService.logout({
                logoutParams: {
                  returnTo: this._config.getConfig('LOGOUT'),
                },
              });
            }
            //relook
            const errorMessage = error.error.message
              ? error.error.message
              : error.message;
            if (
              errorMessage?.includes('missing_refresh_token') ||
              errorMessage?.includes('login_required')
            ) {
              this._authService.logout({
                logoutParams: {
                  returnTo: this._config.getConfig('LOGOUT'),
                },
              });
            }
            console.error('Error occurred:', error);

            this.toastr.error(errorMessage);
            return throwError(error);
          })
        );
      })
    );
  }
}
