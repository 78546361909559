import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { ChannelStatusMappingComponent } from 'src/app/views/channel/channel-status-mapping/channel-status-mapping.component';
import { ApiService } from './api.service';
import { CommonService } from './common.service';
import { StorageService } from './storage.service';
import { LOCAL_STORAGE_KEYS } from '../enums/mantle-enums';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private commonService: CommonService,
    private storageService: StorageService
  ) {}

  statusMappingDialog() {
    const modalRef = this.modalService.open(ChannelStatusMappingComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
    });

    this.commonService.modal_close.subscribe((data) => {
      if (data === 'STATUS-MAPPING-MODAL') {
        modalRef.close();
      }
    });
  }

  /**
   * Country Settings
   */
  getCountries(): Observable<any> {
    return this.apiService.get('/api/v1/settings/country');
  }

  getCountry(country_code): Observable<any> {
    return this.apiService.get('/api/v1/settings/country/' + country_code);
  }

  addCountry(requestPayload): Observable<any> {
    return this.apiService.post('/api/v1/settings/country', requestPayload);
  }
  updateCountry(requestPayload): Observable<any> {
    return this.apiService.patch('/api/v1/settings/country', requestPayload);
  }
  deleteCountry(country_code): Observable<any> {
    return this.apiService.delete('/api/v1/settings/country/' + country_code);
  }

  /**
   * Currency Settings
   */
  getCurrencys(): Observable<any> {
    return this.apiService.get('/api/v1/currency');
  }

  getCurrency(currency_code): Observable<any> {
    return this.apiService.get('/api/v1/currency/' + currency_code);
  }

  addCurrency(requestPayload): Observable<any> {
    return this.apiService.post('/api/v1/currency', requestPayload);
  }
  updateCurrency(requestPayload): Observable<any> {
    return this.apiService.patch('/api/v1/currency', requestPayload);
  }
  deleteCurrency(currency_code): Observable<any> {
    return this.apiService.delete('/api/v1/currency/' + currency_code);
  }

  /**
   * Order StatusSettings
   */
  getOrdersstatus(): Observable<any> {
    return this.apiService.get('/api/v1/settings/order_status');
  }

  getOrderstatus(order_status_id): Observable<any> {
    return this.apiService.get(
      '/api/v1/settings/order_status/' + order_status_id
    );
  }

  addOrderstatus(requestPayload): Observable<any> {
    return this.apiService.post(
      '/api/v1/settings/order_status',
      requestPayload
    );
  }
  updateOrderstatus(requestPayload): Observable<any> {
    return this.apiService.patch(
      '/api/v1/settings/order_status',
      requestPayload
    );
  }
  deleteOrderstatus(order_status_id): Observable<any> {
    return this.apiService.delete(
      '/api/v1/settings/order_status/' + order_status_id
    );
  }

  /**
   * Endicia Carrier
   */
  getCarriers(): Observable<any> {
    return this.apiService.get('/api/v1/endicia/carrier');
  }

  getCarrier(id): Observable<any> {
    return this.apiService.get('/api/v1/endicia/carrier/' + id);
  }

  addCarrier(requestPayload): Observable<any> {
    return this.apiService.post('/api/v1/endicia/carrier', requestPayload);
  }
  updateCarrier(requestPayload): Observable<any> {
    return this.apiService.patch('/api/v1/endicia/carrier', requestPayload);
  }
  deleteCarrier(id): Observable<any> {
    return this.apiService.delete('/api/v1/endicia/carrier/' + id);
  }

  /**
   * Endicia Content Type
   */
  getContenttypes(): Observable<any> {
    return this.apiService.get('/api/v1/endicia/contents_type');
  }

  getContenttype(id): Observable<any> {
    return this.apiService.get('/api/v1/endicia/contents_type/' + id);
  }

  addContenttype(requestPayload): Observable<any> {
    return this.apiService.post(
      '/api/v1/endicia/contents_type',
      requestPayload
    );
  }
  updateContenttype(requestPayload): Observable<any> {
    return this.apiService.patch(
      '/api/v1/endicia/contents_type',
      requestPayload
    );
  }
  deleteContenttype(id): Observable<any> {
    return this.apiService.delete('/api/v1/endicia/contents_type/' + id);
  }

  /**
   * Endicia Delivery Confirmation Type
   */
  getDeliveryConfirmationTypes(): Observable<any> {
    return this.apiService.get('/api/v1/endicia/delivery_confirmation_type');
  }

  getDeliveryConfirmationType(id): Observable<any> {
    return this.apiService.get(
      '/api/v1/endicia/delivery_confirmation_type/' + id
    );
  }

  addDeliveryConfirmationType(requestPayload): Observable<any> {
    return this.apiService.post(
      '/api/v1/endicia/delivery_confirmation_type',
      requestPayload
    );
  }
  updateDeliveryConfirmationType(requestPayload): Observable<any> {
    return this.apiService.patch(
      '/api/v1/endicia/delivery_confirmation_type',
      requestPayload
    );
  }
  deleteDeliveryConfirmationType(id): Observable<any> {
    return this.apiService.delete(
      '/api/v1/endicia/delivery_confirmation_type/' + id
    );
  }

  /**
   * Endicia Dimension Unit
   */
  getDimensionUnits(): Observable<any> {
    return this.apiService.get('/api/v1/endicia/dimension_unit');
  }

  getDimensionUnit(id): Observable<any> {
    return this.apiService.get('/api/v1/endicia/dimension_unit/' + id);
  }

  addDimensionUnit(requestPayload): Observable<any> {
    return this.apiService.post(
      '/api/v1/endicia/dimension_unit',
      requestPayload
    );
  }
  updateDimensionUnit(requestPayload): Observable<any> {
    return this.apiService.patch(
      '/api/v1/endicia/dimension_unit',
      requestPayload
    );
  }
  deleteDimensionUnit(id): Observable<any> {
    return this.apiService.delete('/api/v1/endicia/dimension_unit/' + id);
  }

  /**
   *Endicia Label Format
   */
  getLabelFormats(): Observable<any> {
    return this.apiService.get('/api/v1/endicia/label_format');
  }

  getLabelFormat(id): Observable<any> {
    return this.apiService.get('/api/v1/endicia/label_format/' + id);
  }

  addLabelFormat(requestPayload): Observable<any> {
    return this.apiService.post('/api/v1/endicia/label_format', requestPayload);
  }
  updateLabelFormat(requestPayload): Observable<any> {
    return this.apiService.patch(
      '/api/v1/endicia/label_format',
      requestPayload
    );
  }
  deleteLabelFormat(id): Observable<any> {
    return this.apiService.delete('/api/v1/endicia/label_format/' + id);
  }

  /**
   *Endicia Label Output Type
   */
  getLabelOutputTypes(): Observable<any> {
    return this.apiService.get('/api/v1/endicia/label_output_type');
  }

  getLabelOutputType(id): Observable<any> {
    return this.apiService.get('/api/v1/endicia/label_output_type/' + id);
  }

  addLabelOutputType(requestPayload): Observable<any> {
    return this.apiService.post(
      '/api/v1/endicia/label_output_type',
      requestPayload
    );
  }
  updateLabelOutputType(requestPayload): Observable<any> {
    return this.apiService.patch(
      '/api/v1/endicia/label_output_type',
      requestPayload
    );
  }
  deleteLabelOutputType(id): Observable<any> {
    return this.apiService.delete('/api/v1/endicia/label_output_type/' + id);
  }

  /**
   * Endicia Label Size
   */
  getLabelSizes(): Observable<any> {
    return this.apiService.get('/api/v1/endicia/label_size');
  }

  getLabelSize(id): Observable<any> {
    return this.apiService.get('/api/v1/endicia/label_size/' + id);
  }

  addLabelSize(requestPayload): Observable<any> {
    return this.apiService.post('/api/v1/endicia/label_size', requestPayload);
  }
  updateLabelSize(requestPayload): Observable<any> {
    return this.apiService.patch('/api/v1/endicia/label_size', requestPayload);
  }
  deleteLabelSize(id): Observable<any> {
    return this.apiService.delete('/api/v1/endicia/label_size/' + id);
  }

  /**
   * Endicia Non Delivery Option
   */
  getNonDeliveryOptions(): Observable<any> {
    return this.apiService.get('/api/v1/endicia/non_delivery_option');
  }

  getNonDeliveryOption(id): Observable<any> {
    return this.apiService.get('/api/v1/endicia/non_delivery_option/' + id);
  }

  addNonDeliveryOption(requestPayload): Observable<any> {
    return this.apiService.post(
      '/api/v1/endicia/non_delivery_option',
      requestPayload
    );
  }
  updateNonDeliveryOption(requestPayload): Observable<any> {
    return this.apiService.patch(
      '/api/v1/endicia/non_delivery_option',
      requestPayload
    );
  }
  deleteNonDeliveryOption(id): Observable<any> {
    return this.apiService.delete('/api/v1/endicia/non_delivery_option/' + id);
  }

  /**
   * Endicia Packaging Type
   */
  getPackagingTypes(): Observable<any> {
    return this.apiService.get('/api/v1/endicia/packaging_type');
  }

  getPackagingType(id): Observable<any> {
    return this.apiService.get('/api/v1/endicia/packaging_type/' + id);
  }

  addPackagingType(requestPayload): Observable<any> {
    return this.apiService.post(
      '/api/v1/endicia/packaging_type',
      requestPayload
    );
  }
  updatePackagingType(requestPayload): Observable<any> {
    return this.apiService.patch(
      '/api/v1/endicia/packaging_type',
      requestPayload
    );
  }
  deletePackagingType(id): Observable<any> {
    return this.apiService.delete('/api/v1/endicia/packaging_type/' + id);
  }

  /**
   * Endicia Service Type
   */
  getServiceTypes(): Observable<any> {
    return this.apiService.get('/api/v1/endicia/service_type');
  }

  getServiceType(id): Observable<any> {
    return this.apiService.get('/api/v1/endicia/service_type/' + id);
  }

  addServiceType(requestPayload): Observable<any> {
    return this.apiService.post('/api/v1/endicia/service_type', requestPayload);
  }
  updateServiceType(requestPayload): Observable<any> {
    return this.apiService.patch(
      '/api/v1/endicia/service_type',
      requestPayload
    );
  }
  deleteServiceType(id): Observable<any> {
    return this.apiService.delete('/api/v1/endicia/service_type/' + id);
  }

  /**
   * Endicia Weight Unit
   */
  getWeightUnits(): Observable<any> {
    return this.apiService.get('/api/v1/endicia/weight_unit');
  }

  getWeightUnit(id): Observable<any> {
    return this.apiService.get('/api/v1/endicia/weight_unit/' + id);
  }

  addWeightUnit(requestPayload): Observable<any> {
    return this.apiService.post('/api/v1/endicia/weight_unit', requestPayload);
  }
  updateWeightUnit(requestPayload): Observable<any> {
    return this.apiService.patch('/api/v1/endicia/weight_unit', requestPayload);
  }
  deleteWeightUnit(id): Observable<any> {
    return this.apiService.delete('/api/v1/endicia/weight_unit/' + id);
  }
  /**
   * Store
   */
  getStores(): Observable<any> {
    return this.apiService.get('/api/v1/settings/store');
  }

  getStore(id): Observable<any> {
    return this.apiService.get('/api/v1/settings/store/' + id);
  }

  addStore(requestPayload): Observable<any> {
    return this.apiService.post('/api/v1/settings/store', requestPayload);
  }
  updateStore(requestPayload): Observable<any> {
    return this.apiService.patch('/api/v1/settings/store', requestPayload);
  }
  deleteStore(id): Observable<any> {
    return this.apiService.delete('/api/v1/settings/store/' + id);
  }
  /**
   * System settings
   * router.get('/settings/system', auth.verifyToken, sys_controller.getSystemSettings)
router.get('/settings/system/:id', auth.verifyToken, sys_controller.getSystemSettingById)
router.get('/settings/system/name/:name', auth.verifyToken, sys_controller.getSystemSettingByName)
router.post('/settings/system',auth.verifyToken,sys_controller.createSystemSetting)
router.patch('/settings/system', auth.verifyToken, sys_controller.updateSystemSetting)
router.get('/settings/system/value/:name', auth.verifyToken, sys_controller.getSettingValueByName)

   */
  getSystemSettings(cached = false): Observable<any> {
    if (cached) {
      const cachedSettings = this.storageService.getItem(
        LOCAL_STORAGE_KEYS.SYSTEM_SETTINGS
      );
      if (cachedSettings != undefined) {
        return of(cachedSettings);
      }
    }
    return this.apiService.get('/api/v1/settings/system');
  }

  getSystemSettingById(id): Observable<any> {
    return this.apiService.get('/api/v1/settings/system/' + id);
  }

  getSystemSettingByName(name): Observable<any> {
    return this.apiService.get('/api/v1/settings/system/name/' + name);
  }

  createSystemSetting(requestPayload): Observable<any> {
    return this.apiService.post('/api/v1/settings/system', requestPayload);
  }

  updateSystemSetting(requestPayload): Observable<any> {
    return this.apiService.patch('/api/v1/settings/system', requestPayload);
  }

  getSettingValueByName(name): Observable<any> {
    return this.apiService.get('/api/v1/settings/system/value/' + name);
  }

  getSystemSettingByGroupName(name): Observable<any> {
    return this.apiService.get('/api/v1/settings/system/group/' + name);
  }
  deleteSystemSettings(id): Observable<any> {
    return this.apiService.delete('/api/v1/settings/system/' + id);
  }
  getCurrencyDenominations(): Observable<any> {
    return this.apiService.get('/api/v1/currency/setting/denomination');
  }
}
