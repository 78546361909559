<div class="card">
  <div class="card-header">
    <h3 class="card-title small">Email Templates</h3>
    <div class="card-tools">
      <button
        type="button"
        class="btn btn-info btn-sm"
        style="border: 0px"
        (click)="onNewTemplate()"
      >
        <i class="fas fa-plus"></i> New Template
      </button>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12 table-responsive small">
        <table class="table table-striped dataTable">
          <thead>
            <tr>
              <th id="name">Name</th>
              <th id="template_ref">Ref</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <ng-container
              *ngFor="let template of templateList$ | async; index as i"
            >
              <tr>
                <td>{{ template.name }}</td>
                <td>{{ template.template_ref }}</td>
                <td>
                  <div class="dropdown" data-bs-toggle="dropdown">
                    <button
                      class="btn btn-outline btn-sm dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Action
                    </button>
                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <button
                        type="button"
                        class="dropdown-item"
                        (click)="viewDetails(template.template_id)"
                      >
                        <i class="fas fa-info-circle"></i> View Details
                      </button>
                      <button
                        type="button"
                        class="dropdown-item"
                        (click)="onSendEmail(template.template_id)"
                      >
                        <i class="fas fa-info-circle"></i> Send Email
                      </button>
                      <button
                        type="button"
                        class="dropdown-item"
                        (click)="onDelete(template.template_id)"
                      >
                        <i class="fas fa-trash"></i> Detele
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
