import { Component } from '@angular/core';

@Component({
  selector: 'app-mantle-product-vendor-ids',
  templateUrl: './mantle-product-vendor-ids.component.html',
  styleUrl: './mantle-product-vendor-ids.component.scss'
})
export class MantleProductVendorIdsComponent {

}
