<aside #mainSidebar class="main-sidebar sidebar-dark-primary elevation-4">
  <!-- Brand Logo -->
  <a [routerLink]="['/']" class="brand-link">
    <img
      [(src)]="logoImage"
      alt="Logo"
      class="brand-image img-circle elevation-3"
      style="opacity: 0.8"
    />
    <span class="brand-text font-weight-light">MANTLE</span>
  </a>

  <!-- Sidebar -->
  <div class="sidebar">
    <!-- Sidebar user (optional) -->
    <div class="user-panel mt-3 pb-3 mb-3 d-flex">
      <div class="image">
        <img
          src="{{ user_details.image }}"
          class="img-circle elevation-2"
          alt=""
        />
      </div>
      <div class="info">
        <a href="/profile" class="d-block"> {{ user_details.name }}</a>
      </div>
    </div>

    <!-- Sidebar Menu -->
    <nav class="mt-2">
      <ul
        class="nav nav-pills nav-sidebar flex-column"
        data-widget="treeview"
        role="menu"
        data-accordion="false"
      >
        <!-- Add icons to the links using the .nav-icon class
             with font-awesome or any other icon font library -->
        <li class="nav-item">
          <a [routerLink]="['/']" class="nav-link">
            <i class="nav-icon fas fa-tachometer-alt"></i>
            <p>Dashboard</p>
          </a>
        </li>

        <li class="nav-item treeview">
          <a href="#" class="nav-link">
            <i class="nav-icon fas fa-shopping-cart"></i>
            <p>Sales Orders</p>
          </a>
          <ul class="nav nav-treeview" style="margin-left: 35px">
            <li class="nav-item">
              <a [routerLink]="['/order/pos']" class="nav-link">
                <i class="nav-icon fas fa-tablet-alt"></i>
                <p>Point of Sale</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/order']" class="nav-link">
                <i class="nav-icon fas fa-cart-plus"></i>
                <p>Manage Orders</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/order-shipments']" class="nav-link">
                <i class="nav-icon fas fa-tasks"></i>
                <p>Shipments</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/return-order']" class="nav-link">
                <i class="nav-icon fas fa-undo-alt"></i>
                <p>Returns</p>
              </a>
            </li>
          </ul>
        </li>

        <li class="nav-item treeview">
          <a href="#" class="nav-link">
            <i class="nav-icon fas fa-layer-group"></i>
            <p>Purchase Order</p>
          </a>
          <ul class="nav nav-treeview" style="margin-left: 35px">
            <li class="nav-item">
              <a [routerLink]="['/po/orders']" class="nav-link">
                <i class="nav-icon fas fa-cart-plus"></i>
                <p>Manage Purchase Orders</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/po/vendors']" class="nav-link">
                <i class="nav-icon fas fa-users-cog"></i>
                <p>Manage Vendors</p>
              </a>
            </li>
          </ul>
        </li>

        <li class="nav-item treeview">
          <a href="#" class="nav-link">
            <i class="nav-icon fas fa-chart-pie"></i>
            <p>Inventory</p>
          </a>
          <ul class="nav nav-treeview" style="margin-left: 35px">
            <li class="nav-item">
              <a [routerLink]="['/inventory']" class="nav-link">
                <i class="nav-icon fas fa-chart-pie"></i>
                <p>Manage Inventory</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/catalog/manage']" class="nav-link">
                <i class="nav-icon fas fa-edit"></i>
                <p>Manage Catalog</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/catalog/subscription']" class="nav-link">
                <i class="nav-icon fas fa-tasks"></i>
                <p>Manage Subscriptions</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/inventory/audit']" class="nav-link">
                <i class="nav-icon fas fa-clipboard-check"></i>
                <p>Inventory Audit</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/inventory/transfer']" class="nav-link">
                <i class="nav-icon fas fa-exchange-alt"></i>
                <p>Transfers & Adjustments</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/inventory-upload']" class="nav-link">
                <i class="nav-icon fas fa-upload"></i>
                <p>Upload Inventory</p>
              </a>
            </li>
          </ul>
        </li>

        <li class="nav-item">
          <a [routerLink]="['/customers']" class="nav-link">
            <i class="nav-icon fas fa-users"></i>
            <p>Customers</p>
          </a>
        </li>

        <li class="nav-item">
          <a [routerLink]="['/reports']" class="nav-link">
            <i class="nav-icon fas fa-chart-bar"></i>
            <p>Reports</p>
          </a>
        </li>

        <!--li class="nav-item">
          <a [routerLink]="['/reports']" class="nav-link">
            <i class="nav-icon fas fa-chart-bar"></i>
            <p>Marketting</p>
          </a>
        </li-->

        <li class="nav-item treeview">
          <a href="#" class="nav-link">
            <i class="nav-icon fas fa-th"></i>
            <p>Channels</p>
          </a>
          <ul class="nav nav-treeview" style="margin-left: 35px">
            <li class="nav-item">
              <a [routerLink]="['/channels-dashboard']" class="nav-link">
                <i class="nav-icon fas fa-chart-line"></i>
                <p>Channels Dashboard</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/channels-listing']" class="nav-link">
                <i class="nav-icon fas fa-list"></i>
                <p>Active Listing</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/channels-manage']" class="nav-link">
                <i class="nav-icon fas fa-network-wired"></i>
                <p>Manage Channels</p>
              </a>
            </li>
          </ul>
        </li>

        <!--li class="nav-item">
          <a [routerLink]="['/billing']" class="nav-link">
            <i class="nav-icon fas fa-chart-bar"></i>
            <p>Billing</p>
          </a>
        </li-->

        <li class="nav-item treeview">
          <a href="#" class="nav-link">
            <i class="nav-icon fas fa-cog"></i>
            <p>Settings</p>
          </a>
          <ul class="nav nav-treeview" style="margin-left: 35px">
            <li class="nav-item">
              <a [routerLink]="['/settings/country']" class="nav-link">
                <i class="nav-icon fas fa-flag"></i>
                <p>Country</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/settings/currency']" class="nav-link">
                <i class="nav-icon fas fa-wallet"></i>
                <p>Currency</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/settings/order-status']" class="nav-link">
                <i class="nav-icon fas fa-network-wired"></i>
                <p>Order Status</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                [routerLink]="['/settings/order-status-mapping']"
                class="nav-link"
              >
                <i class="nav-icon fas fa-network-wired"></i>
                <p>Order Status Mapping</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/settings/endicia-settings']" class="nav-link">
                <i class="nav-icon fas fa-network-wired"></i>
                <p>Endicia Settings</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/settings/users']" class="nav-link">
                <i class="nav-icon fas fa-users"></i>
                <p>Users</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/settings/stores']" class="nav-link">
                <i class="nav-icon fas fa-users"></i>
                <p>Store Address</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/plugin']" class="nav-link">
                <i class="nav-icon fas fa-puzzle-piece"></i>
                <p>Plugins & Extensions</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/settings/cash-register']" class="nav-link">
                <i class="nav-icon far fa-money-bill-alt"></i>
                <p>Cash Register</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/settings/reports-admin']" class="nav-link">
                <i class="nav-icon fas fa-chart-bar"></i>
                <p>Reports Definition</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/settings/product-settings']" class="nav-link">
                <i class="nav-icon fas fa-filter"></i>
                <p>Product Settings</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/settings/reward-settings']" class="nav-link">
                <i class="nav-icon fas fa-wallet"></i>
                <p>Reward Points</p>
              </a>
            </li>
            <li class="nav-item treeview">
              <a href="#" class="nav-link">
                <i class="nav-icon fas fa-envelope"></i>
                <p>Email Settings</p>
              </a>
              <ul class="nav nav-treeview" style="margin-left: 35px">
                <li class="nav-item">
                  <a
                    [routerLink]="['/settings/email-settings']"
                    class="nav-link"
                  >
                    <i class="nav-icon fas fa-cog"></i>
                    <p>Mailbox Settings</p>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    [routerLink]="['/settings/email-template']"
                    class="nav-link"
                  >
                    <i class="nav-icon fas fa-envelope-open-text"></i>
                    <p>Email Templates</p>
                  </a>
                </li>
              </ul>
            </li>

            <li class="nav-item">
              <a [routerLink]="['/settings/system-setting']" class="nav-link">
                <i class="nav-icon fas fa-cog"></i>
                <p>System Settings</p>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
    <!-- /.sidebar-menu -->
  </div>
  <!-- /.sidebar -->
</aside>
