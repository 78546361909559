import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ChannelsService } from 'src/app/utils/services/channels.service';
import { CommonService } from 'src/app/utils/services/common.service';
import { MantleConfirmDialogComponent } from '../../mantle-confirm-dialog/mantle-confirm-dialog.component';
import { ChannelActivateBcComponent } from '../activation/channel-activate-bc/channel-activate-bc.component';
import { ChannelActivateShopifyComponent } from '../activation/channel-activate-shopify/channel-activate-shopify.component';
import { ChannelActivateTcgComponent } from '../activation/channel-activate-tcg/channel-activate-tcg.component';
import { ChannelActivateAmazonSpComponent } from '../activation/channel-activate-amazon-sp/channel-activate-amazon-sp.component';
import {
  CHANNEL_REF_ENUM,
  MANTLE_MODAL_NAME,
} from 'src/app/utils/enums/mantle-enums';
import { Subscription } from 'rxjs';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';
import { ChannelActivateCardtraderComponent } from '../activation/channel-activate-cardtrader/channel-activate-cardtrader.component';
import { ChannelActivateWcComponent } from '../activation/channel-activate-wc/channel-activate-wc.component';
import { ChannelActivatePosComponent } from '../activation/channel-activate-pos/channel-activate-pos.component';

@Component({
  selector: 'channel-list',
  templateUrl: './channel-list.component.html',
  styleUrls: ['./channel-list.component.scss'],
})
export class ChannelListComponent implements OnInit {
  channelList: Array<any> = [];
  private confirmModalSubscription!: Subscription;
  private channelModalSubscription!: Subscription;
  timezone: any = this.commonService.getTimeZone();
  constructor(
    private channelsService: ChannelsService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private mantleModalService: MantleModalService
  ) {}

  ngOnInit(): void {
    this.init_data();

    this.channelsService.channelAdded.subscribe(() => {
      this.init_data();
    });
  }

  init_data() {
    this.channelModalSubscription?.unsubscribe();
    this.spinner.show();
    const obs = this.channelsService.getChannels();
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.channelList = res;
      },
      (err) => {
        this.spinner.hide();

        this.toastr.error(err.error.message);
      }
    );
  }

  onChannelEnableDisable(index, action, deleted = 0) {
    const modalRef = this.modalService.open(MantleConfirmDialogComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'sm',
      backdrop: 'static',
    });
    var header = '';
    var message = '';

    if (deleted == 1) {
      header = 'Delete Sales Channel';
      message = 'Are you sure want to delete this Sales Channel?';
    } else if (action == 0) {
      header = 'Disable Sales Channel';
      message = 'Are you sure want to Disable this Sales Channel?';
    } else if (action == 1) {
      header = 'Enable Sales Channel';
      message = 'Are you sure want to Enable this Sales Channel?';
    }

    modalRef.componentInstance.header = header;
    modalRef.componentInstance.message = message;
    this.confirmModalSubscription = this.commonService.modal_close.subscribe(
      (data: any) => {
        if (data.name === MANTLE_MODAL_NAME.CONFIRM_DIALOG) {
          if (data.data.action_id == 1) {
            const channel = this.channelList[index];
            const requestPayload = {
              sales_channel_id: channel.sales_channel_id,
              sales_channel_status: action,
              is_deleted: deleted,
            };
            this.spinner.show();
            const obs = this.channelsService.updateSalesChannel(requestPayload);
            obs.subscribe(
              (res) => {
                this.spinner.hide();
                this.init_data();
              },
              (err) => {
                this.spinner.hide();

                this.toastr.error(err.error.message);
              }
            );
          }

          modalRef.close();
        }
      }
    );
  }

  onViewDetails(index) {
    const channel = this.channelList[index];
    const channel_ref = channel.channel_ref;
    if (channel_ref === CHANNEL_REF_ENUM.TCG) {
      const modalRef = this.mantleModalService.open(
        ChannelActivateTcgComponent,
        MANTLE_MODAL_NAME.TCG_ACTIVATION_MODAL,
        {
          ariaLabelledBy: 'modal-basic-title',
          size: 'lg',
          backdrop: 'static',
        }
      );
      modalRef.componentInstance.channelOption = channel.channel_tcg;
      modalRef.componentInstance.salesChannel = channel;

      this.channelModalSubscription = this.commonService.modal_close.subscribe(
        (data) => {
          if (data === MANTLE_MODAL_NAME.TCG_ACTIVATION_MODAL) {
            modalRef.close();
            this.init_data();
          }
        }
      );
    } else if (channel_ref === CHANNEL_REF_ENUM.BC) {
      const modalRef = this.mantleModalService.open(
        ChannelActivateBcComponent,
        MANTLE_MODAL_NAME.BC_ACTIVATION_MODAL,
        {
          ariaLabelledBy: 'modal-basic-title',
          size: 'lg',
          backdrop: 'static',
        }
      );
      modalRef.componentInstance.channelOption = channel.channel_bc;
      modalRef.componentInstance.salesChannel = channel;

      this.channelModalSubscription = this.commonService.modal_close.subscribe(
        (data) => {
          if (data === MANTLE_MODAL_NAME.BC_ACTIVATION_MODAL) {
            modalRef.close();
            this.init_data();
          }
        }
      );
    } else if (channel_ref === CHANNEL_REF_ENUM.SHOPIFY) {
      const modalRef = this.mantleModalService.open(
        ChannelActivateShopifyComponent,
        MANTLE_MODAL_NAME.SHOPIFY_ACTIVATION_MODAL,
        {
          ariaLabelledBy: 'modal-basic-title',
          size: 'lg',
          backdrop: 'static',
        }
      );
      modalRef.componentInstance.channelOption = channel.channel_shopify;
      modalRef.componentInstance.salesChannel = channel;

      this.channelModalSubscription = this.commonService.modal_close.subscribe(
        (data) => {
          if (data === MANTLE_MODAL_NAME.SHOPIFY_ACTIVATION_MODAL) {
            modalRef.close();
            this.init_data();
          }
        }
      );
    } else if (channel_ref === CHANNEL_REF_ENUM.AMAZON_SP) {
      const modalRef = this.mantleModalService.open(
        ChannelActivateAmazonSpComponent,
        MANTLE_MODAL_NAME.AMAZON_SP_ACTIVATION_MODAL,
        {
          ariaLabelledBy: 'modal-basic-title',
          size: 'lg',
          backdrop: 'static',
        }
      );

      modalRef.componentInstance.channelOption = channel.channel_amazon_sp;
      modalRef.componentInstance.salesChannel = channel;

      this.channelModalSubscription = this.commonService.modal_close.subscribe(
        (data) => {
          if (data === MANTLE_MODAL_NAME.AMAZON_SP_ACTIVATION_MODAL) {
            modalRef.close();
            this.init_data();
          }
        }
      );
    } else if (channel_ref === CHANNEL_REF_ENUM.CARD_TRADER) {
      const modalRef = this.mantleModalService.open(
        ChannelActivateCardtraderComponent,
        MANTLE_MODAL_NAME.CARDTRADER_ACTIVATION_MODAL,
        {
          ariaLabelledBy: 'modal-basic-title',
          size: 'lg',
          backdrop: 'static',
        }
      );

      modalRef.componentInstance.channelOption = channel.channel_cardtrader;
      modalRef.componentInstance.salesChannel = channel;

      this.channelModalSubscription = this.commonService.modal_close.subscribe(
        (data) => {
          if (data === MANTLE_MODAL_NAME.CARDTRADER_ACTIVATION_MODAL) {
            modalRef.close();
            this.init_data();
          }
        }
      );
    } else if (channel_ref === CHANNEL_REF_ENUM.WOO_COMMERCE) {
      const modalRef = this.mantleModalService.open(
        ChannelActivateWcComponent,
        MANTLE_MODAL_NAME.WOO_COMMERCE_ACTIVATION_MODAL,
        {
          ariaLabelledBy: 'modal-basic-title',
          size: 'lg',
          backdrop: 'static',
        }
      );

      modalRef.componentInstance.channelOption = channel.channel_wc;
      modalRef.componentInstance.salesChannel = channel;

      this.channelModalSubscription = this.commonService.modal_close.subscribe(
        (data) => {
          if (data === MANTLE_MODAL_NAME.WOO_COMMERCE_ACTIVATION_MODAL) {
            modalRef.close();
            this.init_data();
          }
        }
      );
    } else if (channel_ref === CHANNEL_REF_ENUM.POS) {
      const modalRef = this.mantleModalService.open(
        ChannelActivatePosComponent,
        MANTLE_MODAL_NAME.CHANNEL_POS_ACTIVATION_MODAL,
        {
          ariaLabelledBy: 'modal-basic-title',
          size: 'lg',
          backdrop: 'static',
        }
      );

      modalRef.componentInstance.salesChannel = channel;

      this.channelModalSubscription = this.commonService.modal_close.subscribe(
        (data) => {
          if (data === MANTLE_MODAL_NAME.CHANNEL_POS_ACTIVATION_MODAL) {
            modalRef.close();
            this.init_data();
          }
        }
      );
    }
  }

  ngOnDestroy() {
    this.confirmModalSubscription?.unsubscribe();
  }
}
