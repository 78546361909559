<div class="col-md-12 small table-responsive">
  <table class="table table-striped dataTable">
    <thead>
      <tr>
        <th></th>
        <th>Sales Channel</th>
        <th>Description</th>
        <th>Date Added</th>
        <th>Status</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let channel of channelList; let i = index">
        <tr>
          <td>
            <img
              src="{{ channel.channel_option.channel_image_url }}"
              class="img-thumbnail rounded float-left"
              width="50px"
              height="50px"
            />
          </td>
          <td>{{ channel.channel_option.channel_name }}</td>
          <td>{{ channel.sales_channel_description }}</td>
          <td>{{ channel.createdAt | date: 'short':timezone }}</td>
          <td
            [ngClass]="
              channel.sales_channel_status == '1'
                ? 'text-success'
                : 'text-warning'
            "
          >
            <span *ngIf="channel.sales_channel_status == '1'">Active</span>
            <span *ngIf="channel.sales_channel_status != '1'">In Active</span>
          </td>
          <td>
            <div class="dropdown" data-bs-toggle="dropdown">
              <button
                class="btn btn-outline btn-sm dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Action
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <button
                  type="button"
                  class="dropdown-item"
                  (click)="onViewDetails(i)"
                >
                  <i class="fas fa-info-circle"></i> View Details
                </button>
                <button
                  *ngIf="channel.sales_channel_status == '1'"
                  type="button"
                  class="dropdown-item"
                  (click)="onChannelEnableDisable(i, 0)"
                >
                  <i class="fas fa-file-invoice"></i> Disable
                </button>
                <button
                  *ngIf="channel.sales_channel_status != '1'"
                  type="button"
                  class="dropdown-item"
                  (click)="onChannelEnableDisable(i, 1)"
                >
                  <i class="fas fa-file-invoice"></i> Enable
                </button>
                <button
                  type="button"
                  class="dropdown-item"
                  (click)="onChannelEnableDisable(i, 0, 1)"
                >
                  <i class="fas fa-trash"></i> Delete
                </button>
              </div>
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
