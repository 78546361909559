import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import {
  CASH_REGISSTER_STATUS,
  CASH_REGISTER_TRANSACTION_TYPES,
  MANTLE_MODAL_NAME,
  PAYMENT_METHOD,
} from 'src/app/utils/enums/mantle-enums';
import { Auth0Service } from 'src/app/utils/services/auth0.service';
import { CashRegisterService } from 'src/app/utils/services/cash-register.service';
import { CommonService } from 'src/app/utils/services/common.service';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';
import { MerchantService } from 'src/app/utils/services/merchant.service';
import { OrderService } from 'src/app/utils/services/order.service';
import { UserService } from 'src/app/utils/services/user.service';
import { MantleOrderCreateComponent } from '../../mantle-order-create/mantle-order-create.component';

@Component({
  selector: 'order-return-details',
  templateUrl: './order-return-details.component.html',
  styleUrls: ['./order-return-details.component.scss'],
})
export class OrderReturnDetailsComponent implements OnInit, OnDestroy {
  @Input() order_id;
  @Input() order_return_id;

  orderDetails: any = {
    return_date: new Date().toISOString(),
  };
  orderItems: Array<any> = [];
  activeRegisters: Array<any> = [];
  cash_register_id: any;
  cashRegisterStore: any;
  cashRegisterUser: any;
  paymentTerminal: any;
  userProfile: any = {};
  paymentMethod: Array<any> = [];

  constructor(
    private fb: UntypedFormBuilder,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private mantleModalService: MantleModalService,
    private toastr: ToastrService,
    private orderService: OrderService,
    private cashRegisterService: CashRegisterService,
    private auth0Service: Auth0Service,
    private userService: UserService,
    private merchantService: MerchantService
  ) {}

  ngOnInit(): void {
    this.initValues();
  }

  initValues = () => {
    if (this.order_id != null && this.order_id != undefined) {
      this.getLookupValues();
      const uProfile = this.auth0Service.getProfile();

      if (uProfile) {
        this.userService.getUserByEmail(uProfile.email).subscribe(
          (res) => {
            this.userProfile = res;

            this.getUserCashRegisters();
          },
          (err) => {
            this.toastr.error(err);
          }
        );
      }

      this.getOrderDetails();
    }
    if (this.order_return_id != null && this.order_return_id != undefined) {
      this.getOrderReturnDetails();
    }
  };

  getOrderDetails = () => {
    this.spinner.show();
    this.orderService.getOrderById(this.order_id).subscribe(
      (res) => {
        this.spinner.hide();
        this.orderDetails = { ...this.orderDetails, ...res };
        if (this.order_return_id == null || this.order_return_id == undefined)
          this.orderItems = this.orderDetails?.order_items?.map((x) => {
            return {
              ...x,
              ...{
                tax_amount: x.total * (res.sale_tax_pct / 100),
              },
            };
          });
        this.patchFormValue(res);
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err?.error?.message);
      }
    );
  };

  getOrderReturnDetails = () => {
    this.spinner.show();
    this.orderService.getReturnOrderById(this.order_return_id).subscribe(
      (res) => {
        this.spinner.hide();
        this.patchReturnDetails(res);
        this.order_id = res.order_id;
        this.getOrderDetails();
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err?.error?.message);
      }
    );
  };

  onUpdateReturnQty = ($event, index) => {
    var itemDetails = this.orderItems[index];
    if (
      parseInt(itemDetails.return_quantity) > parseInt(itemDetails.quantity) ||
      parseInt(itemDetails.return_quantity) < 0
    ) {
      this.toastr.warning('Invalid Return Quantity');
      itemDetails.return_quantity = 0;
    }

    this.calculateTotal();
  };

  calculateTotal = () => {
    const returnItems = this.orderItems.filter(
      (x) => x.selected == true && x.return_quantity > 0
    );

    const return_amount = returnItems?.reduce((prev: any, next: any) => {
      return (
        parseFloat(prev) +
        (isNaN(next.total)
          ? 0
          : parseFloat(next.total) + parseFloat(next.tax_amount)) *
          (next.return_quantity / next.quantity)
      );
    }, 0);

    if (!this.orderDetails.partial_refund) {
      this.orderDetails.return_amount = Math.round(return_amount * 100) / 100;
    }

    if (this.orderDetails.return_store_credit) {
      this.orderDetails.return_store_credit_amount = this.orderDetails.return_amount;
      this.orderDetails.return_amount = 0;
    } else {
      if (this.orderDetails.return_store_credit_amount > 0)
        this.orderDetails.return_amount = this.orderDetails.return_store_credit_amount;
      this.orderDetails.return_store_credit_amount = 0;
    }

    const restocking_fee =
      Math.round(
        (return_amount -
          (this.orderDetails.return_amount +
            this.orderDetails.return_store_credit_amount)) *
          100
      ) / 100;

    const order_total_new =
      parseFloat(this.orderDetails.order_total) -
      (this.orderDetails.return_amount +
        this.orderDetails.return_store_credit_amount);
    this.orderDetails.restocking_fee = restocking_fee;
    this.orderDetails.order_total_new = Math.round(order_total_new * 100) / 100;
  };

  onSelectItem = ($event, index) => {
    if (!$event.target.checked) {
      this.orderItems[index].return_quantity = 0;
    }
    this.calculateTotal();
  };

  onReturnOrder = (confirmed = false) => {
    if (!confirmed) {
      const modalRef = this.mantleModalService.confirmDialog(
        `Confirm Order Return`,
        'Once you receive and inspect the returned item(s), confirm to initiate the refund process. ',
        this.onReturnOrder,
        [true]
      );
      return;
    }

    const returnItems = this.orderItems.filter(
      (x) => x.selected == true && x.return_quantity > 0
    );

    if (returnItems.length > 0) {
      this.spinner.show();
      const requestPayload = {
        ...this.orderDetails,
        ...{
          order_return_items: returnItems,
        },
      };
      this.orderService.returnOrder(requestPayload).subscribe(
        (res) => {
          this.patchReturnDetails(res);
          this.spinner.hide();
          this.refundTransaction();
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err?.error?.message);
        }
      );
    }
  };

  patchFormValue = (res) => {};

  patchReturnDetails = (res) => {
    this.order_return_id = res.order_return_id;
    this.orderDetails = { ...this.orderDetails, ...res };
    this.orderItems = res.order_return_items.map((x) => {
      x.selected = true;
      return {
        ...x,
        ...{
          selected: true,
        },
      };
    });
  };

  getUserCashRegisters = async () => {
    this.cashRegisterService
      .filterCashRegister({
        user_id: this.userProfile.user_id,
        status: CASH_REGISSTER_STATUS.RUNNING,
      })
      .subscribe(
        (res) => {
          this.activeRegisters = res;

          //If only single register is assigned to current user
          if (this.activeRegisters.length == 1) {
            this.activeRegisters[0].active = true;
            this.toggleActiveRegister(null, this.activeRegisters[0], 0);
          }
        },
        (err) => {
          this.toastr.error(err);
        }
      );
  };

  toggleActiveRegister = async ($event, register: any, index) => {
    if ($event != null) {
      if (!$event.target.checked) {
        this.cash_register_id = null;
        this.cashRegisterStore = null;
        this.cashRegisterUser = null;
        return;
      }
    }

    this.cash_register_id = register.cash_register_id;
    this.cashRegisterStore = register.store;
    this.cashRegisterUser = register?.cash_register_users?.user;
    this.paymentTerminal = register?.terminal;
  };

  getLookupValues() {
    //this.spinner.show();

    const obs = this.commonService.getLookupValues();
    obs.subscribe(
      (res) => {
        this.spinner.hide();

        //this.orderStatus = res['order_status'];
        //this.currency = res['currency'];
        //this.orderSource = res['order_source'];
        this.paymentMethod = res['payment_method'];
        //this.countries = res['countries'];
      },
      (err) => {
        //this.spinner.hide();
      }
    );
  }

  refundTransaction = () => {
    if (
      this.orderDetails.payment_method_id == PAYMENT_METHOD.CASH &&
      this.cash_register_id != null
    ) {
      this.spinner.show();
      this.cashRegisterService
        .cashRegisterTransaction({
          cash_register_id: this.cash_register_id,
          transaction_type: CASH_REGISTER_TRANSACTION_TYPES.REVERSE,
          credit: 0,
          debit: parseFloat(this.orderDetails.return_amount)?.toFixed(2),
          order_id: this.order_id,
        })
        .subscribe(
          (res) => {
            this.spinner.hide();
          },
          (err) => {
            this.spinner.hide();
          }
        );
      return;
    } else if (
      this.orderDetails.payment_method_id == PAYMENT_METHOD.CREDIT_DEBIT_CARD &&
      this.paymentTerminal?.terminal_id
    ) {
      this.spinner.show();
      this.merchantService
        .pushCardPayment(
          this.order_id,
          this.paymentTerminal?.terminal_id,
          'Refund',
          this.orderDetails.return_amount
        )
        .subscribe(
          (res) => {
            this.spinner.hide();
          },
          (err) => {
            this.spinner.hide();
          }
        );
      return;
    } else {
      this.toastr.warning(
        `Auto-Refunds are not supported by the selected payment method.`
      );
    }
  };

  onViewOrder = () => {
    const modalRef = this.mantleModalService.open(
      MantleOrderCreateComponent,
      MANTLE_MODAL_NAME.ORDER_ADD_NEW,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'xl',
        backdrop: 'static',
      }
    );
    modalRef.componentInstance.order_id = this.order_id;
  };

  ngOnDestroy(): void {}

  closeModal() {
    this.commonService.modal_close.next(MANTLE_MODAL_NAME.ORDER_RETURN_DETAILS);
  }
}
