import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { CommonService } from './common.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MailingService {
  constructor(
    private apiService: ApiService,
    private commonService: CommonService
  ) {}

  getDefaultSetting(): Observable<any> {
    return this.apiService.get(`/api/v1/email/settings`);
  }
  updateEmailSettings(requestPayload): Observable<any> {
    return this.apiService.post('/api/v1/email/settings', requestPayload);
  }
  getEmailTemplates(): Observable<any> {
    return this.apiService.get('/api/v1/email/template');
  }
  getEmailTemplateById(id): Observable<any> {
    return this.apiService.get(`/api/v1/email/template/${id}`);
  }
  updateEmailTemplate(requestPayload): Observable<any> {
    return this.apiService.post('/api/v1/email/template', requestPayload);
  }
  deleteEmailTemplate(id): Observable<any> {
    return this.apiService.delete(`/api/v1/email/template/${id}`);
  }
  sendTestEmail(requestPayload): Observable<any> {
    return this.apiService.post('/api/v1/email/test', requestPayload);
  }
  sendEmail(requestPayload): Observable<any> {
    //console.log(template_id);
    console.log(requestPayload);
    return this.apiService.post(
      `/api/v1/email/send/${requestPayload.template_id}`,
      requestPayload
    );
  }
  exchangeCodeForToken(code): Observable<any> {
    return this.apiService.post(`/api/v1/email/settings/gmail-token`, {
      code: code,
    });
  }
}
