<div class="card">
  <div class="card-header">
    <div class="card-tools">
      <btn-barcode-generator
        [upc]="data['UPC']"
        [productDetails]="data"
      ></btn-barcode-generator>

      <mantle-favorite [data]="data"></mantle-favorite>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12 img-card">
        <img [src]="cardImage" />
      </div>
    </div>
  </div>
</div>
