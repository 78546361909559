<div class="card">
  <div class="card-header">
    <div class="card-tools">
      <button
        type="button"
        *ngIf="selectedShipments.length > 1"
        class="btn btn-outline-success btn-sm"
        (click)="createCarrierManifest()"
      >
        Create Carrier Manifest
      </button>

      <button
        type="button"
        *ngIf="selectedShipments.length > 0"
        class="btn btn-outline-secondary btn-sm ml-4"
        (click)="requestCarrierPickup()"
      >
        Request a Carrier Pickup
      </button>
    </div>
    <h5>Shipments</h5>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12 table-responsive small">
        <table class="table table-striped dataTable w-auto">
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>Shipment #</th>
              <th>Order #</th>
              <th>Tracking #</th>
              <th>Service</th>
              <th>Recipient</th>
              <th>Ship Date</th>
              <th>Manifest #</th>
              <th>Pickup #</th>
              <th>Status</th>
              <th>...</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let shipment of shipmentList; let i = index">
              <tr>
                <td>
                  <input
                    type="checkbox"
                    class="select-checkbox"
                    [disabled]="shipment.manifest_id"
                    (change)="checkShipment($event, i)"
                  />
                </td>
                <td>
                  <a href="#" (click)="openShipment(i, shipment.order_id)"
                    ><u>{{ shipment.id }}</u></a
                  >
                </td>
                <td>
                  <a href="#" (click)="openOrder(shipment.order_id)"
                    ><u>{{ shipment.order_number }}</u></a
                  >
                </td>
                <td>
                  <a href="#" (click)="openTracking(i)"
                    ><u>{{ shipment.tracking_number }}</u></a
                  >
                </td>
                <td>{{ shipment.endicia_carrier?.carrier_description }}</td>
                <td>{{ shipment.request_payload?.to_address?.name }}</td>
                <td>
                  {{ shipment.shipment_date | date: 'shortDate':timezone }}
                </td>
                <td>
                  <a href="#" (click)="openManifest(shipment.manifest_id)"
                    ><u>{{ shipment.manifest_id }}</u></a
                  >
                </td>
                <td>
                  <a href="#" (click)="openPickup(shipment.pickup_id)"
                    ><u>{{ shipment.pickup_id }}</u></a
                  >
                </td>
                <td>{{ shipment.status }}</td>
                <td>
                  <div class="dropdown" data-bs-toggle="dropdown">
                    <button
                      class="btn btn-outline btn-sm dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      More Actions
                    </button>
                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <button
                        type="button"
                        class="dropdown-item"
                        (click)="viewLabel(shipment.label_id)"
                      >
                        <i class="fas fa-tags"></i> View Label
                      </button>
                      <button
                        type="button"
                        class="dropdown-item"
                        (click)="voidLabel(shipment.label_id)"
                      >
                        <i class="fas fa-ban"></i> Void Label
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
