<div class="row">
  <div class="col-md-12 small table-responsive">
    <table class="table table-striped dataTable">
      <thead>
        <tr>
          <th>Store Name</th>
          <th>Email</th>
          <th>Phone No#</th>
          <th>Address</th>
          <th>Default Location</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let store of storeList">
          <tr>
            <td>{{ store.store_name }}</td>
            <td>{{ store.store_email }}</td>
            <td>{{ store.store_phone }}</td>
            <td>{{ store.store_address }}</td>
            <td>{{ store.is_default }}</td>
            <td>
              <div class="dropdown" data-bs-toggle="dropdown">
                <button
                  class="btn btn-outline btn-sm dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Action
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <button
                    type="button"
                    class="dropdown-item"
                    (click)="onEdit(store.store_id)"
                  >
                    <i class="fas fa-edit"></i> Edit
                  </button>
                  <button
                    type="button"
                    class="dropdown-item"
                    (click)="onEdit(store.store_id)"
                  >
                    <i class="fas fa-info"></i> Set as Default
                  </button>
                  <button
                    type="button"
                    class="dropdown-item"
                    (click)="onDeleteStore(store.store_id)"
                  >
                    <i class="fas fa-lock"></i> Delete
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
