import { Component } from '@angular/core';

@Component({
  selector: 'app-settings-mailing',
  templateUrl: './settings-mailing.component.html',
  styleUrl: './settings-mailing.component.scss'
})
export class SettingsMailingComponent {

}
