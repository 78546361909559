import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
import { AppConfig } from 'src/app/utils/services/app.config';
import { ChannelsService } from 'src/app/utils/services/channels.service';
import { CommonService } from 'src/app/utils/services/common.service';
import { SettingsService } from 'src/app/utils/services/settings.service';

@Component({
  selector: 'app-channel-activate-amazon-sp',
  templateUrl: './channel-activate-amazon-sp.component.html',
  styleUrls: ['./channel-activate-amazon-sp.component.scss'],
})
export class ChannelActivateAmazonSpComponent implements OnInit {
  @Input() channelOption: any = {};
  validated: boolean = false;
  storeList: Array<any> = [];
  aws_regions: any = [
    {
      id: 'na',
      description: 'North America',
    },
    {
      id: 'eu',
      description: 'Europe',
    },
    {
      id: 'fe',
      description: 'Far East',
    },
  ];
  aws_market_places = [];

  constructor(
    private fb: UntypedFormBuilder,
    private channelsService: ChannelsService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private config: AppConfig,
    private settingsService: SettingsService
  ) {}

  amazonConfigForm = this.fb.group({
    channel_sp_id: [],
    channel_ref: [],
    selling_partner_app_client_id: ['', Validators.required],
    selling_partner_app_client_secret: ['', Validators.required],
    aws_access_key_id: ['', Validators.required],
    aws_secret_access_key: ['', Validators.required],
    refresh_token: ['', Validators.required],
    seller_id: ['', Validators.required],
    aws_ssession_token: [{ value: '', disabled: true }],
    aws_selling_partner_role: [{ value: '', disabled: true }],
    channel_amazon_description: ['Amazon SP Channel'],
    update_frequency: [30],
    region: ['na'],
    use_sandbox: [false],
    marketplace_id: ['ATVPDKIKX0DER'],
    created_at_min: [],
    store_ids: [],
  });

  ngOnInit(): void {
    if (this.channelOption != undefined) {
      setTimeout(() => {
        this.amazonConfigForm.patchValue(this.channelOption);
      }, 0);
    }

    this.settingsService.getStores().subscribe({
      next: (res) => {
        this.storeList = res;
      },
      error: (err) => {
        this.spinner.hide();
      },
      complete: () => {
        this.spinner.hide();
      },
    });
  }

  onValidate() {
    this.validated = false;
    this.spinner.show();
    const obs = this.channelsService.validateAmazonSp(
      this.amazonConfigForm.value
    );
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        if (res != undefined) {
          if (res.is_valid) {
            this.validated = true;
            this.aws_market_places = res.market_places.map((x) => {
              return {
                id: x.marketplace.id,
                description: `${x.marketplace.name} - ${x.marketplace.countryCode}`,
              };
            });
            this.toastr.success('Validation Successful');
          } else {
            this.validated = false;
            this.toastr.error(res.error.message, 'Validation Failed');
          }
        } else {
          this.validated = false;
          this.toastr.error('Validation Failed', 'Validation Failed');
        }
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(JSON.stringify(err), 'An Error Occurred');
      }
    );
  }

  onSave() {
    this.spinner.show();
    const obs = this.channelsService.createAmazonSpChannel(
      this.amazonConfigForm.value
    );
    obs.subscribe(
      (res) => {
        this.amazonConfigForm.patchValue(res);
        this.spinner.hide();
        this.toastr.success('Channel Added Successfully');
        this.closeModal();
        this.channelsService.channelAdded.next({});
        this.settingsService.statusMappingDialog();
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(JSON.stringify(err), 'An Error Occurred');
      }
    );
  }

  onUpdate() {
    this.spinner.show();
    const obs = this.channelsService.updateAmazonSpChannel(
      this.amazonConfigForm.value
    );
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.toastr.success('Update Successful');
        this.closeModal();
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(JSON.stringify(err.error), 'An Error Occurred');
      }
    );
  }
  onUpdateFrequency() {
    this.spinner.show();
    const obs = this.channelsService.updateAmazonSpChannel({
      channel_sp_id: this.amazonConfigForm.value['channel_sp_id'],
      channel_ref: this.amazonConfigForm.value['channel_ref'],
      update_frequency: this.amazonConfigForm.value['update_frequency'],
    });
    obs.subscribe(
      (res) => {
        this.spinner.hide();
        this.toastr.success('Update Successful');
        this.closeModal();
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(JSON.stringify(err.error), 'An Error Occurred');
      }
    );
  }

  closeModal() {
    this.commonService.modal_close.next(
      MANTLE_MODAL_NAME.AMAZON_SP_ACTIVATION_MODAL
    );
  }
}
