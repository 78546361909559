import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
import { AppService } from 'src/app/utils/services/app.service';
import { CommonService } from 'src/app/utils/services/common.service';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';
import { SettingsService } from 'src/app/utils/services/settings.service';
import { MantleConfirmDialogComponent } from 'src/app/views/mantle-confirm-dialog/mantle-confirm-dialog.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output() toggleMenuSidebar: EventEmitter<any> = new EventEmitter<any>();
  public searchForm: UntypedFormGroup;
  public init_load: any;

  constructor(
    private appService: AppService,
    private commonService: CommonService,
    private settingsService: SettingsService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private mantleModalService: MantleModalService
  ) {
    this.commonService.fullScreenMode.subscribe((data) => {
      this.toggleMenuSidebar.emit(data);
    });
  }

  ngOnInit() {
    this.searchForm = new UntypedFormGroup({
      search: new UntypedFormControl(null),
    });

    /*const lookups_obs = this.commonService.getLookupValues();
    lookups_obs.subscribe(res=>{
      this.init_load = res.init_load
    },(err)=>{
      
    })*/
    this.settingsService
      .getSystemSettingByName('INIT_CHANNEL_LOAD')
      .subscribe((res) => {
        this.init_load = parseInt(res.value);
      });
  }

  logout() {
    this.appService.logout();
  }

  disableLoad() {
    const modalRef = this.mantleModalService.confirmDialog(
      'Disable Channel Initial Load',
      'By confriming this, channel data will be updated by transactions from other channels'
    );

    let confirmSubription = this.commonService.modal_close.subscribe(
      (data: any) => {
        if (data.name === MANTLE_MODAL_NAME.CONFIRM_DIALOG) {
          if (data.data.action_id == 1) {
            this.spinner.show();
            this.settingsService
              .updateSystemSetting({
                id: 3,
                value: 0,
              })
              .subscribe({
                next: (res) => {
                  this.init_load = 0;
                },
                error: (err) => {
                  this.toastr.error(JSON.stringify(err));
                  this.spinner.hide();
                  confirmSubription.unsubscribe();
                },
                complete: () => {
                  this.spinner.hide();
                  confirmSubription.unsubscribe();
                },
              });
          }
          modalRef.close();
        }
      }
    );
  }
}
