<section class="content">
  <div class="container-fluid">
    <div class="card" *ngIf="productData">
      <div class="card-header">
        <div class="card-tools">
          <button type="button" class="btn btn-tool" (click)="closeModal()">
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="form-group col-md-3">
            <label class="control-label small">Product Line</label>
            <input
              [readOnly]="true"
              type="text"
              [(value)]="productData['Product Line']"
              [(ngModel)]="productData['Product Line']"
              [ngModelOptions]="{
                standalone: true
              }"
              class="form-control form-control-sm float-right"
            />
          </div>
          <div class="col-md-6">
            <label class="control-label small">Product Name</label>
            <input
              [readOnly]="true"
              type="text"
              [(value)]="productData['Product Name']"
              [(ngModel)]="productData['Product Name']"
              [ngModelOptions]="{
                standalone: true
              }"
              class="form-control form-control-sm float-right"
            />
          </div>
          <div class="col-md-3">
            <label class="control-label small">&nbsp;</label>
            <button
              type="button"
              class="btn btn-sm btn-outline-primary form-control form-control-sm float-right"
              (click)="onSaveProduct()"
            >
              Save Product
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <label class="control-label small">Condition</label>
            <input
              [readOnly]="true"
              type="text"
              [(value)]="productData['Condition']"
              [(ngModel)]="productData['Condition']"
              [ngModelOptions]="{
                standalone: true
              }"
              class="form-control form-control-sm"
            />
          </div>
          <div class="col-md-2">
            <label class="control-label small">Foil / NonFoil</label>
            <input
              [readOnly]="true"
              type="text"
              [(value)]="productData['Foil']"
              [(ngModel)]="productData['Foil']"
              [ngModelOptions]="{
                standalone: true
              }"
              class="form-control form-control-sm"
            />
          </div>
          <div class="col-md-2">
            <label class="control-label small">Language</label>
            <input
              [readOnly]="true"
              type="text"
              [(value)]="productData['Language']"
              [(ngModel)]="productData['Language']"
              [ngModelOptions]="{
                standalone: true
              }"
              class="form-control form-control-sm"
            />
          </div>
          <div class="col-md-3">
            <label class="control-label small">Set Name</label>
            <input
              [readOnly]="true"
              type="text"
              [(value)]="productData['Set Name']"
              [(ngModel)]="productData['Set Name']"
              [ngModelOptions]="{
                standalone: true
              }"
              class="form-control form-control-sm"
            />
          </div>
          <div class="col-md-2">
            <label class="control-label small">Card Number</label>
            <input
              [readOnly]="true"
              type="text"
              [(value)]="productData['Card Number']"
              [(ngModel)]="productData['Card Number']"
              [ngModelOptions]="{
                standalone: true
              }"
              class="form-control form-control-sm"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
