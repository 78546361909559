import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import {
  LOCAL_STORAGE_KEYS,
  MANTLE_MODAL_NAME,
  USER_PREFERENCES,
} from 'src/app/utils/enums/mantle-enums';
import { Auth0Service } from 'src/app/utils/services/auth0.service';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';
import { StorageService } from 'src/app/utils/services/storage.service';
import { UserPreferenceService } from 'src/app/utils/services/user-preference.service';
import { ManageQuickLinksComponent } from 'src/app/views/dashboard/mantle-dashboard-widget/quick-links/manage-quick-links/manage-quick-links.component';
import timezones from '../../../../../configs/timezones.json';
@Component({
  selector: 'mantle-user-preference',
  templateUrl: './user-preference.component.html',
  styleUrls: ['./user-preference.component.scss'],
})
export class UserPreferenceComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('NgToggle') NgToggle;
  userProfile: any;
  quickLinksEnabled: boolean;
  userPrefences: Array<any> = [];
  timezone: any = null;
  timezones: any = timezones;

  constructor(
    private auth0Service: Auth0Service,
    private userPreferenceService: UserPreferenceService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private mantleModalService: MantleModalService,
    private storageService: StorageService
  ) {}
  ngOnInit(): void {
    this.userProfile = this.auth0Service.getProfile();
    this.getUserPreferennce();
  }
  ngAfterViewInit(): void {}

  getUserPreferennce = () => {
    this.spinner.show();
    this.userPreferenceService
      .getUserPreferences(this.userProfile.user_id)
      .subscribe(
        (res) => {
          this.userPrefences = res;
          this.spinner.hide();
          this.initPreferences();
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err.message);
        }
      );
  };

  initPreferences = () => {
    if (
      this.userPrefences.find((x) => x.name == USER_PREFERENCES.QUICK_LINKS)
        ?.value == 'true'
    ) {
      this.quickLinksEnabled = true;
      this.NgToggle.toggle({});
    }
    this.timezone = this.userPrefences.find(
      (x) => x.name == USER_PREFERENCES.TIMEZONE
    )?.value;
  };

  toggleQuickLink($event) {
    this.spinner.show();
    this.quickLinksEnabled = !this.quickLinksEnabled;
    const requestPayload = {
      user_id: this.userProfile.user_id,
      description: 'Quick Links',
      name: USER_PREFERENCES.QUICK_LINKS,
      type: 'boolean',
      value: this.quickLinksEnabled,
    };

    this.userPreferenceService.updateUserPreferences(requestPayload).subscribe(
      (res) => {
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message);
      }
    );
  }

  onEditQuickLinks() {
    const modalRef = this.mantleModalService.open(
      ManageQuickLinksComponent,
      MANTLE_MODAL_NAME.MANAGE_QUICK_LINKS,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
        backdrop: 'static',
      }
    );
  }
  onTimeZoneChange = ($event) => {
    if ($event?.abbrev) {
      this.spinner.show();

      this.quickLinksEnabled = !this.quickLinksEnabled;

      const requestPayload = {
        user_id: this.userProfile.user_id,
        description: `User Timezone ${$event?.name}`,
        name: USER_PREFERENCES.TIMEZONE,
        type: 'string',
        value: $event?.name,
      };

      this.userPreferenceService
        .updateUserPreferences(requestPayload)
        .subscribe(
          (res) => {
            this.toastr.success(`User Timezone Set - ${$event?.name}`);
            this.storageService.setItem(
              LOCAL_STORAGE_KEYS.USER_TIMEZONE,
              $event?.name
            );
            this.spinner.hide();
          },
          (err) => {
            this.spinner.hide();
            this.toastr.error(err.message);
          }
        );
    }
  };

  ngOnDestroy(): void {}
}
