<section class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card" style="background-color: transparent">
          <div class="card-header">
            <div class="card-title">
              <h5>Inventory Transfers & Adjustments</h5>
            </div>
            <div class="card-tools">
              <div class="row">
                <div class="col-12">
                  <button
                    type="button"
                    class="btn btn-info btn-sm"
                    style="border: 0px"
                    (click)="viewTransferDetails(null)"
                  >
                    <i class="fas fa-plus"></i> New Transfer / Adjustment
                  </button>
                </div>
              </div>
            </div>
            <!-- /.card-tools -->
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-md-12 table-responsive small">
            <table class="table table-striped dataTable">
              <thead>
                <tr>
                  <th id="transfer_number">Number</th>
                  <th id="operation_type">Type</th>
                  <th id="status">Status</th>
                  <th id="">Date</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let transfer of transfersList">
                  <tr>
                    <td>{{ transfer.transfer_number }}</td>
                    <td>{{ transfer.operation_type | titlecase }}</td>
                    <td>{{ transfer.status | titlecase }}</td>
                    <td>{{ transfer.createdat | date: 'short':timezone }}</td>
                    <td>
                      <div class="dropdown" data-bs-toggle="dropdown">
                        <button
                          class="btn btn-outline btn-sm dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Action
                        </button>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <button
                            type="button"
                            class="dropdown-item"
                            (click)="viewTransferDetails(transfer.transfer_id)"
                          >
                            <i class="fas fa-info-circle"></i> View Details
                          </button>
                          <button
                            type="button"
                            class="dropdown-item"
                            (click)="viewTransferDetails(transfer.transfer_id)"
                          >
                            <i class="fas fa-trash"></i> Delete
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
