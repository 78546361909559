<div class="card">
  <div class="card-header">
    <div class="card-tools"></div>
    <h5>Upload Inventory</h5>
  </div>
  <div class="card-body">
    <ng-container *ngIf="uploadInProgress">
      <div class="row">
        <div class="col-12">
          <div
            class="progress-bar progress-bar-info"
            role="progressbar"
            attr.aria-valuenow="{{ progresspct }}"
            aria-valuemin="0"
            aria-valuemax="100"
            [ngStyle]="{ width: progresspct + '%' }"
          >
            {{ progresspct | number: '1.0-2' }}%
          </div>
        </div>
      </div>
    </ng-container>
    <div class="row">
      <div class="col-3">
        <a class="small" href="./assets/Sample_Inventory_Upload.csv"
          >Sample Upload File</a
        >
      </div>
    </div>
    <div class="row">
      <div class="form-group col-3">
        <label class="control-label small">CSV Pricing & Quantity file</label>
      </div>
      <div class="form-group col-6">
        <input
          type="file"
          name="Upload CSV"
          id="txtFileUpload"
          (change)="uploadListener($event)"
          accept=".csv"
          class="form-control form-control-sm"
          [(disabled)]="uploadInProgress"
        />
      </div>
    </div>
    <div class="row">
      <div class="form-group col-3">
        <label class="control-label small" for="updateOnlyLocal"
          >Update Local Inventory only (Linked channels will not be
          updated)</label
        >
      </div>
      <div class="form-group col-6">
        <input
          type="checkbox"
          id="updateOnlyLocal"
          (change)="updateOnlyLocal($event)"
          class="form-check-input"
          [(disabled)]="uploadInProgress"
        />
      </div>
    </div>
    <ng-container *ngIf="validCsvfile">
      <div class="row">
        <div class="col-md-12">
          <h5>Column , Channel Mapping</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 table-responsive">
          <table class="table table-striped dataTable">
            <thead>
              <tr>
                <th>Price Source Column</th>
                <th>Destination Channel</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <ng-container
                *ngFor="let channel of salesChannelHeaders; let i = index"
              >
                <tr>
                  <td>{{ channel.columnName }}</td>
                  <td>
                    <ng-select
                      [items]="channelList"
                      bindLabel="sales_channel_description"
                      bindValue="sales_channel_id"
                      [(ngModel)]="channel.sales_channel_id"
                      (change)="onChannelChange($event, i)"
                      [ngModelOptions]="{ standalone: true }"
                    >
                    </ng-select>
                  </td>
                  <td>
                    <button
                      type="button"
                      class="btn btn-outline-danger btn-sm mt-2"
                      (click)="onRemoveColumn(i)"
                    >
                      Remove <i class="fas fa-trash" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <button
            type="button"
            class="btb btn-primary btn-sm"
            (click)="onUpload()"
            [(disabled)]="uploadInProgress"
          >
            <i class="nav-icon fas fa-upload"></i> Upload
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<div class="card">
  <div class="card-header">Uploads History</div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12 small table-responsive">
        <table class="table table-striped dataTable">
          <thead>
            <tr>
              <th>No# Records</th>
              <th>Success</th>
              <th>Uploaded File</th>
              <th>Output File</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let upload of uploadHistory; let i = index">
              <tr>
                <td>
                  {{ upload.record_count }}
                </td>
                <td>
                  {{ upload.record_success }}
                </td>
                <td>
                  <a
                    href="javascript:void(0)"
                    (click)="downloadFile('input', upload.input_file_name)"
                  >
                    {{ upload.input_file_name }}
                  </a>
                </td>
                <td>
                  <a
                    href="javascript:void(0)"
                    (click)="downloadFile('output', upload.output_file_name)"
                  >
                    {{ upload.output_file_name }}
                  </a>
                </td>
                <td>
                  {{ upload.createdAt | date: 'short':timezone }}
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
