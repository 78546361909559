import { Component, Input } from '@angular/core';
import { NgxPrintService, PrintOptions } from 'ngx-print';

@Component({
  selector: 'mantle-print',
  templateUrl: './mantle-print.component.html',
  styleUrls: ['./mantle-print.component.scss'],
})
export class MantlePrintComponent {
  @Input() btnLabel = 'Print';
  @Input() printSectionId;
  @Input() useExistingCss = true;
  @Input() printDelay = 0;
  @Input() cssClass = 'btn btn-outline-secondary btn-sm ';
  constructor(private ngxPrintService: NgxPrintService) {}

  onPrint = () => {
    setTimeout(() => {
      this.ngxPrintService.print(
        new PrintOptions({
          printSectionId: this.printSectionId,
          useExistingCss: this.useExistingCss,
          printDelay: this.printDelay,
        })
      );
    }, this.printDelay);
  };
}
