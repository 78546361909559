import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import {
  MANTLE_MODAL_NAME,
  ORDER_SOURCE_ID,
  SYSTEM_SETTINGS,
} from 'src/app/utils/enums/mantle-enums';
import { Auth0Service } from 'src/app/utils/services/auth0.service';
import { CashRegisterService } from 'src/app/utils/services/cash-register.service';
import { CommonService } from 'src/app/utils/services/common.service';
import { InventoryService } from 'src/app/utils/services/inventory.service';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';
import { MerchantService } from 'src/app/utils/services/merchant.service';
import { OrderService } from 'src/app/utils/services/order.service';
import { SettingsService } from 'src/app/utils/services/settings.service';
import { UserService } from 'src/app/utils/services/user.service';
import { MantleOrderReceiptComponent } from 'src/app/views/mantle-order/mantle-order-receipt/mantle-order-receipt.component';

@Component({
  selector: 'app-kiosk-order',
  templateUrl: './kiosk-order.component.html',
  styleUrls: ['./kiosk-order.component.scss'],
})
export class KioskOrderComponent implements OnInit, OnDestroy {
  @Input() order_id;
  timezone: any = this.commonService.getTimeZone(true);
  initFormValues: any = {};
  itemRef: number = 0;
  private productSearchSubscription: Subscription;
  private receiptModalSubscription: Subscription;
  orderItems: Array<any> = [];
  orderItemsInventory: Array<any> = [];
  orderDetails: any;
  orderTaxesList: any;
  disclaimerHtml: any;
  autoPrintReceipt: any;
  orderTaxes: any;

  constructor(
    private fb: UntypedFormBuilder,
    private commonService: CommonService,
    private orderService: OrderService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private inventoryService: InventoryService,
    private settingsService: SettingsService,
    private cashRegisterService: CashRegisterService,
    private auth0Service: Auth0Service,
    private userService: UserService,
    private mantleModalService: MantleModalService,
    private merchantService: MerchantService
  ) {}

  orderForm = this.fb.group({
    order_id: [],
    order_number: [],
    customer_id: ['', Validators.required],
    order_status_id: [0],
    order_source_id: [ORDER_SOURCE_ID.KIOSK],
    store_id: [],
    //payment_method_id: [PAYMENT_METHOD.CASH],
    order_type_id: [],
    order_date: new Date().toISOString(),
    shipment_date: [],
    currency_code: [],
    order_note: [],
    order_total: [0],
    discount_total_total: [0],
    shipment_cost: [0],
    paid_amount: [0],
    change_amount: [0],
    sale_tax_pct: [0],
    sale_tax: [0],
    sub_total: [0],
  });

  customerInfoForm = this.fb.group({
    fullName: ['', Validators.required],
    email: ['', Validators.required],
    terms: ['', Validators.required],
  });

  ngOnInit(): void {
    this.initFormValues['orderForm'] = this.orderForm.getRawValue();

    this.productSearchSubscription = this.orderService.product_search_result.subscribe(
      (res) => {
        this.setSelectedItem(res);
      }
    );

    this.init_values();
  }

  init_values() {
    this.orderForm.controls['order_status_id'].disable();
    //this.getLookupValues();
    if (this.order_id != null && this.order_id != undefined) {
      //this.getOrderDetails();
    } else {
      //this.canEdit = true;
      this.getSystemSettings();
    }

    this.onAddOrderItem();
  }

  setSelectedItem(data, getInventoryData = true) {
    const index = data['itemRef'];
    const productData = data['data'];

    const itemAddedIndex = this.orderItems.findIndex(
      (x) => x.child_sku == productData['child_sku']
    );
    if (itemAddedIndex >= 0) {
      this.toastr.info('Selected Item Already Added');
      this.orderItems[itemAddedIndex].quantity =
        parseInt(this.orderItems[itemAddedIndex].quantity) + 1;

      this.changeQuantity(null, itemAddedIndex);
      return false;
    } else {
      const prodValues: any = {
        item_description: productData['product_name'],
        brand_id: productData['brand_id'],
        child_sku: productData['child_sku'],
        parent_sku: productData['parent_sku'],
        product_name: productData['product_name'],
        edit: false,
        loading_inventory: true,
        quantity: 1,
      };
      this.orderItems[index] = {
        ...this.orderItems[index],
        ...prodValues,
      };
      this.onAddOrderItem();

      if (getInventoryData)
        this.getInventoryData(this.orderItems[index], index);
    }
    return true;
  }

  changeQuantity($event, index) {
    //const quantity = $event.target.value
    var itemDetails = this.orderItems[index];

    const itemInventory = this.orderItemsInventory.find(
      (x) => x.child_sku === itemDetails['child_sku']
    );

    if (itemInventory != undefined /* && this.canEdit */) {
      if ('inventory_id' in itemInventory) {
        this.validateInventory(itemDetails, itemInventory);
        var total = 0;
        if (itemDetails['edited']) {
          total =
            parseFloat(itemDetails['quantity']) *
            parseFloat(itemDetails['unit_price']);
        } else {
          total =
            parseFloat(itemDetails['quantity']) *
            parseFloat(itemInventory['sell_price']);
        }

        itemDetails['total'] = isNaN(total) ? 0 : total.toFixed(2);

        this.calculateTotal();
      }
    } else if (itemDetails['edited']) {
      var total =
        parseFloat(itemDetails['quantity']) *
        parseFloat(itemDetails['unit_price']);
      itemDetails['total'] = isNaN(total) ? 0 : total.toFixed(2);
      this.calculateTotal();
    }
  }

  onAddOrderItem() {
    const emptyIndex = this.orderItems?.filter((x) => x.child_sku == undefined)
      ?.length;
    if (emptyIndex <= 0) {
      this.orderItems.push({
        edit: true,
      });
      this.itemRef = this.orderItems.length - 1;
    }
    //this.setUpcFocus();
  }

  getInventoryData(orderItemData, index) {
    this.inventoryService.getInventoryData(orderItemData).subscribe(
      (res) => {
        this.processInventoryData(orderItemData, res, index);
        this.changeQuantity(null, index);
      },
      (err) => {
        //if (this.canEdit) {
        orderItemData['has_errors'] = true;
        //}
      }
    );
  }

  processInventoryData(orderItemData, inventoryData, index) {
    if (inventoryData != null) {
      if ('inventory_id' in inventoryData) {
        orderItemData['loading_inventory'] = false;

        //Check if current Inventory item exists
        const inIndex = this.orderItemsInventory.findIndex(
          (x) => x.inventory_id == inventoryData['inventory_id']
        );
        if (inIndex > -1) {
          this.orderItemsInventory.splice(inIndex, 1);
        }
        this.orderItemsInventory.push(inventoryData);
        //if (this.canEdit && !this.isExternalSource) {
        this.validateInventory(orderItemData, inventoryData);
        //}
      } else {
        orderItemData['loading_inventory'] = false;
        //if (this.canEdit && !this.isExternalSource) {
        orderItemData['has_errors'] = true;
        //}
      }
    } else {
      orderItemData['loading_inventory'] = false;
      //if (this.canEdit && !this.isExternalSource) {
      orderItemData['has_errors'] = true;
      //}
    }
  }

  validateInventory(orderItemData, inventoryData) {
    orderItemData['currency_code'] = inventoryData['currency_code'];
    orderItemData['stock'] = inventoryData['quantity'];
    orderItemData['reserve'] = inventoryData['quantity_reserve'];
    if (!orderItemData['edited'] /* && this.canEdit */) {
      orderItemData['unit_price'] = inventoryData['sell_price'];
    }

    if (inventoryData['product_status_id'] === 2) {
      orderItemData['has_warning'] = true;
    }
    if (
      inventoryData['product_status_id'] === 3 ||
      inventoryData['available'] <= 0 ||
      orderItemData['stock'] == null
    ) {
      orderItemData['has_errors'] = true;
    }
    if (
      orderItemData['quantity'] !== undefined &&
      !orderItemData['has_errors']
    ) {
      if (
        parseFloat(orderItemData['quantity']) >
        parseFloat(inventoryData['available'])
      ) {
        orderItemData['has_errors'] = true;
      } else {
        orderItemData['has_errors'] = false;
      }
    }
  }

  calculateTotal = () => {
    //if (!this.canUpdateTotals()) return;
    var total = 0.0;
    var sub_total = 0.0;
    var sale_tax = 0.0;
    this.orderItems.forEach((item) => {
      if (item.total !== undefined) {
        sub_total += parseFloat(item.total);
      }
    });

    var discount_total_total = parseFloat(
      this.orderForm.value.discount_total_total
    );
    if (discount_total_total < 0 || isNaN(discount_total_total)) {
      discount_total_total = 0;
      this.orderForm.patchValue({ discount_total_total: 0 });
    }

    var shipment_cost = parseFloat(this.orderForm.value.shipment_cost);

    var sale_tax_pct = parseFloat(this.orderForm.value.sale_tax_pct);

    total =
      sub_total +
      (shipment_cost ? shipment_cost : 0) -
      (discount_total_total ? discount_total_total : 0);

    if (sale_tax_pct > 0)
      sale_tax = Math.round(total * (sale_tax_pct / 100) * 100) / 100;

    total = total + sale_tax;

    this.orderForm.patchValue({
      order_total: Math.round(total * 100) / 100,
      sub_total: Math.round(sub_total * 100) / 100,
      sale_tax: sale_tax,
    });

    //this.calculateChange();

    //this.setUpcFocus();
  };

  onDeleteOrderItem(index, order_item_id, order_id) {
    if (order_item_id !== null && order_item_id !== undefined) {
      this.spinner.show();

      this.orderService.deleteOrderItem(order_item_id, order_id).subscribe(
        (res) => {
          this.init_forms_data(res);
          this.spinner.hide();
          this.itemRef -= 1;
          this.calculateTotal();
        },
        (err) => {
          this.spinner.hide();

          this.toastr.error(JSON.stringify(err.error), 'An Error Occurred');
        }
      );
    } else {
      this.orderItems.splice(index, 1);
      this.itemRef -= 1;
      this.calculateTotal();
    }
    this.onAddOrderItem();
  }

  init_forms_data = async (orderDetails) => {
    this.orderDetails = orderDetails;
    const order = orderDetails;
    this.order_id = order.order_id;
    /*order.order_date = moment
      .utc(order.order_date)
      .local()
      .format('YYYY-MM-DDTHH:mm');*/
    const order_items = orderDetails.order_items;
    //const billingAddress = orderDetails.billing_address;
    //const shippingAddress = orderDetails.shipment_address;

    this.orderForm.patchValue(order);
    this.orderItems = order_items;
    /* 
    if (billingAddress != null && billingAddress != undefined) {
      this.billingForm.patchValue(billingAddress);
    }
    if (shippingAddress != null && shippingAddress != undefined) {
      this.shipmentForm.patchValue(shippingAddress);
    }

    this.isCustomerSelected = true; */
    return;
  };

  getSystemSettings = () => {
    this.settingsService.getSystemSettings(true).subscribe(
      (res) => {
        this.orderTaxesList = res
          .filter((x) => x.group_name === SYSTEM_SETTINGS.SALE_TAX)
          ?.map((tx) => {
            return {
              sales_tax_name: tx.name,
              sales_tax_description: tx.description,
              sales_tax_pct: tx.value,
              is_default: tx.is_default,
            };
          });
        const salesTax = this.orderTaxesList.filter((x) => x.is_default);

        this.setOrderTaxes(salesTax);

        this.disclaimerHtml = res.find(
          (x) => x.name === SYSTEM_SETTINGS.RECEIPT_DISCLAIMER
        )?.value;

        this.autoPrintReceipt = res.find(
          (x) => x.name === SYSTEM_SETTINGS.AUTO_PRINT_RECEIPT
        )?.value;

        this.autoPrintReceipt = this.autoPrintReceipt == 1 ? true : false;
      },
      (err) => {
        this.toastr.error(err);
      }
    );
  };

  setOrderTaxes = (salesTax: any) => {
    //if (!this.canUpdateTotals()) return;
    var total_tax = 0;
    //if (!this.taxExempted && this.canEdit) {
    this.orderTaxes = salesTax;

    if (this.orderTaxes?.length > 0) {
      total_tax = salesTax
        .map((x) => parseFloat(x.sales_tax_pct))
        .reduce((prev, next) => prev + next);
    }
    /* } else {
      if (this.taxExempted) this.toastr.info('Tax Exempt Customer');
    } */

    this.orderForm.patchValue({ sale_tax_pct: total_tax });

    this.calculateTotal();
  };

  submitOrder = async () => {
    const formValue = this.orderForm.getRawValue();
    const customerInfo = this.customerInfoForm.getRawValue();
    if (!this.validateOrder(formValue)) return;
    var requestPayload: any = {};
    const order_items = [];

    this.orderItems.forEach((orderItem, index) => {
      order_items.push({
        brand_id: orderItem.brand_id,
        child_sku: orderItem.child_sku,
        parent_sku: orderItem.parent_sku,
        quantity: orderItem.quantity,
        currency_code: orderItem.currency_code,
        item_description: orderItem.item_description,
        edited: false,
        unit_price: orderItem.unit_price,
      });

      if ('order_item_id' in orderItem) {
        order_items[index]['order_item_id'] = orderItem.order_item_id;
      }
    });

    requestPayload = {
      //customer_id: formValue.customer_id,
      order_status_id: formValue.order_status_id,
      order_source_id: formValue.order_source_id,
      store_id: formValue.store_id,
      //payment_method_id: formValue.payment_method_id,
      order_type_id: formValue.order_type_id,
      order_date: formValue.order_date,
      order_note: formValue.order_note,
      sale_tax_pct: formValue.sale_tax_pct,
      sale_tax: formValue.sale_tax,
      sub_total: formValue.sub_total,
      order_total: formValue.order_total,
      order_items: order_items,
      order_taxes: this.orderTaxes,
      customer: customerInfo,
    };
    //remove last empty row added item
    requestPayload.order_items = requestPayload.order_items.filter(
      (x) => x.brand_id != undefined && x.child_sku != undefined
    );

    this.spinner.show();

    this.orderService.createKioskOrder(requestPayload).subscribe(
      async (res) => {
        await this.init_forms_data(res);
        this.spinner.hide();
        this.toastr.success('Your order has been successfully created.');

        this.printReceipt().then((_) => {
          this.resetOrder();
        });
      },
      (err) => {
        this.resetOrder();
        this.spinner.hide();

        this.toastr.error(
          'We were unable to process your order at this time. Please get in touch with the store for assistance or try placing your order again.'
        );
      }
    );
  };

  validateOrder = (formValue): boolean => {
    return true;
  };

  printReceipt(override = false) {
    return new Promise((resolve, reject) => {
      if (!this.autoPrintReceipt && !override) {
        this.toastr.warning('Receipt Printing Disabled');
        resolve({});
        return;
      }
      const modalRef = this.mantleModalService.open(
        MantleOrderReceiptComponent,
        MANTLE_MODAL_NAME.ORDER_RECEIPT,
        {
          ariaLabelledBy: 'modal-basic-title',
          size: 'lg',
          backdrop: 'static',
        }
      );

      modalRef.componentInstance.order_id = this.order_id;
      modalRef.componentInstance.orderDetails = this.orderDetails;
      modalRef.componentInstance.disclaimerHtml = this.disclaimerHtml;
      modalRef.componentInstance.storeDetails = {}; //this.cashRegisterStore;
      modalRef.componentInstance.userDetails = {}; //this.cashRegisterUser;

      this.receiptModalSubscription = this.commonService.modal_close.subscribe(
        (data) => {
          console;
          if (data === 'ORDER-RECEIPT') {
            modalRef.close();
            this.receiptModalSubscription.unsubscribe();
            resolve({});
          }
        }
      );
    });
  }
  //Reset Order Form for new
  resetOrder = async () => {
    this.order_id = null;
    this.orderForm.reset();
    this.customerInfoForm.reset();
    /* this.billingForm.reset();
    this.shipmentForm.reset(); */
    this.orderForm.patchValue(this.initFormValues['orderForm']);
    this.orderForm.patchValue({
      order_date: moment().tz(this.timezone).format('YYYY-MM-DDTHH:mm'),
    });
    /* this.billingForm.patchValue(this.initFormValues['billingForm']);
    this.shipmentForm.patchValue(this.initFormValues['shipmentForm']);
    this.isNewRecord = true;
    this.canEdit = true;
    this.awaitingPayment = false;
    this.isExternalSource = false; */
    this.orderItems = [];
    this.orderItemsInventory = [];
    this.itemRef = 0;
    this.orderTaxes = [];
    this.orderTaxesList = [];
    /* this.taxExempted = false;
    this.verifyPaidAmount = false;
    this.showVerifyPaidAmount = false; */

    this.init_values();

    //this.initPoSData();
  };

  ngOnDestroy(): void {
    this.productSearchSubscription.unsubscribe();
    this.receiptModalSubscription.unsubscribe();
  }
}
