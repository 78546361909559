import { Inject, Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable, Subject } from 'rxjs';
import { ApiService } from './api.service';
import { DOCUMENT } from '@angular/common';
import { StorageService } from './storage.service';
import { LOCAL_STORAGE_KEYS } from '../enums/mantle-enums';
import timezones from '../../../configs/timezones.json';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  LOOKUP_VALUES: any = 'LOOKUP_VALUES';
  timezones: any = timezones;

  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService,
    private storageService: StorageService,
    @Inject(DOCUMENT) private document: any
  ) {}

  modal_close = new Subject();
  searching_customer = new Subject();
  external_customer_search = new Subject();
  external_customer_set = new Subject();
  mantle_data_toggle = new Subject();
  product_form_value = new Subject();
  fullScreenMode = new Subject();
  tabularInputRemoved = new Subject();
  tabularInputOnChange = new Subject();
  terminalsUpdated = new Subject();
  cashRegisterUpdated = new Subject();
  orderTaxComponentUpdated = new Subject();
  systemSettingsUpdated = new Subject();
  catalogDataChanged = new Subject();
  orderRedeemPoints = new Subject();
  inventoryPeriodSelector = new Subject();
  onSaveProduct = new Subject();

  getLookupValues(): Observable<any> {
    return this.apiService.post('/api/v1/get_lookups', {});
  }

  getLookupValue(key, ...replace) {
    var values = this.storage.retrieve(this.LOOKUP_VALUES);
    if (values === null || values === undefined) {
      this.getLookupValues().subscribe((res) => {
        this.storage.store(this.LOOKUP_VALUES, res);
      });
    }
    return values['key'];
  }

  getDirtyValues(form: any) {
    let dirtyValues = {};

    Object.keys(form.controls).forEach((key) => {
      let currentControl = form.controls[key];

      if (currentControl.dirty) {
        if (currentControl.controls)
          dirtyValues[key] = this.getDirtyValues(currentControl);
        else dirtyValues[key] = currentControl.value;
      }
    });

    return dirtyValues;
  }

  getFullScreenElement() {
    return document.getElementById('pos-fullscreen');
  }
  browserFullScreen(element: any) {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      /* Firefox */
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      /* IE/Edge */
      element.msRequestFullscreen();
    }
    this.fullScreenMode.next(true);
  }

  browserExitFullScreen(document: any) {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE/Edge */
      document.msExitFullscreen();
    }
    this.fullScreenMode.next(false);
  }
  isValidJSON = (obj) => {
    var t = typeof obj;
    try {
      if (t == 'object') {
        obj = JSON.stringify(obj);
      }
      JSON.parse(obj);
      return true;
    } catch (e) {
      return false;
    }
  };

  lookupToValueLabel = (lookups) => {
    let lookupResult = {};
    const valueKeys = [
      'id',
      'currency_code',
      'country_code',
      'order_source_id',
      'order_status_id',
      'sales_channel_id',
      'payment_method_id',
      'product_status_id',
    ];
    const labelKeys = [
      'name',
      'description',
      'sales_channel_description',
      'country_name',
    ];

    Object.keys(lookups)?.forEach((key) => {
      let lookup = lookups[key]?.map((val) => {
        let value;
        let label;
        let valKarr = Object.keys(val)?.filter((_value) =>
          valueKeys.includes(_value)
        );
        if (valKarr.length > 0) value = val[valKarr[0]];

        let lblKarr = Object.keys(val)?.filter((_value) =>
          labelKeys.includes(_value)
        );
        if (lblKarr.length > 0) label = val[lblKarr[0]];

        return {
          ...{
            value: value,
            label: label,
          },
          ...val,
        };
      });

      lookupResult[key] = lookup;
    });
    return lookupResult;
  };

  JSONNullEmptyCleanup = (obj, removeEmprtyString = false) => {
    if (obj == null || obj == undefined) return obj;

    const newObj = {};
    Object.entries(obj).forEach(([k, v]) => {
      if (Array.isArray(v)) {
        newObj[k] = obj[k];
      } else if (v === Object(v)) {
        newObj[k] = this.JSONNullEmptyCleanup(v);
      } else if (
        v != null &&
        !(removeEmprtyString && v?.toString()?.trim() == '')
      ) {
        newObj[k] = obj[k];
      }
    });
    return newObj;
  };

  getTimeZone = (getName = false) => {
    var timezone = this.storageService.getItem(
      LOCAL_STORAGE_KEYS.USER_TIMEZONE
    );
    if (timezone == null || timezone == undefined) {
      timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } else if (!getName) {
      timezone = this.timezones.find((x) => x.name === timezone)?.abbrev;
    }
    return timezone;
  };
}
