<div class="row">
  <div class="col-md-12 table-responsive small">
    <table class="table table-striped dataTable">
      <thead>
        <tr>
          <th id="order_return_number">No#</th>
          <th id="order.order_number">Order Number</th>
          <th id="return_amount">Amount</th>
          <th id="return_date">Date</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let order of ordersList">
          <tr>
            <td>{{ order.order_return_number }}</td>
            <td>{{ order.order?.order_number }}</td>
            <td>{{ order.return_amount }}</td>
            <td>{{ order.return_date | date: 'short':timezone }}</td>
            <td>
              <div class="dropdown" data-bs-toggle="dropdown">
                <button
                  class="btn btn-outline btn-sm dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Action
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <button
                    type="button"
                    class="dropdown-item"
                    (click)="viewOrderDetails(order.order_return_id)"
                  >
                    <i class="fas fa-info-circle"></i> View Details
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
