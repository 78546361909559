<section class="content">
  <div class="container-fluid">
    <div class="card">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="card-header">
          <h3 class="card-title small">Mailbox Information</h3>
          <div class="card-tools"></div>
        </div>
        <div class="card-body">
          <div
            class="row small"
            *ngIf="this.form.getRawValue().service_type == 'gmail'"
          >
            <div class="col-md-12">
              <h6>
                Step 1: Set Up a Google Cloud Project:
                <button
                  type="button"
                  class="btn btn-sm btn-outline-info"
                  (click)="toggleGoogleInfo()"
                  title="More Help Info"
                >
                  <i class="fas fa-info-circle"></i>
                </button>
              </h6>

              <ng-container *ngIf="gmailMoreInfo">
                <p>
                  Follow the steps below to set up a Google Cloud project and
                  enable the Gmail API:
                </p>

                <h6>1. Go to the Google Cloud Console</h6>
                <p>
                  Visit the
                  <a href="https://console.cloud.google.com/" target="_blank"
                    >Google Cloud Console</a
                  >.
                </p>

                <h6>2. Create a New Project (or Select an Existing One)</h6>
                <p>
                  If you don't have an existing project, click on "Create
                  Project" and follow the prompts. If you already have a
                  project, simply select it.
                </p>

                <h6>3. Enable the Gmail API</h6>
                <p>To enable the Gmail API for your project:</p>
                <ol>
                  <li>
                    Navigate to <strong>APIs & Services > Library</strong>.
                  </li>
                  <li>
                    Search for <strong>Gmail API</strong> in the search bar.
                  </li>
                  <li>
                    Click the <strong>Enable</strong> button to enable the Gmail
                    API for your project.
                  </li>
                </ol>

                <h6>4. Create OAuth2 Credentials</h6>
                <p>
                  To create OAuth2 credentials for accessing the Gmail API,
                  follow these steps:
                </p>
                <ol>
                  <li>Go to <strong>APIs & Services > Credentials</strong>.</li>
                  <li>
                    Click <strong>Create Credentials</strong> and select
                    <strong>OAuth client ID</strong>.
                  </li>
                  <li>
                    Select the application type (e.g., Desktop app, Web app)
                    that fits your needs.
                  </li>
                  <li>
                    Download the <strong>JSON file</strong> containing your
                    <code>client_id</code>, <code>client_secret</code>, and
                    other details.
                  </li>
                </ol>
              </ng-container>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <formly-form
                [model]="model"
                [fields]="fields"
                [options]="options"
                [form]="form"
              ></formly-form>
            </div>
          </div>
        </div>
        <div class="card-footer text-muted">
          <div class="row">
            <div
              class="col-md-6"
              *ngIf="this.form.getRawValue().service_type == 'gmail'"
            >
              <button
                type="button"
                class="btn btn-secondary btn-sm w-50"
                [disabled]="!form.valid"
                (click)="onGmailAuth()"
              >
                Authorize Gmail
              </button>
            </div>
            <div class="col-md-6">
              <button
                type="submit"
                class="btn btn-success btn-sm w-50"
                [disabled]="!form.valid"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
