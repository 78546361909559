import { Component, OnInit, SimpleChanges } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/utils/services/common.service';

@Component({
  selector: 'mantle-tabular-inputs',
  templateUrl: './mantle-tabular-inputs.component.html',
  styleUrls: ['./mantle-tabular-inputs.component.scss'],
})
export class MantleTabularInputsComponent
  extends FieldArrayType
  implements OnInit {
  autoRemove = false;
  constructor(
    private commonService: CommonService,
    private toastr: ToastrService
  ) {
    super();
  }
  ngOnInit(): void {
    setTimeout(() => {
      //TODO - Add property to props
      this.formControl.valueChanges.subscribe((value) => {
        console.log('Form array changed:', value);
        this.validateChanges(value);
      });
    }, 0);
  }

  validateChanges = (values) => {
    //TODO - Move Validation to calling component
    if (this.field.key == 'vendor_product_ids') {
      const duplicatesWithLastIndex = this.findLastIndexForDuplicates(values);
      if (duplicatesWithLastIndex.length > 0) {
        this.toastr.warning('Item with the same Identifier already added');
        this.autoRemove = true;
        this.remove(duplicatesWithLastIndex[0].lastIndex);
      }
    }
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes['field']) {
      this.onArrayChange(changes['field'].currentValue);
    }
  }

  onChange(value: any, index: number) {
    console.log('Field changed at index:', index, 'Value:', value);
  }
  onArrayChange(value: any) {
    // Handle any logic you need when the array as a whole changes
    console.log('Array field changed:', value);
  }

  remove(index) {
    this.commonService.tabularInputRemoved.next({
      name: this.field.key,
      index: index,
      autoRemove: this.autoRemove,
    });
    this.autoRemove = false;
    super.remove(index);
  }

  // Find last index of duplicates using map and reduce
  findLastIndexForDuplicates = (array: any[]) => {
    const vendorIndices = array
      .map((item, index) => ({ vendor_id: item.vendor_id, index }))
      .reduce((acc, { vendor_id, index }) => {
        if (acc[vendor_id]) {
          acc[vendor_id].count += 1;
          acc[vendor_id].lastIndex = index;
        } else {
          acc[vendor_id] = { count: 1, lastIndex: index };
        }
        return acc;
      }, {} as Record<string, { count: number; lastIndex: number }>);

    return Object.keys(vendorIndices)
      .filter((vendor_id) => vendorIndices[vendor_id].count > 1)
      .map((vendor_id) => ({
        vendor_id,
        lastIndex: vendorIndices[vendor_id].lastIndex,
      }));
  };
}
