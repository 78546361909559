import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ChannelsService {
  constructor(private apiService: ApiService) {}

  channelAdded = new Subject();

  getChannels(): Observable<any> {
    return this.apiService.get('/api/v1/channel');
  }

  getDashboardData(): Observable<any> {
    return this.apiService.post('/api/v1/channel/dashboard', {});
  }

  getProductListing(requestPayload): Observable<any> {
    return this.apiService.get(
      `/api/v1/channel/active-listing?offset=${requestPayload.offset}&limit=${requestPayload.limit}`
    );
  }
  getChannelOptions(): Observable<any> {
    return this.apiService.get('/api/v1/channel/options');
  }

  validateTCG(requestPayload): Observable<any> {
    return this.apiService.post('/api/v1/channel/tcg/validate', requestPayload);
  }

  createTCGChannel(requestPayload): Observable<any> {
    return this.apiService.post('/api/v1/channel/tcg/create', requestPayload);
  }

  updateTCGChannel(requestPayload): Observable<any> {
    return this.apiService.patch('/api/v1/channel/tcg/update', requestPayload);
  }

  updateTcgSkuInventory(requestPayload): Observable<any> {
    return this.apiService.post(
      '/api/v1/channel/tcg/inventory-update',
      requestPayload
    );
  }

  updateSalesChannel(requestPayload): Observable<any> {
    return this.apiService.patch('/api/v1/channel/update', requestPayload);
  }

  //BigCommerce Services
  validateBC(requestPayload): Observable<any> {
    return this.apiService.post('/api/v1/channel/bc/validate', requestPayload);
  }

  createBCChannel(requestPayload): Observable<any> {
    return this.apiService.post('/api/v1/channel/bc/create', requestPayload);
  }

  updateBCChannel(requestPayload): Observable<any> {
    return this.apiService.patch('/api/v1/channel/bc/update', requestPayload);
  }

  updateBCProduct(requestPayload): Observable<any> {
    return this.apiService.post(
      '/api/v1/channel/bc/product-update',
      requestPayload
    );
  }

  getBCProductDetails(requestPayload): Observable<any> {
    return this.apiService.post(
      '/api/v1/channel/bc/product-details',
      requestPayload
    );
  }

  //SHOPIFY
  validateShopify(requestPayload): Observable<any> {
    return this.apiService.post(
      '/api/v1/channel/shopify/validate',
      requestPayload
    );
  }

  createShopifyChannel(requestPayload): Observable<any> {
    return this.apiService.post(
      '/api/v1/channel/shopify/create',
      requestPayload
    );
  }

  updateShopifyChannel(requestPayload): Observable<any> {
    return this.apiService.patch(
      '/api/v1/channel/shopify/update',
      requestPayload
    );
  }

  updateShopifyProduct(requestPayload): Observable<any> {
    return this.apiService.post(
      '/api/v1/channel/shopify/product-update',
      requestPayload
    );
  }

  ///Amazon SP
  validateAmazonSp(requestPayload): Observable<any> {
    return this.apiService.post(
      '/api/v1/channel/amazon-sp/validate',
      requestPayload
    );
  }

  createAmazonSpChannel(requestPayload): Observable<any> {
    return this.apiService.post(
      '/api/v1/channel/amazon-sp/create',
      requestPayload
    );
  }

  updateAmazonSpChannel(requestPayload): Observable<any> {
    return this.apiService.patch(
      '/api/v1/channel/amazon-sp/update',
      requestPayload
    );
  }

  validateAmazonAsin(requestPayload): Observable<any> {
    return this.apiService.post(
      '/api/v1/channel/amazon-sp/search-asin',
      requestPayload
    );
  }

  updateAmazonSpProduct(requestPayload): Observable<any> {
    return this.apiService.post(
      '/api/v1/channel/amazon-sp/product-update',
      requestPayload
    );
  }

  //CardTrader

  validateCardTrader(requestPayload): Observable<any> {
    return this.apiService.post(
      '/api/v1/channel/card-trader/validate',
      requestPayload
    );
  }

  createCardTraderChannel(requestPayload): Observable<any> {
    return this.apiService.post('/api/v1/channel/card-trader', requestPayload);
  }

  updateCardTraderChannel(requestPayload): Observable<any> {
    return this.apiService.patch('/api/v1/channel/card-trader', requestPayload);
  }

  updateCardTraderProduct(requestPayload): Observable<any> {
    return this.apiService.post(
      '/api/v1/channel/card-trader/product-update',
      requestPayload
    );
  }

  //WooCommerce
  validateWooCommerce(requestPayload): Observable<any> {
    return this.apiService.post(
      '/api/v1/channel/woo-commerce/validate',
      requestPayload
    );
  }

  createWooCommerceChannel(requestPayload): Observable<any> {
    return this.apiService.post('/api/v1/channel/woo-commerce', requestPayload);
  }

  updateWooCommerceChannel(requestPayload): Observable<any> {
    return this.apiService.patch(
      '/api/v1/channel/woo-commerce',
      requestPayload
    );
  }

  updateWooCommerceProduct(requestPayload): Observable<any> {
    return this.apiService.post(
      '/api/v1/channel/woo-commerce/product-update',
      requestPayload
    );
  }

  //POS
  validatePOS(requestPayload): Observable<any> {
    return this.apiService.post('/api/v1/channel/pos/validate', requestPayload);
  }

  createPosChannel(requestPayload): Observable<any> {
    return this.apiService.post('/api/v1/channel/pos', requestPayload);
  }

  updatePosChannel(requestPayload): Observable<any> {
    return this.apiService.patch('/api/v1/channel/pos', requestPayload);
  }

  updatePosProduct(requestPayload): Observable<any> {
    return this.apiService.post(
      '/api/v1/channel/pos/product-update',
      requestPayload
    );
  }
  posChannelLinkRegister(requestPayload): Observable<any> {
    return this.apiService.post(
      '/api/v1/channel/pos/cash-register',
      requestPayload
    );
  }
  posChannelUpdateRegister(requestPayload): Observable<any> {
    return this.apiService.patch(
      '/api/v1/channel/pos/cash-register',
      requestPayload
    );
  }
  posChannelDeleteRegister(id): Observable<any> {
    return this.apiService.delete(`/api/v1/channel/pos/cash-register/${id}`);
  }

  getUserPosChannels(requestPayload): Observable<any> {
    return this.apiService.post(
      '/api/v1/channel/pos/cash-register/user',
      requestPayload
    );
  }

  allocatePosToInventories(requestPayload): Observable<any> {
    return this.apiService.post(
      '/api/v1/channel/pos/inventory-allocate',
      requestPayload
    );
  }

  //General
  getSalesChannelOrderStatus(sales_channel_id): Observable<any> {
    return this.apiService.get(
      '/api/v1/channel/channel-order-status?salesChannelId=' + sales_channel_id
    );
  }

  updateSalesChannelOrderStatus(requestPayload): Observable<any> {
    return this.apiService.post(
      '/api/v1/channel/channel-order-status',
      requestPayload
    );
  }

  checkPendingOrders(requestPayload): Observable<any> {
    return this.apiService.post(
      '/api/v1/channel/channel-check-orders',
      requestPayload
    );
  }
}
