import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

@Component({
  selector: 'mantle-side-drawer',
  templateUrl: './mantle-side-drawer.component.html',
  styleUrl: './mantle-side-drawer.component.scss',
})
export class MantleSideDrawerComponent implements OnInit {
  @Input() isOpen: boolean = false;
  @Input() componentType?: Type<any>; // Accepts a component class type
  @Output() isOpenChange = new EventEmitter<boolean>();
  @Input() componentData?: any; // Add an input for passing data
  @ViewChild('drawerContent', { read: ViewContainerRef, static: true })
  drawerContent!: ViewContainerRef;

  constructor() {}

  ngOnInit() {
    if (this.componentType) {
      this.loadComponent(this.componentType, this.componentData);
    }
  }

  loadComponent(component: Type<any>, data?: any) {
    //const componentFactory = this.resolver.resolveComponentFactory(component);
    //this.drawerContent.clear();
    //this.drawerContent.createComponent(componentFactory);
    this.drawerContent.clear();
    const componentRef = this.drawerContent.createComponent(component);
    if (data) {
      Object.assign(componentRef.instance, data);
    }
  }

  close() {
    this.isOpen = false;
    this.isOpenChange.emit(this.isOpen);
  }

  open() {
    this.isOpen = true;
    this.isOpenChange.emit(this.isOpen); // Emit change when opening the drawer
  }
}
