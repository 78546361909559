import { Component, Input } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import {
  MANTLE_MODAL_NAME,
  REPORTING_DATA_TYPES,
  REPORTING_DEFAULT_OPTIONS,
  REPORTING_DEFAULT_PROPERTY,
  REPORTING_LOOKUP,
} from 'src/app/utils/enums/mantle-enums';
import { CommonService } from 'src/app/utils/services/common.service';
import { ReportingService } from 'src/app/utils/services/reporting.service';

@Component({
  selector: 'mantle-report-run',
  templateUrl: './mantle-report-run.component.html',
  styleUrls: ['./mantle-report-run.component.scss'],
})
export class MantleReportRunComponent {
  @Input() report_id: any;
  private jsonData: any;
  private webDataRocksConfig?: any;
  reportName: any;
  private webOptions: any;
  private reportDetailsSubscription!: Subscription;
  private reportDataSubscription!: Subscription;
  private lookupSubscription!: Subscription;
  hasFilters: boolean = false;
  reportForm = this.fb.group({});

  fields: FormlyFieldConfig[];
  model: any = {};
  options: FormlyFormOptions = {
    formState: {
      report_type: REPORTING_LOOKUP.REPORT_TYPE,
      dataType: REPORTING_LOOKUP.DATA_TYPES,
      lookupKeys: [],
      lookupValues: {},
    },
  };

  constructor(
    private fb: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private reportingService: ReportingService,
    private toastr: ToastrService,
    private commonService: CommonService
  ) {}

  async ngOnInit() {
    await this.initData();
  }

  initData() {
    this.spinner.show();

    this.lookupSubscription = this.commonService.getLookupValues().subscribe(
      (res) => {
        delete res.init_load;
        this.options.formState.lookupValues = this.commonService.lookupToValueLabel(
          res
        );
      },
      (err) => {}
    );

    this.reportDetailsSubscription = this.reportingService
      .getReportById(this.report_id)
      .subscribe(
        (res) => {
          this.reportName = res.name;
          this.webDataRocksConfig =
            Object.keys(res.properties).length === 0
              ? REPORTING_DEFAULT_PROPERTY
              : res.properties;
          this.webDataRocksConfig.options =
            Object.keys(res.properties).length === 0
              ? REPORTING_DEFAULT_OPTIONS
              : res.options;

          this.buildFilters(res);
          if (!this.hasFilters) {
            this.getReportData();
          } else this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          this.toastr.error(error);
        }
      );
  }

  getReportData(filterPrams = null) {
    this.spinner.show();
    this.reportDataSubscription = this.reportingService
      .getReportData(this.report_id, filterPrams)
      .subscribe(
        (resData) => {
          this.spinner.hide();
          this.jsonData = resData;
          this.webDataRocksConfig.dataSource.data = this.jsonData;
          this.setReport();
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }

  buildFilters(reportConfig) {
    let fieldConfig = [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [],
      },
    ];

    if (reportConfig.report_filters.length > 0) {
      this.hasFilters = true;

      reportConfig.report_filters.forEach((filter) => {
        let field = {
          className: 'col-4',
          key: filter.name,
          type: 'input',
          defaultValue: filter.default_value,
          props: {
            label: filter.description,
            placeholder: '',
            description: filter.description,
            required: filter.is_required == 1 ? true : false,
          },
          expressionProperties: {
            'props.options': `formState.lookupValues['${filter.lookup_key}']`,
          },
        };

        if (filter.data_type == REPORTING_DATA_TYPES.STRING) {
          delete field.expressionProperties;
        } else if (
          filter.data_type == REPORTING_DATA_TYPES.STRING_ARRAY ||
          filter.data_type == REPORTING_DATA_TYPES.NUMBER_ARRAY
        ) {
          field.type = 'mantle-tag-input';
          delete field.expressionProperties;
          if (this.commonService.isValidJSON(filter.default_value)) {
            field.defaultValue = JSON.parse(filter.default_value);
          } else field.defaultValue = [];
        } else if (filter.data_type == REPORTING_DATA_TYPES.NUMBER) {
          field.props['type'] = 'number';
          delete field.expressionProperties;
        } else if (filter.data_type == REPORTING_DATA_TYPES.DATE) {
          field.props['type'] = 'date';
          delete field.expressionProperties;
        } else if (filter.data_type == REPORTING_DATA_TYPES.DATE_TIME) {
          field.props['type'] = 'datetime-local-formly';
          delete field.expressionProperties;
        } else if (filter.data_type == REPORTING_DATA_TYPES.SELECT) {
          field.type = 'mantle-select';
        } else if (filter.data_type == REPORTING_DATA_TYPES.MULTI_SELECT) {
          field.type = 'mantle-select';
          (field.defaultValue = JSON.parse(filter.default_value)),
            (field.props['multiple'] = true);
          field.props['enableSelectAll'] = true;
        }

        fieldConfig[0].fieldGroup.push(field);
      });
    }

    this.fields = fieldConfig;
  }

  runReport() {
    this.hasFilters = false;
    let filters = this.reportForm.getRawValue();
    this.getReportData(filters);
  }

  setReport(): void {
    setTimeout(() => {
      const pivotTable = new window.WebDataRocks({
        container: `#MantleWebDataRocksContainer`,
        width: '100%',
        height: '100%',
        toolbar: true,
        beforetoolbarcreated: this.customizeToolbar,
        report: this.webDataRocksConfig,
      });
    }, 1000);
  }

  customizeToolbar(toolbar) {
    const filename = `${Date.now()}`;
    var tabs = toolbar.getTabs();
    toolbar.getTabs = function () {
      tabs.forEach((tab, index) => {
        if (tab.id !== 'wdr-tab-export' && tab.id !== 'wdr-tab-fullscreen')
          delete tabs[index];

        if (tab.id === 'wdr-tab-export') {
          let exportToPdfTab = tab.menu.find(
            (tab) => tab.id == 'wdr-tab-export-pdf'
          );
          exportToPdfTab.handler = () => {
            this.pivot.exportTo('pdf', {
              filename: `${filename}.pdf`,
            });
          };
          let exportToExcelTab = tab.menu.find(
            (tab) => tab.id == 'wdr-tab-export-excel'
          );
          exportToExcelTab.handler = () => {
            this.pivot.exportTo('excel', {
              filename: `${filename}.xlsx`,
            });
          };
          let exportTohtmlTab = tab.menu.find(
            (tab) => tab.id == 'wdr-tab-export-html'
          );
          exportTohtmlTab.handler = () => {
            this.pivot.exportTo('html', {
              filename: `${filename}.html`,
            });
          };
        }
      });

      /* tabs.unshift({
        id: 'wdr-tab-runreport',
        title: 'Run',
        handler: this.runReport,
        icon: this.icons.format,
      }); */
      return tabs;
    };
  }

  ngOnDestroy() {
    this.reportDetailsSubscription?.unsubscribe();
    this.reportDataSubscription?.unsubscribe();
    this.lookupSubscription?.unsubscribe();
  }

  closeModal() {
    this.commonService.modal_close.next(MANTLE_MODAL_NAME.RUN_REPORT_MODAL);
  }
}
