import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/utils/services/common.service';
import { InventoryService } from 'src/app/utils/services/inventory.service';
import formConfig from '../../../../../configs/forms/form_transfer_details.json';
import { SettingsService } from 'src/app/utils/services/settings.service';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
import { OrderService } from 'src/app/utils/services/order.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mantle-inventory-transfer-details',
  templateUrl: './mantle-inventory-transfer-details.component.html',
  styleUrl: './mantle-inventory-transfer-details.component.scss',
})
export class MantleInventoryTransferDetailsComponent
  implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  @Input() transfer_id: any;
  transferForm = this.fb.group({
    transfer_id: [],
    status: ['draft'],
    transfer_number: [],
    inventory_transfer_items: this.fb.array([]),
  });
  get inventoryTransferItems(): UntypedFormArray {
    return this.transferForm.get(
      'inventory_transfer_items'
    ) as UntypedFormArray;
  }

  itemRef: number;

  options: FormlyFormOptions = {
    formState: {
      operation_type: [
        { value: 'transfer', label: 'Transfer' },
        { value: 'adjustment', label: 'Adjustment' },
      ],
      record_status: [
        { value: 'draft', label: 'Draft' },
        { value: 'finalized', label: 'Finalized' },
      ],
      store_location: [],
    },
  };
  fields: FormlyFieldConfig[];
  model: any = {};

  constructor(
    private inventoryService: InventoryService,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private settingsService: SettingsService,
    private orderService: OrderService
  ) {}

  ngOnInit(): void {
    this.fields = formConfig;
    this.spinner.show();
    this.subscription.add(
      this.settingsService.getStores().subscribe({
        next: (res) => {
          this.options.formState.store_location = res.map((x) => ({
            ...{ value: x.store_id.toString(), label: x.store_name },
            ...x,
          }));
        },
        error: (err) => {
          this.spinner.hide();
        },
        complete: () => {
          this.spinner.hide();
        },
      })
    );

    this.subscription.add(
      this.orderService.product_search_result.subscribe({
        next: (res) => this.addTransferItem(res),
        error: (err) => {},
        complete: () => {},
      })
    );

    this.getTransferDetails();
  }

  getTransferDetails = () => {
    if (this.transfer_id) {
      this.spinner.show();
      this.inventoryService.getTransferById(this.transfer_id).subscribe({
        next: (res) => {
          this.patchForm(res);
        },
        error: (err) => {
          this.spinner.hide();
        },
        complete: () => {
          this.spinner.hide();
        },
      });
    }
  };

  addTransferItem(itemData) {
    itemData = itemData.data;
    this.spinner.show();
    this.subscription.add(
      this.inventoryService.getInventoryData(itemData).subscribe({
        next: (res) => {
          const source_location_id = this.transferForm.getRawValue()
            ?.source_location_id;
          const inventoryLocation = res.inventory_locations?.find(
            (x) => x.store_id == source_location_id
          );
          if (!inventoryLocation) {
            this.spinner.hide();
            this.toastr.warning(
              'Inventory not maintained for the source location'
            );
            return;
          }
          this.inventoryTransferItems.push(
            this.createTransferItem({
              inventory_id: res.inventory_id,
              brand_id: res.brand_id,
              child_sku: res.child_sku,
              quantity: inventoryLocation.quantity,
              product_description: itemData.product_name,
            })
          );
        },
        error: (err) => {
          this.spinner.hide();
        },
        complete: () => {
          this.spinner.hide();
        },
      })
    );
  }
  removeTransferItem(index: any) {
    const transferItemsId = this.inventoryTransferItems.at(index)?.value
      ?.transfer_item_id;
    if (!transferItemsId) {
      this.inventoryTransferItems.removeAt(index);
      return;
    }
    this.spinner.show();
    this.inventoryService
      .deleteTransferItem(
        this.transferForm.get('transfer_id').value,
        transferItemsId
      )
      .subscribe({
        next: (res) => {
          this.inventoryTransferItems.removeAt(index);
        },
        error: (err) => {
          this.spinner.hide();
        },
        complete: () => {
          this.spinner.hide();
        },
      });
  }
  submit() {
    this.spinner.show();
    const requestPayload = this.transferForm.getRawValue();
    this.inventoryService.createTransfer(requestPayload).subscribe({
      next: (res) => {
        this.patchForm(res);
      },
      error: (err) => {
        this.spinner.hide();
      },
      complete: () => {
        this.spinner.hide();
      },
    });
  }

  finalize() {
    this.spinner.show();
    this.inventoryService
      .finalizeTransfer(this.transferForm.getRawValue().transfer_id)
      .subscribe({
        next: (res) => {
          this.patchForm(res);
        },
        error: (err) => {
          this.spinner.hide();
        },
        complete: () => {
          this.spinner.hide();
        },
      });
  }
  patchForm(data) {
    this.transferForm.patchValue(data);
    data?.inventory_transfer_items?.forEach((item, index) => {
      if (this.inventoryTransferItems.at(index)) {
        this.inventoryTransferItems.at(index).patchValue(item);
      } else {
        this.inventoryTransferItems.push(this.createTransferItem(item));
      }
    });
  }

  closeModal() {
    this.commonService.modal_close.next(
      MANTLE_MODAL_NAME.INVENTORY_TRANSFER_DETAILS
    );
  }

  // Initialize a Form Group for inventory_transfer_items
  createTransferItem(itemData) {
    return this.fb.group({
      transfer_item_id: [itemData.transfer_item_id],
      transfer_id: [itemData.transfer_id],
      inventory_id: [itemData.inventory_id, Validators.required],
      brand_id: [itemData.brand_id],
      child_sku: [itemData.child_sku, Validators.required],
      quantity: [itemData.quantity],
      transferred_quantity: [
        itemData.transferred_quantity ?? 0,
        itemData.transferred_quantity >= 0
          ? [Validators.max(itemData.quantity)]
          : [],
      ],
      adjusted_quantity: [itemData.adjusted_quantity ?? 0],
      product_description: [itemData.product_description],
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
