import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mantle-inventory',
  templateUrl: './mantle-inventory.component.html',
  styleUrls: ['./mantle-inventory.component.scss'],
})
export class MantleInventoryComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
