import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mantle-product-drawer',
  templateUrl: './mantle-product-drawer.component.html',
  styleUrl: './mantle-product-drawer.component.scss',
})
export class MantleProductDrawerComponent implements OnInit {
  @Input() data: any;
  cardImage: String = '';

  ngOnInit(): void {
    if (this.data['Image'] != null && this.data['Image'] != undefined) {
      this.cardImage = this.data['Image'];
    }
  }
}
