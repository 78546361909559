<form [formGroup]="tcgInventoryForm">
  <div class="card">
    <div class="card-header">
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="closeModal()">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <h5>Update SKU inventory</h5>
    </div>
    <div class="card-body">
      <div class="row small text-muted m-2">
        <div class="col-3">
          <strong>Product ID:</strong> {{ product.productid }}
        </div>
        <div class="col-3">
          <strong>Product Category:</strong> {{ product.category }}
        </div>
        <div class="col-3">
          <strong>Product Name:</strong> {{ product.name }}
        </div>
        <div class="col-3">
          <strong>Product Rarity:</strong> {{ product.rarity }}
        </div>
      </div>
      <div class="row small text-muted m-2">
        <div class="col-3">
          <strong>Store ID:</strong>
          {{ product.sales_channel.channel_tcg.store_key }}
        </div>
        <div class="col-3">
          <strong>Channel:</strong>
          {{ product.sales_channel.channel_tcg.channel_tcg_description }}
        </div>
        <div class="col-3">
          <strong>Product Condition:</strong> {{ product.condition_name }}
        </div>
        <div class="col-3">
          <strong>Product Date Updated:</strong> {{ product.date_updated }}
        </div>
      </div>
      <div class="row small text-muted m-2">
        <div class="col-3"><strong>SKU ID:</strong> {{ product.skuid }}</div>
        <div class="col-3">
          <strong>Current Quantity:</strong> {{ product.quantity }}
        </div>
        <div class="col-3">
          <strong>Current Price:</strong> {{ product.price }}
        </div>
      </div>
      <div class="row m-2">
        <div class="form-group col-6">
          <div class="row">
            <div class="col-9">
              <label class="control-label small">Quantity</label>
              <input
                [readonly]="!quantityEdited"
                type="number"
                class="form-control form-control-sm"
                formControlName="quantity"
                min="0"
              />
            </div>
            <div class="col-3">
              <button
                *ngIf="!quantityEdited"
                type="button"
                class="btn btn-outline-sm mt-4"
                (click)="onAllowQuantityEdit()"
              >
                <i class="fas fa-edit"></i>
              </button>
              <button
                *ngIf="quantityEdited"
                type="button"
                class="btn btn-outline-sm mt-4"
                (click)="onAllowQuantityEdit()"
              >
                <i class="fas fa-undo"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="form-group col-6">
          <label class="control-label small">Price</label>
          <input
            type="number"
            class="form-control form-control-sm"
            formControlName="price"
            min="0"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-6">
          <button
            type="button"
            [disabled]="!tcgInventoryForm.valid"
            class="btn btn-sm btn-info w-50"
            (click)="onUpdateInventory()"
          >
            <i class="fas fa-save"></i> Update
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
