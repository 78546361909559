export enum PRODUCT_CATALOG {
  MTG = 1, //Magic
  WS = 2, //Weiss Schwarz
  YGH = 3, //YuGiOh
  PKMN = 5, //Pokemon
  BGG = 6, //Board Games
}

export enum ORDER_SOURCE_ID {
  AMAZON = 1,
  DIRECT = 2,
  EBAY = 3,
  MARKET_PLACE = 4,
  MOBILE = 5,
  POS = 6,
  TCG_PLAYER = 7,
  BIG_COMMERCE = 8,
  SHOPIFY = 9,
  CARD_TRADER = 10,
  KIOSK = 11,
  WOO_COMMERCE = 12,
  OTHER = 99,
}

export enum PAYMENT_METHOD {
  CASH = 1,
  CREDIT_DEBIT_CARD = 2,
  MASTER_CARD = 3,
  AMERICA_EXPRESS = 4,
  PAYPAL = 5,
  SOFORT = 6,
  ALIPAY_WALLET = 7,
  APPLE_PAY = 8,
  UNION_PAY = 9,
  GOOGLE_PAY = 10,
  Hipercard = 11,
  Elo = 12,
  AMAZON_PAY = 13,
  Boleto = 14,
  SEPA = 15,
  JCB = 16,
  DINERS_CLUB = 17,
  Discover = 18,
  iDEAL = 19,
  OTHER = 20,
  VISA = 21,
  GIFT_CARD = 22,
  CHECK = 23,
  SPLIT_PAYMENT = 21,
}

export enum CASH_REGISTER_TRANSACTION_TYPES {
  START_REGISTER = 1,
  END_REGISTER = 2,
  CASH_ORDER = 3,
  OPEN_TRAY = 4,
  REVERSE = 5,
}

export enum CASH_REGISSTER_STATUS {
  RUNNING = 1,
  CLOSED = 2,
  DECOMMISSIONED = 99,
}

export enum CHANNEL_REF_ENUM {
  TCG = 'CHANNEL_TCG',
  BC = 'CHANNEL_BC',
  AMAZON_SP = 'CHANNEL_AMAZON_SP',
  SHOPIFY = 'CHANNEL_SHOPIFY',
  CARD_TRADER = 'CHANNEL_CARDTRADER',
  WOO_COMMERCE = 'CHANNEL_WC',
  POS = 'CHANNEL_POS',
}
export enum MANTLE_MODAL_NAME {
  CUSTOMER_MODAL = 'CUSTOMER_MODAL',
  RUN_REPORT_MODAL = 'RUN_REPORT_MODAL',
  EDIT_DEFAULT_REPORT_MODAL = 'EDIT_DEFAULT_REPORT_MODAL',
  CONFIRM_DIALOG = 'CONFIRM-DIALOG',
  CARDTRADER_ACTIVATION_MODAL = 'CARDTRADER_ACTIVATION_MODAL',
  CHANNEL_OPTIONS_MODAL = 'CHANNEL-OPTIONS-MODAL',
  AMAZON_SP_ACTIVATION_MODAL = 'AMAZON-SP-ACTIVATION-MODAL',
  SHOPIFY_ACTIVATION_MODAL = 'SHOPIFY-ACTIVATION-MODAL',
  BC_ACTIVATION_MODAL = 'BC-ACTIVATION-MODAL',
  TCG_ACTIVATION_MODAL = 'TCG-ACTIVATION-MODAL',
  MERCHANT_LIST_MODAL = 'MERCHANT_LIST_MODAL',
  MERCHANT_SETTINGS_MODAL = 'MERCHANT_SETTINGS_MODAL',
  MX_MERCHANT_MODAL = 'MX_MERCHANT_MODAL',
  CASH_REGISTER_LIST_MODAL = 'CASH_REGISTER_LIST_MODAL',
  MODAL_CASHREGISTER_DETAILS = 'MODAL_CASHREGISTER_DETAILS',
  MODAL_CASHREGISTER_TRANSACTION = 'MODAL_CASHREGISTER_TRANSACTION',
  TAX_COMPONENT_MODAL = 'TAX_COMPONENT_MODAL',
  MODAL_SETTING_EDIT = 'MODAL_SETTING_EDIT',
  PRODUCT_INVENTORY_MODAL = 'PRODUCT-INVENTORY-MODAL',
  PRODUCT_EDIT_MODAL = 'PRODUCT_EDIT_MODAL',
  PRODUCT_INVENTORY_ONLY_MODAL = 'PRODUCT_INVENTORY_ONLY_MODAL',
  BARCODE_GENERATOR_MODAL = 'BARCODE_GENERATOR_MODAL',
  CASH_DENOMINATION_MODAL = 'CASH_DENOMINATION_MODAL',
  MODAL_CASHREGISTER_HIST_LIST = 'MODAL_CASHREGISTER_HIST_LIST',
  PRODUCT_UPDATE_MODAL = 'PRODUCT_UPDATE_MODAL',
  SETTINGS_PRODUCT_FILTER = 'SETTINGS_PRODUCT_FILTER',
  PO_DETAILS = 'PO_DETAILS',
  PO_VENDOR_DETAILS = 'PO_VENDOR_DETAILS',
  MULTIPLE_UPC_MODAL = 'MULTIPLE-UPC-MODAL',
  PO_INVOICE = 'PO_INVOICE',
  ORDER_RETURN_DETAILS = 'ORDER_RETURN_DETAILS',
  MANAGE_QUICK_LINKS = 'MANAGE_QUICK_LINKS',
  SETTINGS_PRODUCT_DESCRIPTION = 'SETTINGS_PRODUCT_DESCRIPTION',
  WOO_COMMERCE_ACTIVATION_MODAL = 'WOO_COMMERCE_ACTIVATION_MODAL',
  TIERED_REWARD_POINT_DETAILS = 'TIERED_REWARD_POINT_DETAILS',
  PRODUCT_REWARD_POINT_DETAILS = 'PRODUCT_REWARD_POINT_DETAILS',
  ORDER_REDEEM_POINT = 'ORDER_REDEEM_POINT',
  INVENTORY_ADVANCED_MODAL = 'INVENTORY_ADVANCED_MODAL',
  ORDER_RECEIPT = 'ORDER-RECEIPT',
  ORDER_ADD_NEW = 'ORDER-ADD-NEW',
  CHANNEL_POS_ACTIVATION_MODAL = 'CHANNEL_POS_ACTIVATION_MODAL',
  INVENTORY_TRANSFER_DETAILS = 'INVENTORY_TRANSFER_DETAILS',
  EMAIL_TEMPLATE_DETAILS = 'EMAIL_TEMPLATE_DETAILS',
}

export enum REPORTING_DATA_TYPES {
  STRING = 'STRING',
  STRING_ARRAY = 'STRING_ARRAY',
  NUMBER = 'NUMBER',
  NUMBER_ARRAY = 'NUMBER_ARRAY',
  DATE = 'DATE',
  DATE_TIME = 'DATE_TIME',
  SELECT = 'SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  CUSTOM = 'CUSTOM',
}

export enum PAYMENT_MERCHANT_TYPES {
  PAYMENT_MX_MERCHANT = 1,
}

export enum SYSTEM_SETTINGS {
  SALE_TAX = 'SALE_TAX',
  RECEIPT_DISCLAIMER = 'RECEIPT-DISCLAIMER',
  AUTO_PRINT_RECEIPT = 'AUTO_PRINT_RECEIPT',
  IMAGE_LOGO = 'IMAGE_LOGO',
}
export enum LOCAL_STORAGE_KEYS {
  SYSTEM_SETTINGS = 'SYSTEM_SETTINGS',
  USER_TIMEZONE = 'USER_TIMEZONE',
  USER_PROFILE = 'USER_PROFILE',
}

export enum PRODUCT_FILTER_LEVEL {
  INVENTORY = 1,
  CATALOG = 2,
}
export enum PURCHASE_ORDER_STATUS {
  DRAFT = 1,
  SUBMITTED = 2,
  ACCEPTED = 3,
  COMPLETED = 4,
  CANCELED = 5,
  /*RECEIVED = 5,
  PARTIALLY_RECEIVED = 6,
  BILLED = 7,
  CANCELED = 8,*/
}

export enum USER_PREFERENCES {
  QUICK_LINKS = 'QUICK_LINKS',
  TIMEZONE = 'TIMEZONE',
}

export enum STATE_STATUS {
  PENDING = 'pending',
  LOADING = 'loading',
  ERROR = 'error',
  SUCCESS = 'success',
}
/**
 * Consts
 */
export const REPORTING_DEFAULT_OPTIONS = {
  grid: {
    type: 'flat',
    title: '',
    showFilter: true,
    showHeaders: false,
    showTotals: 'off',
    showGrandTotals: 'off',
    grandTotalsPosition: 'bottom',
    showExtraTotalLabels: false,
    showHierarchies: true,
    showHierarchyCaptions: true,
    drillthroughMaxRows: 1000,
    showReportFiltersArea: true,
    showAutoCalculationBar: true,
  },
  configuratorActive: false,
  configuratorButton: true,
  showAggregations: true,
  showCalculatedValuesButton: true,
  drillThrough: true,
  showDrillThroughConfigurator: true,
  sorting: 'on',
  datePattern: 'dd/MM/yyyy',
  dateTimePattern: 'dd/MM/yyyy HH:mm:ss',
  saveAllFormats: false,
  showDefaultSlice: true,
  showEmptyData: true,
  defaultHierarchySortName: 'asc',
  selectEmptyCells: true,
  showAggregationLabels: true,
};

export const REPORTING_DEFAULT_PROPERTY = {
  dataSource: {
    dataSourceType: 'json',
    data: [],
  },
  slice: {
    rows: [],
    columns: [],
    measures: [],
    expands: {
      rows: [],
      columns: [],
    },
    flatOrder: [],
  },
  options: {
    grid: { type: 'flat', showTotals: 'off', showGrandTotals: 'off' },
  },
  formats: [
    {
      name: '',
      thousandsSeparator: ',',
      decimalSeparator: '.',
      decimalPlaces: 2,
      maxSymbols: 20,
      currencySymbol: '',
      currencySymbolAlign: 'left',
      nullValue: ' ',
      infinityValue: 'Infinity',
      divideByZeroValue: 'Infinity',
    },
  ],
};
/**
 * type:1 - denotes dashboard reports
 */
export const REPORTING_LOOKUP = {
  REPORT_TYPE: [
    { value: '-1', label: 'System' },
    { value: '0', label: 'Default' },
    { value: '1', label: 'Dashboard Widget - Card', type: 1, sortOrder: 1 },
    {
      value: '2',
      label: 'Dashboard Widget - Line Graph',
      type: 1,
      sortOrder: 2,
    },
    { value: '3', label: 'Dashboard Widget - Pie', type: 1, sortOrder: 3 },
    ,
    { value: '4', label: 'Dashboard Widget - Table', type: 1, sortOrder: 4 },
    { value: '5', label: 'Mailing Data Report', type: 1, sortOrder: 4 },
  ],
  DATA_TYPES: [
    { value: REPORTING_DATA_TYPES.STRING, label: 'String' },
    { value: REPORTING_DATA_TYPES.STRING_ARRAY, label: 'String Array' },
    { value: REPORTING_DATA_TYPES.NUMBER, label: 'Numeric' },
    { value: REPORTING_DATA_TYPES.NUMBER_ARRAY, label: 'Numeric Array' },
    { value: REPORTING_DATA_TYPES.DATE, label: 'Date' },
    { value: REPORTING_DATA_TYPES.DATE_TIME, label: 'Date Time' },
    { value: REPORTING_DATA_TYPES.SELECT, label: 'Select' },
    { value: REPORTING_DATA_TYPES.MULTI_SELECT, label: 'Multi Select' },
    {
      value: REPORTING_DATA_TYPES.CUSTOM,
      label: 'Custom function (Not implemented)',
    },
  ],
};
