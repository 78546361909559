import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MailingService } from 'src/app/utils/services/mailing.service';

@Component({
  selector: 'gmail-callback',
  templateUrl: './gmail-callback.component.html',
  styleUrl: './gmail-callback.component.scss',
})
export class GmailCallbackComponent implements OnInit {
  constructor(
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private mailingService: MailingService
  ) {}
  ngOnInit(): void {
    this.spinner.show();
    this.route.queryParams.subscribe((params) => {
      const code = params['code'];
      if (code) {
        this.mailingService.exchangeCodeForToken(code).subscribe({
          next: (res) => {
            this.spinner.hide();
          },
          error: (err) => {
            console.error('nnnn');
            this.spinner.hide();
            alert('kk');
            this.router.navigate(['settings/email-settings']);
          },
          complete: () => {
            this.spinner.hide();
            this.router.navigate(['settings/email-settings']);
          },
        });
      } else {
        this.toastr.error('Authorization code not found');
        this.spinner.hide();
      }
    });
  }
}
