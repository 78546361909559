<div *ngIf="!to.hidden">
  <div class="col-md-12 table-responsive small">
    <table class="table table-striped table-sm hover dataTable">
      <tbody>
        <tr *ngFor="let field of field.fieldGroup; let i = index">
          <td>
            <formly-field
              [field]="field"
              (ngModelChange)="onChange($event, i)"
            ></formly-field>
          </td>
          <td
            class="form-group"
            *ngIf="to.canRemove || to.canRemove == undefined"
          >
            <label class="control-label">&nbsp;</label>
            <button
              type="button"
              class="btn btn-outline-danger btn-sm rounded-circle"
              style="width: 30px; height: 30px"
              (click)="remove(i)"
            >
              <i class="fas fa-trash" aria-hidden="true"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div style="margin: 30px 0" *ngIf="to.canAdd || to.canAdd == undefined">
    <button
      type="button"
      class="btn btn-outline-primary btn-sm mr-2 rounded-circle"
      style="width: 30px; height: 30px"
      (click)="add()"
    >
      <i class="fa fa-plus" aria-hidden="true"></i>
    </button>
  </div>
</div>
