import { Component, Input, NgModuleRef, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/utils/services/api.service';
import { CommonService } from 'src/app/utils/services/common.service';
import { InventoryService } from 'src/app/utils/services/inventory.service';
import { OrderService } from 'src/app/utils/services/order.service';

@Component({
  selector: 'order-product-search',
  templateUrl: './order-product-search.component.html',
  styleUrls: ['./order-product-search.component.scss'],
})
export class OrderProductSearchComponent implements OnInit {
  @Input() itemRef;
  @Input() notDialog: boolean = false;
  @Input() have_invetory: boolean = true;
  public product_list$: Observable<any>;
  public input$ = new Subject<any | null>();
  product_brand = [];
  loadingProduct = false;
  selectedProduct: any;
  productLineSelected: boolean = false;
  //@Input() modalRef:NgModuleRef

  constructor(
    private fb: UntypedFormBuilder,
    private commonService: CommonService,
    private orderService: OrderService,
    private spinner: NgxSpinnerService,
    private inventoryService: InventoryService
  ) {
    this.product_list$ = this.input$.pipe(
      map((term) => this.searchProduct(term))
    );
  }

  productSearchForm = this.fb.group({
    brand_id: ['', Validators.required],
    child_sku: ['', Validators.required],
    parent_sku: [],
    product_name: [],
  });
  ngOnInit(): void {
    this.init_values();
  }
  init_values() {
    this.getLookupValues();
  }

  getLookupValues(pre_load?: boolean) {
    this.spinner.show();

    const obs = this.commonService.getLookupValues();
    obs.subscribe(
      (res) => {
        this.spinner.hide();

        this.product_brand = res['product_brand'];
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  changeProduct(event) {
    this.selectedProduct = event;
    if (event != undefined) {
      this.productSearchForm.patchValue({
        parent_sku: event['parent_sku'],
        brand_id: parseInt(event['brand_id']),
      });
      this.onProductLineSelect(
        event['child_sku'],
        event['parent_sku'],
        event['product_name']
      );
    }
  }

  onSelectProduct() {
    this.orderService.product_search_result.next({
      itemRef: this.itemRef,
      data: this.selectedProduct,
    });
    if (!this.productLineSelected) this.productSearchForm.reset();
    this.productSearchForm.patchValue({
      child_sku: null,
      parent_sku: null,
      product_name: null,
    });
    if (!this.notDialog)
      this.commonService.modal_close.next('ORDER-PRODUCT-SEARCH');
  }

  searchProduct(term) {
    if (typeof term !== 'string') {
      this.loadingProduct = false;
      return term;
    }

    var brand_id = this.productSearchForm.value['brand_id'];
    const searchTerm = term ? term : '';
    if (searchTerm != '') {
      this.loadingProduct = true;
      this.inventoryService
        .searchProductInventory({
          brand_id: brand_id,
          search_term: searchTerm,
          have_invetory: this.have_invetory,
        })
        .subscribe(
          (res) => {
            this.input$.next(res);
          },
          (err) => {
            this.loadingProduct = false;
          }
        );
    }
  }
  onChangeProductLine = async ($event) => {
    this.productLineSelected = $event != undefined;
    this.onProductLineSelect(null, null, null);
  };

  onProductLineSelect = async (child_sku, parent_sku, product_name) => {
    this.productSearchForm.patchValue({
      child_sku: child_sku,
      parent_sku: parent_sku,
      product_name: product_name,
    });
  };

  closeModal() {
    this.commonService.modal_close.next('ORDER-PRODUCT-SEARCH');
  }
}
