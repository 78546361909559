import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';
import { CommonService } from 'src/app/utils/services/common.service';
import { OrderService } from 'src/app/utils/services/order.service';
import { ConfirmPaymentComponent } from './confirm-payment/confirm-payment.component';
import { OrderProductSearchComponent } from './order-product-search/order-product-search.component';
import {
  CASH_REGISSTER_STATUS,
  CASH_REGISTER_TRANSACTION_TYPES,
  MANTLE_MODAL_NAME,
  ORDER_SOURCE_ID,
  PAYMENT_METHOD,
  SYSTEM_SETTINGS,
} from 'src/app/utils/enums/mantle-enums';
import { InventoryService } from 'src/app/utils/services/inventory.service';
import { SettingsService } from 'src/app/utils/services/settings.service';
import { Observable, Subscription } from 'rxjs';
import { CashRegisterService } from 'src/app/utils/services/cash-register.service';
import { Auth0Service } from 'src/app/utils/services/auth0.service';
import { UserService } from 'src/app/utils/services/user.service';
import { MantleOrderReceiptComponent } from '../mantle-order-receipt/mantle-order-receipt.component';
import { MantleMultipleUpcComponent } from '../mantle-multiple-upc/mantle-multiple-upc.component';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';
import { MantleCustomerComponent } from '../../mantle-customer/mantle-customer.component';
import { MerchantService } from 'src/app/utils/services/merchant.service';
import { of } from 'rxjs';
import { OrderTaxComponent } from './order-tax/order-tax.component';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/utils/interfaces/app.state';
import { MantleOrderRedeemComponent } from '../mantle-order-redeem/mantle-order-redeem.component';
import { RewardPointService } from 'src/app/utils/services/rewardpoint.service';
import { PluginService } from 'src/app/utils/services/plugin.service';
import { StorageService } from 'src/app/utils/services/storage.service';
import { ChannelsService } from 'src/app/utils/services/channels.service';
declare let $: any;
@Component({
  selector: 'mantle-order-create',
  templateUrl: './mantle-order-create.component.html',
  styleUrls: ['./mantle-order-create.component.scss'],
})
export class MantleOrderCreateComponent implements OnInit, OnDestroy {
  @Input() order_id;
  active = 1;
  activateCustomerTab: boolean = false;
  isCustomerSelected: boolean = false;
  customerData: any = {};
  orderStatus: Array<any>;
  orderSource: Array<any>;
  paymentMethod: Array<any>;
  currency: Array<any>;
  orderItems: Array<any> = [];
  orderItemsInventory: Array<any> = [];
  isNewRecord: boolean = true;
  canEdit: boolean = false;
  awaitingPayment: boolean = false;
  isExternalSource: boolean = false;
  countries: Array<any> = [];
  elem: any;
  posPluginData: any;
  isPosMode: boolean = false;
  PosNorm_id: any = '';
  defaultCustomerId = '';
  initFormValues: any = {};
  userProfile: any = {};
  activeRegisters: any = [];
  cash_register_id: any;
  store_id: any;
  cashRegisterStore: any;
  cashRegisterUser: any;
  orderDetails: any;
  disclaimerHtml: any;
  itemRef: number;
  paymentTerminal: any;
  orderTaxesList: Array<any> = [];
  orderTaxes: Array<any> = [];
  autoPrintReceipt: any = true;
  private paymentModalSubscription: Subscription;
  private receiptModalSubscription: Subscription;
  private productInventoryModalSubscription: Subscription;
  private orderSearchModalSubscription: Subscription;
  private multipleUPCModalSubscription: Subscription;
  private productSearchSubscription: Subscription;
  private customerSelectedlSubscription: Subscription;
  private searchingCustomerSubscription: Subscription;
  private subscription: Subscription = new Subscription();
  @ViewChild('upcInput') upcInputs: ElementRef;
  taxExempted: boolean = false;
  showVerifyPaidAmount: boolean = false;
  verifyPaidAmount: boolean = false;
  timezone: any = this.commonService.getTimeZone(true);
  displayCashRegister: boolean = false;
  posChannels: any = [];
  sales_channel_id: any;
  activePoSChannel: any;

  internal_sources = [
    ORDER_SOURCE_ID.DIRECT,
    ORDER_SOURCE_ID.MARKET_PLACE,
    ORDER_SOURCE_ID.OTHER,
    ORDER_SOURCE_ID.POS,
    ORDER_SOURCE_ID.KIOSK,
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private commonService: CommonService,
    private orderService: OrderService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private inventoryService: InventoryService,
    private settingsService: SettingsService,
    private cashRegisterService: CashRegisterService,
    private auth0Service: Auth0Service,
    private userService: UserService,
    private mantleModalService: MantleModalService,
    private merchantService: MerchantService,
    private rewardPointService: RewardPointService,
    private pluginService: PluginService,
    @Inject(DOCUMENT) private document: any,
    private store: Store<AppState>,
    private storageService: StorageService,
    private channelsService: ChannelsService
  ) {}

  orderForm = this.fb.group({
    order_id: [],
    order_number: [],
    customer_id: ['', Validators.required],
    order_status_id: [0],
    order_source_id: [ORDER_SOURCE_ID.DIRECT],
    store_id: [],
    payment_method_id: [PAYMENT_METHOD.CASH],
    order_type_id: [],
    order_date: new Date().toISOString(),
    shipment_date: [],
    currency_code: [],
    order_note: [],
    order_total: [0],
    discount_total_total: [0],
    shipment_cost: [0],
    paid_amount: [0],
    change_amount: [0],
    sale_tax_pct: [0],
    sale_tax: [0],
    sub_total: [0],
    to_pay_amount: [0],
    redeemed_points: [0],
    store_credit_amount: [0],
    redeemed_amount: [0],
    is_pct_discount: [1],
    discount_pct: [0],
  });

  shipmentForm = this.fb.group({
    order_id: [],
    shipment_first_name: [],
    shipment_last_name: [],
    shipment_email: [],
    shipment_phone: [],
    shipment_mobile: [],
    shipment_street_address: [],
    shipment_street_address_2: [],
    shipment_city: [],
    shipment_state: [],
    shipment_zipcode: [],
    shipment_country_code: [],
    shipment_note: [],
  });

  billingForm = this.fb.group({
    order_id: [],
    billing_first_name: [],
    billing_last_name: [],
    billing_email: [],
    billing_phone: [],
    billing_mobile: [],
    billing_street_address: [],
    billing_street_address_2: [],
    billing_city: [],
    billing_state: [],
    billing_zipcode: [],
    billing_country_code: [],
    billing_note: [],
  });

  ngOnInit(): void {
    this.initFormValues['orderForm'] = this.orderForm.getRawValue();
    this.initFormValues['shipmentForm'] = this.shipmentForm.getRawValue();
    this.initFormValues['billingForm'] = this.orderForm.getRawValue();

    const uProfile = this.auth0Service.getProfile();

    if (uProfile) {
      this.userService.getUserByEmail(uProfile.email).subscribe(
        (res) => {
          this.userProfile = res;

          //this.getUserCashRegisters();
          this.getActivePosChannels();
        },
        (err) => {
          this.toastr.error(err);
        }
      );
    }

    this.productSearchSubscription = this.orderService.product_search_result.subscribe(
      (res) => {
        this.setSelectedItem(res);
      }
    );

    this.customerSelectedlSubscription = this.orderService.customer_selected.subscribe(
      (res) => {
        this.customerData = res;
        this.shipmentForm.reset({});
        this.billingForm.reset({});
        this.customerSelected(res);
      }
    );

    this.searchingCustomerSubscription = this.commonService.searching_customer.subscribe(
      (res) => {
        this.isCustomerSelected = !res;
      }
    );

    this.elem = this.commonService.getFullScreenElement();

    this.posPluginData = history.state;

    /*if (this.posPluginData.plugin_id == undefined) {
      this.getActivePosPlugins();
    }*/

    this.init_values();

    this.initPoSData();
  }

  ngAfterViewInit() {
    this.setUpcFocus();
    // if (this.isPosMode)
    //   this.openFullscreen();
  }

  init_values() {
    this.orderForm.controls['order_status_id'].disable();
    this.getLookupValues();
    if (this.order_id != null && this.order_id != undefined) {
      this.getOrderDetails();
    } else {
      this.canEdit = true;
      this.getSystemSettings();
    }

    /* this.settingsService
      .getSystemSettingByGroupName('SALE_TAX')
      .subscribe((res: Array<any>) => {
        var total_tax = res
          .map((x) => parseFloat(x.value))
          .reduce((prev, next) => prev + next);
        this.orderForm.patchValue({ sale_tax_pct: total_tax });
      }); */

    this.initDefaultPosCustomer();

    this.onAddOrderItem();
  }

  getLookupValues() {
    this.spinner.show();

    const obs = this.commonService.getLookupValues();
    obs.subscribe(
      (res) => {
        this.spinner.hide();

        this.orderStatus = res['order_status'];
        this.currency = res['currency'];
        this.orderSource = res['order_source'];
        this.paymentMethod = res['payment_method'];
        this.countries = res['countries'];
        this.isEditable();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  init_forms_data = async (orderDetails) => {
    this.orderDetails = orderDetails;
    const order = orderDetails;
    this.order_id = order.order_id;
    /*order.order_date = moment
      .utc(order.order_date)
      .local()
      .format('YYYY-MM-DDTHH:mm');*/
    const order_items = orderDetails.order_items;
    const billingAddress = orderDetails.billing_address;
    const shippingAddress = orderDetails.shipment_address;

    if (orderDetails.order_status_id == 1) {
      this.awaitingPayment = true;
    }
    this.isEditable();

    if (!this.internal_sources.includes(orderDetails.order_source_id)) {
      this.isExternalSource = true;
    }

    this.orderForm.patchValue(order);
    this.orderItems = order_items;
    if (billingAddress != null && billingAddress != undefined) {
      this.billingForm.patchValue(billingAddress);
    }
    if (shippingAddress != null && shippingAddress != undefined) {
      this.shipmentForm.patchValue(shippingAddress);
    }

    this.isCustomerSelected = true;
    return;
  };

  onViewCustomer(isnew?: boolean) {
    const modalRef = this.mantleModalService.open(
      MantleCustomerComponent,
      MANTLE_MODAL_NAME.CUSTOMER_MODAL,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'xl',
      }
    );
    if (!isnew) modalRef.componentInstance.customerData = this.customerData;
  }

  customerSelected($event) {
    this.isCustomerSelected = true;
    this.customerData = $event;
    //this.store.dispatch(actionSelectCustomer({ customer: this.customerData }));

    this.onCopyCustomerAddress(
      {
        currentTarget: {
          checked: true,
        },
      },
      1
    );
    this.onCopyCustomerAddress(
      {
        currentTarget: {
          checked: true,
        },
      },
      2
    );

    this.setCustomerDetails(this.customerData);
  }

  onAddOrderItem() {
    const emptyIndex = this.orderItems?.filter((x) => x.child_sku == undefined)
      ?.length;
    if (emptyIndex <= 0 && this.canEdit) {
      this.orderItems.push({
        edit: true,
      });
      this.itemRef = this.orderItems.length - 1;
    }

    this.setUpcFocus();
  }

  changeQuantity($event, index) {
    //const quantity = $event.target.value
    var itemDetails = this.orderItems[index];
    //itemDetails['quantity'] = quantity
    const itemInventory = this.orderItemsInventory.find(
      (x) => x.child_sku === itemDetails['child_sku']
    );
    /*if(itemInventory == undefined){
      this.getInventoryData(itemDetails, index)
      itemInventory = this.orderItemsInventory.find(x => x.child_sku === itemDetails['child_sku'])
    }*/
    if (itemInventory != undefined && this.canEdit) {
      if ('inventory_id' in itemInventory) {
        this.validateInventory(itemDetails, itemInventory);
        var total = 0;
        /*if (itemDetails['edited']) {
          total =
            parseFloat(itemDetails['quantity']) *
            parseFloat(itemDetails['unit_price']);
        } else {
          total =
            parseFloat(itemDetails['quantity']) *
            parseFloat(itemInventory['sell_price']);
        }*/
        total =
          parseFloat(itemDetails['quantity']) *
          parseFloat(itemDetails['unit_price']);

        itemDetails['total'] = isNaN(total) ? 0 : total.toFixed(2);

        this.calculateTotal();
      }
    } else if (itemDetails['edited']) {
      var total =
        parseFloat(itemDetails['quantity']) *
        parseFloat(itemDetails['unit_price']);
      itemDetails['total'] = isNaN(total) ? 0 : total.toFixed(2);
      this.calculateTotal();
    }
  }

  changePrice($event, index) {
    this.changeQuantity($event, index);
  }

  onEditOrderItem($event, index) {
    this.orderItems[index].edited = true;
  }

  onDeleteOrderItem(index, order_item_id, order_id) {
    if (order_item_id !== null && order_item_id !== undefined) {
      this.spinner.show();

      this.orderService.deleteOrderItem(order_item_id, order_id).subscribe(
        (res) => {
          //this.order_id = order_id
          //this.getOrderDetails()
          this.init_forms_data(res);
          this.spinner.hide();
          this.itemRef -= 1;
          this.calculateTotal();
        },
        (err) => {
          this.spinner.hide();

          this.toastr.error(JSON.stringify(err.error), 'An Error Occurred');
        }
      );
    } else {
      this.orderItems.splice(index, 1);
      this.itemRef -= 1;
      this.calculateTotal();
    }
    this.onAddOrderItem();
  }

  /**
   * @deprecated Search Model embeded directly on orders page
   * @param $event
   * @param index
   */
  onSearchOrderItem($event, index) {
    const modalRef = this.modalService.open(OrderProductSearchComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
      container: this.elem,
    });
    modalRef.componentInstance.itemRef = index;

    this.orderSearchModalSubscription = this.commonService.modal_close.subscribe(
      (data) => {
        if (data === 'ORDER-PRODUCT-SEARCH') {
          modalRef.close();
          this.orderSearchModalSubscription.unsubscribe();
        }
      }
    );
  }

  setSelectedItem(data, getInventoryData = true) {
    const index = data['itemRef'];
    const productData = data['data'];

    const itemAddedIndex = this.orderItems.findIndex(
      (x) => x.child_sku == productData['child_sku']
    );
    if (itemAddedIndex >= 0) {
      this.toastr.info('Selected Item Already Added');
      this.orderItems[itemAddedIndex].quantity =
        parseInt(this.orderItems[itemAddedIndex].quantity) + 1;

      this.changeQuantity(null, itemAddedIndex);
      this.clearUpcValue();
      return false;
    } else {
      const prodValues: any = {
        item_description: productData['product_name'],
        brand_id: productData['brand_id'],
        child_sku: productData['child_sku'],
        parent_sku: productData['parent_sku'],
        product_name: productData['product_name'],
        edit: false,
        loading_inventory: true,
        quantity: 1,
      };
      this.orderItems[index] = {
        ...this.orderItems[index],
        ...prodValues,
      };
      this.onAddOrderItem();

      if (getInventoryData)
        this.getInventoryData(this.orderItems[index], index);
    }
    return true;
  }

  getInventoryData(orderItemData, index) {
    this.inventoryService.getInventoryData(orderItemData).subscribe(
      (res) => {
        this.processInventoryData(orderItemData, res, index);
        this.changeQuantity(null, index);
      },
      (err) => {
        if (this.canEdit) {
          orderItemData['has_errors'] = true;
        }
      }
    );
  }

  processInventoryData(orderItemData, inventoryData, index) {
    if (inventoryData != null) {
      if ('inventory_id' in inventoryData) {
        orderItemData['loading_inventory'] = false;

        //Check if current Inventory item exists
        const inIndex = this.orderItemsInventory.findIndex(
          (x) => x.inventory_id == inventoryData['inventory_id']
        );
        if (inIndex > -1) {
          this.orderItemsInventory.splice(inIndex, 1);
        }
        this.orderItemsInventory.push(inventoryData);
        if (this.canEdit && !this.isExternalSource) {
          this.validateInventory(orderItemData, inventoryData);
        }
      } else {
        orderItemData['loading_inventory'] = false;
        if (this.canEdit && !this.isExternalSource) {
          orderItemData['has_errors'] = true;
        }
      }
    } else {
      orderItemData['loading_inventory'] = false;
      if (this.canEdit && !this.isExternalSource) {
        orderItemData['has_errors'] = true;
      }
    }
  }

  validateInventory(orderItemData, inventoryData) {
    let quantity = 0;
    let available = 0;
    let quantity_reserve = 0;
    let sell_price = 0;
    //1st check if current PoS is linked channel to this inventory
    const linkedData = inventoryData?.product_external_links?.find(
      (x) => x.channel_id == this.activePoSChannel?.channel_pos_id
    );

    if (linkedData != undefined && linkedData != null) {
      quantity = linkedData.quantity;
      available = linkedData.quantity;
      sell_price = linkedData.price;
    }
    //Get store id for the POS - POS only has a single location
    const posStoreId = this.activePoSChannel?.store_ids[0];

    const locationData = inventoryData?.inventory_locations?.find(
      (x) => x.store_id == posStoreId
    );

    if (locationData != undefined && locationData != null) {
      quantity = locationData.quantity;
      quantity_reserve = locationData.quantity_reserve;
      if (linkedData == undefined || linkedData == null) {
        available = locationData.quantity_available;
        sell_price = inventoryData['sell_price'];
      }
    }

    //If no location or linked channel data, use inventory details
    if (locationData == undefined && linkedData == undefined) {
      available = inventoryData['available'];
      quantity = inventoryData['quantity'];
      quantity_reserve = inventoryData['quantity_reserve'];
      sell_price = inventoryData['sell_price'];
    }

    orderItemData['currency_code'] = inventoryData['currency_code'];
    orderItemData['stock'] = quantity;
    orderItemData['reserve'] = quantity_reserve;
    if (!orderItemData['edited'] && this.canEdit) {
      orderItemData['unit_price'] = sell_price;
    }

    if (inventoryData['product_status_id'] === 2) {
      orderItemData['has_warning'] = true;
    }
    if (
      inventoryData['product_status_id'] === 3 ||
      available <= 0 ||
      orderItemData['stock'] == null
    ) {
      orderItemData['has_errors'] = true;
    }
    if (
      orderItemData['quantity'] !== undefined &&
      !orderItemData['has_errors']
    ) {
      if (
        parseFloat(orderItemData['quantity']) > parseFloat(available.toString())
      ) {
        orderItemData['has_errors'] = true;
      } else {
        orderItemData['has_errors'] = false;
      }
    }
  }

  calculateTotal = () => {
    if (!this.canUpdateTotals()) return;
    var total = 0.0;
    var sub_total = 0.0;
    var sale_tax = 0.0;
    var to_pay_amount = 0.0;

    this.orderItems.forEach((item) => {
      if (item.total !== undefined) {
        sub_total += parseFloat(item.total);
      }
    });

    var isPctDiscount = this.orderForm.value.is_pct_discount;
    if (isPctDiscount == 1) {
      var discountPctAmnt =
        (sub_total * parseFloat(this.orderForm.value.discount_pct)) / 100;
      this.orderForm.patchValue({ discount_total_total: discountPctAmnt });
    } else {
      this.orderForm.patchValue({ discount_pct: 0 });
    }

    var discount_total_total = parseFloat(
      this.orderForm.value.discount_total_total
    );

    if (discount_total_total < 0 || isNaN(discount_total_total)) {
      discount_total_total = 0;
      this.orderForm.patchValue({ discount_total_total: 0 });
    }

    var shipment_cost = parseFloat(this.orderForm.value.shipment_cost);

    var sale_tax_pct = parseFloat(this.orderForm.value.sale_tax_pct);

    var store_credit_amount = parseFloat(
      this.orderForm.value.store_credit_amount
    );
    var redeemed_amount = parseFloat(this.orderForm.value.redeemed_amount);

    total =
      sub_total +
      (shipment_cost ? shipment_cost : 0) -
      (discount_total_total ? discount_total_total : 0);

    if (sale_tax_pct > 0)
      sale_tax = Math.round(total * (sale_tax_pct / 100) * 100) / 100;

    total = total + sale_tax;

    to_pay_amount = total - (redeemed_amount + store_credit_amount);

    this.orderForm.patchValue({
      order_total: Math.round(total * 100) / 100,
      sub_total: Math.round(sub_total * 100) / 100,
      sale_tax: sale_tax,
      to_pay_amount: to_pay_amount,
    });

    this.calculateChange();

    this.setUpcFocus();
  };

  calculateChange() {
    var order_total = parseFloat(this.orderForm.value.to_pay_amount);
    var paid_amount = parseFloat(this.orderForm.value.paid_amount);
    if (paid_amount == 0) paid_amount = order_total;
    var change_amount = paid_amount - order_total;
    if (isNaN(change_amount)) change_amount = 0;
    this.orderForm.patchValue({ change_amount: change_amount.toFixed(2) });
  }

  onCopyCustomerAddress($event, copyType) {
    const checked = $event.currentTarget.checked;
    const customer = this.customerData;
    //billing
    if (copyType == 1 && checked && customer != undefined) {
      this.billingForm.patchValue({
        billing_first_name: customer.first_name,
        billing_last_name: customer.last_name,
        billing_email: customer.email,
        billing_phone: customer.phone,
        billing_mobile: customer.mobile,
        billing_street_address: customer.street_address,
        billing_street_address_2: customer.street_address_2,
        billing_city: customer.city,
        billing_state: customer.state,
        billing_zipcode: customer.zipcode,
        billing_country_code: customer.country_code,
      });
    } else if (copyType == 1 && !checked) {
      this.billingForm.reset();
    }
    //Shipping
    if (copyType == 2 && checked && customer != undefined) {
      this.shipmentForm.patchValue({
        shipment_first_name: customer.first_name,
        shipment_last_name: customer.last_name,
        shipment_email: customer.email,
        shipment_phone: customer.phone,
        shipment_mobile: customer.mobile,
        shipment_street_address: customer.street_address,
        shipment_street_address_2: customer.street_address_2,
        shipment_city: customer.city,
        shipment_state: customer.state,
        shipment_zipcode: customer.zipcode,
        shipment_country_code: customer.country_code,
      });
    } else if (copyType == 2 && !checked) {
      this.shipmentForm.reset();
    }
  }

  submitOrder = async (isDraft = false) => {
    const formValue = this.orderForm.getRawValue();
    if (!this.validateOrder(formValue, isDraft)) return;
    var requestPayload: any = {};
    const order_items = [];

    this.orderItems.forEach((orderItem, index) => {
      order_items.push({
        //order_id :formValue.order_id,
        brand_id: orderItem.brand_id,
        child_sku: orderItem.child_sku,
        parent_sku: orderItem.parent_sku,
        quantity: orderItem.quantity,
        currency_code: orderItem.currency_code,
        item_description: orderItem.item_description,
        edited: orderItem.edited ? true : false,
        unit_price: orderItem.unit_price,
      });
      if ('order_item_id' in orderItem) {
        order_items[index]['order_item_id'] = orderItem.order_item_id;
      }
    });

    requestPayload = {
      customer_id: formValue.customer_id,
      //When confirming draft order, set to awaiting payment to allow for quantity update
      order_status_id: isDraft
        ? 0
        : formValue.order_status_id == 0
        ? 1
        : formValue.order_status_id,
      order_source_id: formValue.order_source_id,
      store_id: this.store_id,
      payment_method_id: formValue.payment_method_id,
      order_type_id: formValue.order_type_id,
      order_date: formValue.order_date,
      order_note: formValue.order_note,
      discount_total_total: formValue.discount_total_total,
      shipment_cost: formValue.shipment_cost,
      paid_amount: formValue.paid_amount,
      change_amount: formValue.change_amount,
      sale_tax_pct: formValue.sale_tax_pct,
      sale_tax: formValue.sale_tax,
      sub_total: formValue.sub_total,
      order_total: formValue.order_total,
      reward_points: formValue.reward_points,
      to_pay_amount: formValue.to_pay_amount,
      redeemed_points: formValue.redeemed_points,
      store_credit_amount: formValue.store_credit_amount,
      redeemed_amount: formValue.redeemed_amount,
      order_items: order_items,
      billing_address: this.billingForm.value,
      shipment_address: this.shipmentForm.value,
      order_taxes: this.orderTaxes,
      is_pct_discount: formValue.is_pct_discount,
      discount_pct: formValue.discount_pct,
      sales_channel_id: this.sales_channel_id,
    };
    //default pos user will not require shipping and billing addresses
    if (formValue.customer_id === this.defaultCustomerId) {
      delete requestPayload?.billing_address;
      delete requestPayload?.shipment_address;
    }

    //remove last empty row added item
    requestPayload.order_items = requestPayload.order_items.filter(
      (x) => x.brand_id != undefined && x.child_sku != undefined
    );

    if (formValue.order_id != null && formValue.order_id != undefined) {
      requestPayload['order_id'] = formValue.order_id;

      this.updateOrder(requestPayload, isDraft);
    } else {
      this.spinner.show();
      this.orderService.createOrder(requestPayload).subscribe(
        async (res) => {
          await this.init_forms_data(res);
          this.spinner.hide();
          this.toastr.success('Order Created Successfully');
          if (!isDraft) {
            this.pushCardPayment().then((_) => {
              this.onConfirmPayment();
            });
          }
          /*if (
            this.isPosMode  &&
            this.orderForm.value.payment_method_id == PAYMENT_METHOD.CASH 
          ) {
            this.onConfirmPayment();
          }*/
        },
        (err) => {
          this.spinner.hide();

          this.toastr.error(JSON.stringify(err.error), 'An Error Occurred');
        }
      );
    }
  };

  getOrderDetails() {
    this.spinner.show();
    this.orderService.getOrderById(this.order_id).subscribe(
      (res) => {
        this.orderDetails = res;
        this.init_forms_data(res);
        this.setCustomerDetails(this.orderDetails.customer);
        this.isEditable();
        if (res.order_items !== undefined) {
          res.order_items.forEach((orderItem, index) => {
            this.getInventoryData(orderItem, index);
          });
        }
        this.getSystemSettings();
        this.onAddOrderItem();
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();

        this.toastr.error(JSON.stringify(err.error), 'An Error Occurred');
      }
    );
  }

  setCustomerDetails(details) {
    this.customerData = details;
    this.commonService.external_customer_search.next(this.customerData);
    this.commonService.external_customer_set.next(this.customerData);
    if (this.customerData == undefined) return;

    this.orderForm.patchValue({
      customer_id: this.customerData?.customer_id,
      /*redeemed_amount: 0,
      store_credit_amount: 0,
      redeemed_points: 0,*/
    });
    this.taxExempted = this.customerData?.tax_exempt;
    this.setOrderTaxes(this.orderTaxes);
    this.getCustomerPoints();
  }

  updateOrder(requestPayload, isDraft = false) {
    this.spinner.show();
    this.orderService.updateOrder(requestPayload).subscribe(
      (res) => {
        this.init_forms_data(res);
        this.spinner.hide();
        this.toastr.success('Order Updated Successfully');
        if (!isDraft) {
          this.pushCardPayment().then((_) => {
            this.onConfirmPayment();
          });
        }
      },
      (err) => {
        this.spinner.hide();

        this.toastr.error(err.message, 'An Error Occurred');
      }
    );
  }

  onConfirmPayment() {
    const modalRef = this.modalService.open(ConfirmPaymentComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
      container: this.elem,
    });
    const formValue = this.orderForm.getRawValue();
    modalRef.componentInstance.order_id = this.order_id;
    modalRef.componentInstance.formValue = formValue;
    modalRef.componentInstance.paymentMethods = this.paymentMethod;
    modalRef.componentInstance.paymentTerminal = this.paymentTerminal;

    this.paymentModalSubscription = this.commonService.modal_close.subscribe(
      (data) => {
        if (data === 'ORDER-CONFIRM-PAYMENT') {
          if (this.isPosMode) {
            this.printReceipt().then((_) => {
              this.finalizePosOrder().subscribe(
                (res) => {
                  this.resetOrder();
                },
                (err) => {
                  this.toastr.error(err);
                }
              );
            });
          } else {
            this.printReceipt().then((_) => {
              this.resetOrder();
            });
          }
          modalRef.close();
          this.paymentModalSubscription.unsubscribe();
        } else if (data === 'ORDER-CONFIRM-PAYMENT-CANCEL') {
          this.getOrderDetails();
          modalRef.close();
          this.paymentModalSubscription.unsubscribe();
        }
      }
    );
  }

  onResetItemPrice($event, index) {
    this.orderItems[index]['edited'] = false;
    this.changeQuantity($event, index);
  }

  onViewInventory($event, index) {
    this.inventoryService.openInventoryModal(
      this.orderItems[index]['brand_id'],
      this.orderItems[index]['child_sku'],
      this.orderItems[index]['parent_sku']
    );
  }

  /**
   * PoS section - inStore Pos
   */

  initPoSData() {
    this.isPosMode = true;
    /*if (this.posPluginData.plugin_id != undefined) {
      this.isPosMode = true;
      //this.PosNorm_id = this.posPluginData.norm_id;
      //this.initDefaultPosCustomer();
    }*/
  }

  searchProductByUpc($event, index) {
    const upc = ($event.target as HTMLInputElement).value;
    /**
     * Prevent count duplication if enter and onChange are executed
     */
    if (this.orderItems[index].loading_inventory) return;
    if (upc.trim()?.length) {
      this.orderItems[index].loading_inventory = true;
      this.inventoryService.searchProductByUpc({ upc: upc }).subscribe(
        (res) => {
          this.orderItems[index].loading_inventory = false;
          this.processUpcResponse(res, index);
        },
        (err) => {
          this.orderItems[index]['loading_inventory'] = false;
          this.toastr.error(err?.error?.message);
        }
      );
    }
  }

  processUpcResponse(res, index) {
    if (res.length > 0) {
      //Lauch Model to select from multiple
      if (res.length > 1) {
        this.toastr.warning('Multiple Items Founds');

        const modalRef = this.modalService.open(MantleMultipleUpcComponent, {
          ariaLabelledBy: 'modal-basic-title',
          size: 'xl',
          backdrop: 'static',
          container: this.elem,
        });

        modalRef.componentInstance.searchResults = res;

        this.multipleUPCModalSubscription = this.commonService.modal_close.subscribe(
          (data: any) => {
            if (data.modalName === 'MULTIPLE-UPC-MODAL') {
              modalRef.close();
              if (data.data != null) {
                this.processUpcResponseData(index, data.data);
              }
              this.multipleUPCModalSubscription.unsubscribe();
            }
          }
        );
      } else {
        //to reuse setSelectedItem Method
        const data = res[0];
        this.processUpcResponseData(index, data);
      }
    } else {
      this.toastr.error('Item Not Found');
      this.orderItems[index]['edit'] = true;
      this.orderItems[index]['has_errors'] = true;
    }
  }

  processUpcResponseData(index, data) {
    const selectedItemData = {
      itemRef: index,
      data: {
        brand_id: data.brand_id,
        child_sku: data['Child Sku'],
        parent_sku: data['Parent Sku'],
        product_name: `${data['Product Name']} - ${data['Set Name']}`,
      },
    };

    if (this.setSelectedItem(selectedItemData, false)) {
      this.processInventoryData(
        this.orderItems[index],
        data.product_inventory,
        index
      );
    } else {
      /**
       * Build on already added index
       */
      const existingIndex = this.orderItems.findIndex(
        (x) => x.child_sku == data['Child Sku']
      );
      this.processInventoryData(
        this.orderItems[existingIndex],
        data.product_inventory,
        existingIndex
      );
    }
    this.changeQuantity(null, index);
  }

  getUserCashRegisters = async () => {
    this.cashRegisterService
      .filterCashRegister({
        user_id: this.userProfile.user_id,
        status: CASH_REGISSTER_STATUS.RUNNING,
      })
      .subscribe(
        (res) => {
          this.setUserCashRegisters(res);
        },
        (err) => {
          this.toastr.error(err);
        }
      );
  };

  setUserCashRegisters = async (res) => {
    this.activeRegisters = res;

    //If only single register is assigned to current user
    if (this.activeRegisters.length == 1) {
      this.activeRegisters[0].active = true;
      this.toggleActiveRegister(null, this.activeRegisters[0], 0);
    } else if (this.activeRegisters.length == 0) {
      this.canEdit = false;
    } else {
      var default_cash_register_id = this.storageService.getItem(
        'cash_register_id'
      );
      if (default_cash_register_id != undefined) {
        var defaultRegister = this.activeRegisters.find(
          (x) => x.cash_register_id == default_cash_register_id
        );
        if (defaultRegister != undefined) {
          this.toggleActiveRegister(null, defaultRegister, 0);
        }
      }
    }
  };

  toggleActiveRegister = async ($event, register: any, index) => {
    if ($event != null) {
      if (!$event.target.checked) {
        this.cash_register_id = null;
        this.cashRegisterStore = null;
        this.cashRegisterUser = null;
        this.canEdit = false;
        this.store_id = null;
        return;
      }
    }
    this.cash_register_id = register.cash_register_id;
    this.cashRegisterStore = register.store;
    this.cashRegisterUser = register?.cash_register_users?.user;
    this.paymentTerminal = register?.terminal;
    this.canEdit = true;
    this.store_id = register.store_id;
    index = this.activeRegisters.findIndex(
      (cr) => cr.cash_register_id === this.cash_register_id
    );
    this.activeRegisters[index].active = true;
    //unselect other cash registers
    this.activeRegisters.forEach((reg, rIndex) => {
      if (rIndex != index) {
        this.activeRegisters[rIndex].active = false;
      }
    });
    this.storageService.setItem('cash_register_id', this.cash_register_id);
  };

  togglePosChannel = async ($event, posChannel: any, index, content = null) => {
    if ($event != null) {
      if (!$event.target.checked) {
        this.sales_channel_id = null;
        this.cash_register_id = null;
        this.cashRegisterStore = null;
        this.cashRegisterUser = null;
        this.canEdit = false;
        this.store_id = null;
        this.activeRegisters = [];
        this.activePoSChannel = null;
        return;
      }
    }
    const registers = posChannel.channel_pos_registers.map(
      (y) => y.cash_register
    );
    this.setUserCashRegisters(registers);
    this.posChannels[index].active = true;
    this.activePoSChannel = this.posChannels[index];
    //unselect other cash registers
    this.posChannels.forEach((reg, rIndex) => {
      if (rIndex != index) {
        this.posChannels[rIndex].active = false;
      }
    });
    this.sales_channel_id = posChannel?.sales_channel?.sales_channel_id;

    this.storageService.setItem('channel_pos_id', posChannel.channel_pos_id);
    this.openCashRegisterModal(content);
  };
  openCashRegisterModal = (content) => {
    if (content) {
      this.modalService
        .open(content, { ariaLabelledBy: 'modal-basic-title' })
        .result.then(
          (result) => {},
          (reason) => {}
        );
    }
  };

  pushCardPayment() {
    this.spinner.show();
    return new Promise((resolve, reject) => {
      if (
        this.orderForm?.value?.payment_method_id ==
        PAYMENT_METHOD.CREDIT_DEBIT_CARD
      ) {
        if (this.paymentTerminal?.terminal_id) {
          this.merchantService
            .pushCardPayment(this.order_id, this.paymentTerminal?.terminal_id)
            .subscribe(
              (res) => {
                this.spinner.hide();
                resolve(true);
              },
              (err) => {
                this.spinner.hide();
                resolve(true);
              }
            );
        } else {
          this.spinner.hide();
          this.toastr.warning(
            `No Active Payment Terminal Assigned`,
            `Payment Terminal`
          );
          resolve(true);
        }
      } else {
        this.spinner.hide();
        resolve(true);
      }
    });
  }

  onCashRigisterOverride = () => {
    this.cash_register_id = '1';
    this.canEdit = true;
  };

  openFullscreen() {
    //this.commonService.browserFullScreen(this.elem);
  }

  closeFullscreen() {
    //this.commonService.browserExitFullScreen(this.document);
  }

  strToFloat(value) {
    return parseFloat(value)?.toFixed(2);
  }

  //Reset Order Form for new
  resetOrder = async () => {
    this.order_id = null;
    this.orderForm.reset();
    this.billingForm.reset();
    this.shipmentForm.reset();
    this.orderForm.patchValue(this.initFormValues['orderForm']);
    this.orderForm.patchValue({
      order_date: moment().tz(this.timezone).format('YYYY-MM-DDTHH:mm'),
    });
    this.billingForm.patchValue(this.initFormValues['billingForm']);
    this.shipmentForm.patchValue(this.initFormValues['shipmentForm']);
    this.isNewRecord = true;
    this.canEdit = true;
    this.awaitingPayment = false;
    this.isExternalSource = false;
    this.orderItems = [];
    this.orderItemsInventory = [];
    this.itemRef = 0;
    this.orderTaxes = [];
    this.orderTaxesList = [];
    this.taxExempted = false;
    this.verifyPaidAmount = false;
    this.showVerifyPaidAmount = false;

    this.init_values();

    this.initPoSData();
  };

  finalizePosOrder(): Observable<any> {
    const formValue = this.orderForm.getRawValue();
    if (
      this.isPosMode &&
      this.orderForm.value.payment_method_id == PAYMENT_METHOD.CASH &&
      this.cash_register_id != '1' //Incase of override
    ) {
      return this.cashRegisterService.cashRegisterTransaction({
        cash_register_id: this.cash_register_id,
        transaction_type: CASH_REGISTER_TRANSACTION_TYPES.CASH_ORDER,
        credit: parseFloat(formValue.paid_amount)?.toFixed(2),
        debit: parseFloat(formValue.change_amount)?.toFixed(2),
        order_id: this.order_id,
      });
    }
    return of({});
  }

  printReceipt(override = false) {
    return new Promise((resolve, reject) => {
      if (!this.autoPrintReceipt && !override) {
        this.toastr.warning('Receipt Printing Disabled');
        resolve({});
        return;
      }
      const modalRef = this.modalService.open(MantleOrderReceiptComponent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
        backdrop: 'static',
        container: this.elem,
      });

      modalRef.componentInstance.order_id = this.order_id;
      modalRef.componentInstance.orderDetails = this.orderDetails;
      modalRef.componentInstance.disclaimerHtml = this.disclaimerHtml;
      modalRef.componentInstance.storeDetails = this.cashRegisterStore;
      modalRef.componentInstance.userDetails = this.cashRegisterUser;

      this.receiptModalSubscription = this.commonService.modal_close.subscribe(
        (data) => {
          console;
          if (data === 'ORDER-RECEIPT') {
            modalRef.close();
            this.receiptModalSubscription.unsubscribe();
            resolve({});
          }
        }
      );
    });
  }

  initDefaultPosCustomer() {
    this.settingsService
      .getSystemSettingByName('DEFAULT_ORDER_CUSTOMER_ID')
      .subscribe((res) => {
        this.defaultCustomerId = JSON.parse(res.value).customer_id;
        this.customerData = JSON.parse(res.value);
        this.isCustomerSelected = true;
        this.customerSelected(this.customerData);
        this.orderForm.patchValue({
          order_source_id: ORDER_SOURCE_ID.POS,
          customer_id: this.defaultCustomerId,
        });

        this.setUpcFocus();
      });
  }

  onTaxComponent = () => {
    const sb = this.commonService.orderTaxComponentUpdated.subscribe((data) => {
      this.setOrderTaxes(data);
    });

    const modalRef = this.mantleModalService.open(
      OrderTaxComponent,
      MANTLE_MODAL_NAME.TAX_COMPONENT_MODAL,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
        backdrop: 'static',
      },
      this.onCloseTaxComponent,
      [sb]
    );

    modalRef.componentInstance.order_id = this.order_id;
    modalRef.componentInstance.orderTaxes = this.orderTaxes;
    modalRef.componentInstance.orderTaxesList = this.orderTaxesList;
  };

  onCloseTaxComponent = (subscription: Subscription) => {
    subscription.unsubscribe();
    this.setUpcFocus();
  };

  setOrderTaxes = (salesTax: any) => {
    if (!this.canUpdateTotals()) return;
    var total_tax = 0;
    if (!this.taxExempted && this.canEdit) {
      this.orderTaxes = salesTax;

      if (this.orderTaxes?.length > 0) {
        total_tax = salesTax
          .map((x) => parseFloat(x.sales_tax_pct))
          .reduce((prev, next) => prev + next);
      }
    } else {
      if (this.taxExempted) this.toastr.info('Tax Exempt Customer');
    }

    this.orderForm.patchValue({ sale_tax_pct: total_tax });

    this.calculateTotal();
  };

  setUpcFocus() {
    setTimeout(() => {
      this.upcInputs?.nativeElement?.focus();
      this.upcInputs?.nativeElement?.click();
    }, 1);
  }

  clearUpcValue() {
    setTimeout(() => {
      if (this.upcInputs?.nativeElement)
        this.upcInputs.nativeElement.value = null;
    }, 1);
  }

  collapseSections() {
    setTimeout(() => {
      const defaultCollpased = ['billingFormCard', 'shippingFormCard'];
      defaultCollpased.forEach((section) => {
        try {
          $(`#${section}`).CardWidget('collapse');
        } catch (error) {}
      });
    }, 10);
  }

  getSystemSettings = () => {
    this.settingsService.getSystemSettings(true).subscribe(
      (res) => {
        this.orderTaxesList = res
          .filter((x) => x.group_name === SYSTEM_SETTINGS.SALE_TAX)
          ?.map((tx) => {
            return {
              sales_tax_name: tx.name,
              sales_tax_description: tx.description,
              sales_tax_pct: tx.value,
              is_default: tx.is_default,
            };
          });
        const salesTax = this.orderTaxesList.filter((x) => x.is_default);

        this.setOrderTaxes(salesTax);

        this.disclaimerHtml = res.find(
          (x) => x.name === SYSTEM_SETTINGS.RECEIPT_DISCLAIMER
        )?.value;

        this.autoPrintReceipt = res.find(
          (x) => x.name === SYSTEM_SETTINGS.AUTO_PRINT_RECEIPT
        )?.value;

        this.autoPrintReceipt = this.autoPrintReceipt == 1 ? true : false;

        this.collapseSections();
      },
      (err) => {
        this.toastr.error(err);
      }
    );
  };

  /**
   * Prevent recalculation of order taxes and totals
   * @returns boolen
   */
  canUpdateTotals = (): boolean => {
    //if (this.canEdit || (this.canEdit && !this.isExternalSource)) return true;
    if (!this.isExternalSource && this.canEdit) return true;
    return false;
  };

  validateOrder = (formValue, isDraft = false): boolean => {
    //Payment method validation
    let hasErrors = false;
    let errors = [];
    if (isNaN(formValue.paid_amount) || formValue.paid_amount == null)
      formValue.paid_amount = 0;
    if (isNaN(formValue.order_total) || formValue.order_total == null)
      formValue.order_total = 0;

    if (
      formValue.payment_method_id == PAYMENT_METHOD.CASH &&
      parseFloat(formValue.to_pay_amount) > parseFloat(formValue.paid_amount) &&
      !this.verifyPaidAmount &&
      !isDraft
    ) {
      this.showVerifyPaidAmount = true;
      errors.push(`Amount tendered is less than the order total`);
      hasErrors = true;
    }
    if (!formValue.customer_id) {
      errors.push(`Customer not selected for this order`);
      hasErrors = true;
    }
    if (hasErrors) {
      this.toastr.error(errors.join('<br />'), `Order Error`, {
        enableHtml: true,
        closeButton: true,
        //timeOut: 10000,
      });
    }
    return !hasErrors;
  };

  redeemPoints = (type) => {
    const sb = this.commonService.orderRedeemPoints.subscribe((data: any) => {
      if (type == 'REWARD_POINT') {
        this.orderForm.patchValue({
          redeemed_amount: data.reward_points,
          redeemed_points: data.reward_points / data.points_per_dollar,
        });
        this.customerData.reward_points = 0; //to hide the redeem section
      }
      if (type == 'STORE_CREDIT') {
        this.orderForm.patchValue({ store_credit_amount: data.store_credit });
        this.customerData.store_credit = 0; //to hide the redeem section
      }

      this.calculateTotal();
    });

    const modalRef = this.mantleModalService.open(
      MantleOrderRedeemComponent,
      MANTLE_MODAL_NAME.ORDER_REDEEM_POINT,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
        backdrop: 'static',
      },
      this.onCloseTaxComponent,
      [sb]
    );
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.customer_id = this.orderForm.getRawValue().customer_id;
  };

  getCustomerPoints = () => {
    if (this.customerData != undefined)
      this.subscription.add(
        this.rewardPointService
          .getCustomerPoints(this.customerData.customer_id)
          .subscribe(
            (res) => {
              this.customerData = { ...this.customerData, ...res };
            },
            (error) => {}
          )
      );
  };

  isEditable() {
    if (this.orderStatus) {
      const orderDetails = this.orderForm.getRawValue();
      this.canEdit = this.orderStatus.find(
        (x) => x.order_status_id == orderDetails.order_status_id
      )?.editable;

      this.displayCashRegister = this.canEdit;
    }
  }

  getActivePosPlugins() {
    this.pluginService
      .filterPlugin({
        enabled: true,
        type: 'POS',
      })
      .subscribe(
        (res) => {
          //relook how to handle multiple pos
          if (res.length > 0) this.posPluginData = res[0];
          this.initPoSData();
        },
        (err) => {
          this.toastr.error('Error fetching PoS plugins');
        }
      );
  }

  getActivePosChannels = () => {
    var tst = this.spinner;
    tst.show();
    this.channelsService
      .getUserPosChannels({
        user_id: this.userProfile.user_id,
      })
      .subscribe({
        next: (res) => {
          this.posChannels = res;
          if (this.posChannels.length == 1) {
            this.togglePosChannel(null, this.posChannels[0], 0);
          } else if (this.posChannels.length == 0) {
            this.canEdit = false;
          } else {
            var default_channel_pos_id = this.storageService.getItem(
              'channel_pos_id'
            );
            if (default_channel_pos_id != undefined) {
              var defaultIndex = this.posChannels.findIndex(
                (x) => x.channel_pos_id == default_channel_pos_id
              );
              if (defaultIndex !== -1) {
                this.togglePosChannel(
                  null,
                  this.posChannels[defaultIndex],
                  defaultIndex
                );
              }
            }
          }
        },
        error: (err) => {
          tst.hide();
        },
        complete: () => {
          tst.hide();
        },
      });
  };

  closeModal() {
    //this.closeFullscreen();
    this.commonService.modal_close.next('ORDER-ADD-NEW');
  }

  ngOnDestroy() {
    this.productSearchSubscription.unsubscribe();
    this.customerSelectedlSubscription.unsubscribe();
    this.searchingCustomerSubscription.unsubscribe();
  }
}
