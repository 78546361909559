<div class="row">
  <div class="col-md-12">
    <div class="card" style="background-color: transparent">
      <div class="card-header">
        <div class="card-tools">
          <mantle-print
            [printSectionId]="'print-section'"
            class="mr-2"
          ></mantle-print>
          <button
            type="button"
            class="btn btn-outline-info btn-sm"
            (click)="confirmAudit()"
          >
            Submit
          </button>
        </div>
      </div>

      <div class="card-body" id="print-section">
        <div class="row">
          <div class="col-md-12 table-responsive small">
            <table class="table table-sm hover mantle-excel-table">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Last Audited</th>
                  <th>System Qty</th>
                  <th>Physical Qty</th>
                  <th>Variance</th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="let inventoryItem of inventoryItems; let i = index"
                >
                  <tr class="item-row">
                    <td colspan="5">
                      {{ inventoryItem.child_sku }} -
                      {{ inventoryItem['Product Name'] }}
                    </td>
                  </tr>
                  <tr
                    *ngIf="
                      inventoryItem.inventory_locations == null ||
                      inventoryItem.inventory_locations.length <= 0
                    "
                  >
                    <td colspan="5" class="table-warning">
                      No inventory location is currently assigned.
                    </td>
                  </tr>
                  <tr
                    *ngFor="
                      let inventoryLocation of inventoryItem.inventory_locations;
                      let ii = index
                    "
                  >
                    <td>
                      {{ inventoryLocation.store_name }}
                    </td>
                    <td>
                      {{ inventoryLocation.auditedat | date: 'short':timezone }}
                    </td>
                    <td>
                      {{ inventoryLocation.quantity }}
                    </td>
                    <td>
                      <input
                        type="number"
                        [(ngModel)]="inventoryLocation.physical_qty"
                        [attr.tabindex]="'' + i + ii + 1"
                        (change)="calculateVariance($event, i, ii)"
                      />
                    </td>
                    <td
                      [ngClass]="
                        inventoryLocation.qty_variance == 0
                          ? 'table-success'
                          : inventoryLocation.qty_variance != null
                          ? 'table-warning'
                          : ''
                      "
                    >
                      {{ inventoryLocation.qty_variance }}
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
