import { Injectable } from '@angular/core';
import { AppState } from '@auth0/auth0-angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { mergeMap, map, catchError, of } from 'rxjs';
import { MailingService } from 'src/app/utils/services/mailing.service';
import {
  deleteEmailTemplate,
  deleteEmailTemplateFailure,
  deleteEmailTemplateSuccess,
  loadEmailTemplates,
  loadEmailTemplatesFailure,
  loadEmailTemplatesSuccess,
  sendEmail,
  sendEmailFailure,
  sendEmailSuccess,
  upsertEmailTemplate,
  upsertEmailTemplateFailure,
  upsertEmailTemplateSuccess,
} from './mailing_template.actions';

@Injectable()
export class MailingTemplateEffects {
  constructor(
    private actions$: Actions,
    private mailingService: MailingService,
    private store: Store<AppState>
  ) {}

  loadEmailTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadEmailTemplates),
      mergeMap(() =>
        this.mailingService.getEmailTemplates().pipe(
          map((templates) => loadEmailTemplatesSuccess({ templates })),
          catchError((error) => of(loadEmailTemplatesFailure({ error })))
        )
      )
    )
  );

  upsertEmailTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertEmailTemplate),
      mergeMap((requestPayload) =>
        this.mailingService.updateEmailTemplate(requestPayload).pipe(
          map((template) => upsertEmailTemplateSuccess({ template })),
          catchError((error) => of(upsertEmailTemplateFailure(error)))
        )
      )
    )
  );

  deleteTiered$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteEmailTemplate),
      mergeMap((template_id) =>
        this.mailingService.deleteEmailTemplate(template_id.template_id).pipe(
          map(() => deleteEmailTemplateSuccess(template_id)),
          catchError((error) => of(deleteEmailTemplateFailure(error)))
        )
      )
    )
  );
  sendEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendEmail),
      mergeMap((requestPayload) =>
        this.mailingService.sendEmail(requestPayload.requestPayload).pipe(
          map((response) => sendEmailSuccess({ response })),
          catchError((error) => of(sendEmailFailure(error)))
        )
      )
    )
  );
}
