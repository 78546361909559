import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/utils/services/common.service';

@Component({
  selector: 'mantle-confirm-dialog',
  templateUrl: './mantle-confirm-dialog.component.html',
  styleUrls: ['./mantle-confirm-dialog.component.scss'],
})
export class MantleConfirmDialogComponent implements OnInit {
  @Input() header: any;
  @Input() message: any;
  @Input() icon: any;
  @Input() alertClass: any;

  constructor(private commonService: CommonService) {}

  ngOnInit(): void {}

  dialogAction(action_id) {
    this.commonService.modal_close.next({
      name: 'CONFIRM-DIALOG',
      data: {
        action_id: action_id,
      },
    });
  }
}
