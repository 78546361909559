import { createAction, props } from '@ngrx/store';
const PREFIX = '[EmailTemplate]';

export const loadEmailTemplates = createAction(`${PREFIX} Load Email Template`);
export const loadEmailTemplatesSuccess = createAction(
  `${PREFIX} Load Email Template Success`,
  props<{ templates: any[] }>()
);
export const loadEmailTemplatesFailure = createAction(
  `${PREFIX} Load Email Template Failure`,
  props<{ error: any }>()
);

export const upsertEmailTemplate = createAction(
  `${PREFIX} Upsert Email Template`,
  props<{ template: any }>()
);

export const upsertEmailTemplateSuccess = createAction(
  `${PREFIX} Upsert Email Template Success`,
  props<{ template: any }>()
);

export const upsertEmailTemplateFailure = createAction(
  `${PREFIX} Upsert Email Template Failure`,
  props<{ error: any }>()
);

export const deleteEmailTemplate = createAction(
  `${PREFIX} Delete Email Template`,
  props<{ template_id: string }>()
);

export const deleteEmailTemplateSuccess = createAction(
  `${PREFIX} Delete Email Template Success`,
  props<{ template_id: string }>()
);

export const deleteEmailTemplateFailure = createAction(
  `${PREFIX} Delete Email Template Failure`,
  props<{ error: any }>()
);

export const sendEmail = createAction(
  `${PREFIX} Send Email`,
  props<{ requestPayload: any }>()
);

export const sendEmailSuccess = createAction(
  `${PREFIX} Send Email Success`,
  props<{ response: any }>()
);

export const sendEmailFailure = createAction(
  `${PREFIX} Sned Email Failure`,
  props<{ error: any }>()
);
