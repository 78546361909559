import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  OnInit,
  forwardRef,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  //NgControl,
} from '@angular/forms';
import { CommonService } from '../services/common.service';

@Directive({
  selector: '[IsoDateLocal]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IsoDateLocalDirective),
      multi: true,
    },
  ],
})
export class IsoDateLocalDirective
  implements ControlValueAccessor /*, OnInit, AfterViewInit*/ {
  private timezone: any;
  constructor(
    private el: ElementRef,
    private datePipe: DatePipe,
    private commonService: CommonService
  ) {
    this.timezone = this.commonService.getTimeZone();
  }
  private previousValue: string | null = null;
  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};

  writeValue(value: string | null): void {
    const inputElement = this.el.nativeElement;
    if (value) {
      const localDateTime = this.isoToLocalDatetime(value);
      this.previousValue = localDateTime;
      inputElement.value = localDateTime;
    } else {
      this.previousValue = null;
      inputElement.value = '';
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error('Method not implemented.');
  }
  // Convert ISO datetime to local datetime format
  private isoToLocalDatetime(isoString: string): string {
    const date = new Date(isoString);
    const formatted = this.datePipe.transform(
      date,
      'yyyy-MM-ddTHH:mm',
      this.timezone
    );
    return formatted || '';
  }

  private localDatetimeToIso(localDatetime: string): string {
    const parsedDate = new Date(localDatetime);
    const isoString = parsedDate.toISOString();
    if (this.previousValue) {
      const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const dateWithTimezone = this.datePipe.transform(
        localDatetime,
        'yyyy-MM-ddTHH:mm:ss.ZZZZZ',
        browserTimezone
      );
      return dateWithTimezone;
    }

    return isoString;
  }

  isoDateValue: any;
  isSet = false;

  @HostListener('ngModelChange', ['$event']) ngModelChange($event) {
    /*if (!this.isSet) {
      this.isSet = true;
      this.isoDateValue = $event;
      setTimeout(() => this.patchIsoDate(), 500);
    }*/
  }
  // Listen for changes in the input element
  @HostListener('input', ['$event.target.value'])
  onInput(value: string): void {
    const isoValue = this.localDatetimeToIso(value);
    this.previousValue = isoValue;
    this.onChange(isoValue);
  }
  @HostListener('blur')
  onBlur(): void {
    this.onTouched();
  }

  /*@HostListener('input', ['$event.target.value'])
  onInput(value: string): void {
    alert('input')
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const dateWithTimezone = this.datePipe.transform(
      value, 'yyyy-MM-ddTHH:mm:ss.ZZZZZ', timeZone
    );
    console.log(dateWithTimezone)
    this.el.nativeElement.value = dateWithTimezone;
  }*/

  /*ngOnInit(): void {
    if (this.ngControl['options']?.standalone) {
      setTimeout(() => {
        this.patchIsoDate();
      }, 500);
    }
  }

  ngAfterViewInit(): void {
    if (this.ngControl['options']?.standalone) {
      setTimeout(() => {
        this.patchIsoDate();
      }, 500);
    }
  }

  patchIsoDate = () => {
    this.isoDateValue = this.isoDateValue ?? this.ngControl.control.value;
    if (this.isoDateValue) {
      this.isoDateValue = this.datePipe.transform(
        this.isoDateValue,
        'yyyy-MM-ddTHH:mm',
        this.commonService.getTimeZone()
      );
      this.ngControl.control.patchValue(this.isoDateValue);
    }
  };*/
}
