import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
import { CashRegisterService } from 'src/app/utils/services/cash-register.service';
import { ChannelsService } from 'src/app/utils/services/channels.service';
import { CommonService } from 'src/app/utils/services/common.service';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';
import { SettingsService } from 'src/app/utils/services/settings.service';

@Component({
  selector: 'channel-activate-pos',
  templateUrl: './channel-activate-pos.component.html',
  styleUrl: './channel-activate-pos.component.scss',
})
export class ChannelActivatePosComponent {
  channelOption: any = {};
  @Input() salesChannel: any = {};
  validated: boolean = false;
  linkedCashRegisters: any = [];
  cashRegisterList: any = [];
  storeList: Array<any> = [];
  constructor(
    private fb: UntypedFormBuilder,
    private channelsService: ChannelsService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private settingsService: SettingsService,
    private cashRegisterService: CashRegisterService,
    private mantleModalService: MantleModalService
  ) {}

  configForm = this.fb.group({
    channel_pos_id: [],
    channel_ref: [],
    store_ids: [],
    channel_pos_description: ['POS Channel'],
    update_frequency: [0],
    created_at_min: [],
  });

  ngOnInit(): void {
    this.channelOption = this.salesChannel?.channel_pos;
    if (this.channelOption != undefined) {
      this.configForm.patchValue(this.channelOption);
      this.linkedCashRegisters =
        this.channelOption?.channel_pos_registers ?? [];
    }
    this.spinner.show();
    this.cashRegisterService.getCashRegisters().subscribe({
      next: (res) => {
        this.cashRegisterList = res;
      },
      error: (err) => {
        this.spinner.hide();
      },
      complete: () => {
        this.spinner.hide();
      },
    });
    this.settingsService.getStores().subscribe({
      next: (res) => {
        this.storeList = res;
      },
      error: (err) => {
        this.spinner.hide();
      },
      complete: () => {
        this.spinner.hide();
      },
    });
  }
  onValidate = () => {
    this.validated = true;
  };

  onSave = () => {
    this.spinner.show();
    const requestPayload = {
      ...this.configForm.getRawValue(),
      ...{
        channel_pos_registers: this.cashRegisterList,
      },
    };
    this.channelsService.createPosChannel(requestPayload).subscribe({
      next: (res) => {
        this.configForm.patchValue(res);
        this.toastr.success('Channel Added Successfully');
        this.closeModal();
        this.channelsService.channelAdded.next({});
        this.settingsService.statusMappingDialog();
      },
      error: (err) => {
        this.toastr.error(err.error.message, 'An Error Occurred');
        this.spinner.hide();
      },
      complete: () => {
        this.spinner.hide();
      },
    });
  };

  onUpdate = () => {
    this.spinner.show();
    const obs = this.channelsService
      .updatePosChannel(this.configForm.value)
      .subscribe({
        next: (res) => {
          this.toastr.success('Update Successful');
          this.closeModal();
        },
        error: (err) => {
          this.toastr.error(err.error.message, 'An Error Occurred');
          this.spinner.hide();
        },
        complete: () => {
          this.spinner.hide();
        },
      });
  };

  onUpdateFrequency = () => {
    this.spinner.show();
    this.channelsService
      .updatePosChannel({
        channel_wc_id: this.configForm.value['channel_wc_id'],
        channel_ref: this.configForm.value['channel_ref'],
        update_frequency: this.configForm.value['update_frequency'],
      })
      .subscribe({
        next: (res) => {
          this.toastr.success('Frequency Update Successful');
          this.closeModal();
        },
        error: (err) => {
          this.toastr.error(err.error.message, 'An Error Occurred');
          this.spinner.hide();
        },
        complete: () => this.spinner.hide(),
      });
  };

  onAddCashRegister = () => {
    this.linkedCashRegisters.push({
      cash_register_id: null,
      channel_pos_id: this.configForm.value['channel_pos_id'],
    });
  };

  onChangeRegister = ($event, i) => {
    var exists = this.linkedCashRegisters.filter(
      (x) => x.cash_register_id == $event['cash_register_id']
    );
    if (exists?.length > 1) {
      this.toastr.warning('Cash Register Already Added');
      this.onRemoveRegister(i);
    }

    if (this.linkedCashRegisters[i]['channel_pos_id'] != null) {
      this.spinner.show();
      this.channelsService
        .posChannelLinkRegister(this.linkedCashRegisters[i])
        .subscribe({
          next: (res) => {},
          error: (err) => {
            this.spinner.hide();
          },
          complete: () => {
            this.spinner.hide();
          },
        });
    }
  };

  onViewRegister = (i) => {};

  onRemoveRegister = (i) => {
    if (this.linkedCashRegisters[i]['id'] !== undefined) {
      this.spinner.show();
      this.channelsService
        .posChannelDeleteRegister(this.linkedCashRegisters[i]['id'])
        .subscribe({
          next: (res) => {
            this.toastr.success(
              'The cash register has been successfully removed from this PoS.'
            );
            this.linkedCashRegisters.splice(i, 1);
          },
          error: (err) => {
            this.spinner.hide();
          },
          complete: () => {
            this.spinner.hide();
          },
        });
    } else {
      this.linkedCashRegisters.splice(i, 1);
    }
  };
  onAllocateInventories = () => {
    this.mantleModalService.confirmDialog(
      `Allocate Invemtories`,
      'By confirming this action, all inventories that have not yet been linked will be allocated to this POS channel with either the default quantity or location-specific quantity.\r\n \r\nIf this POS location is not already assigned to the inventory, the assigned location will be linked to the inventory.\r\nNote: This works best in a single location setup.',
      this.allocateInventories,
      [],
      'fas fa-exclamation-triangle me-2',
      'alert alert-warning'
    );
  };

  allocateInventories = () => {
    this.spinner.show();
    this.channelsService
      .allocatePosToInventories({
        channel_pos_id: this.configForm.value['channel_pos_id'],
      })
      .subscribe({
        next: (res) => {
          this.toastr.success(res.message);
        },
        error: (err) => {
          this.spinner.hide();
        },
        complete: () => {
          this.spinner.hide();
        },
      });
  };

  closeModal() {
    this.linkedCashRegisters = [];
    this.commonService.modal_close.next(
      MANTLE_MODAL_NAME.CHANNEL_POS_ACTIVATION_MODAL
    );
  }
}
