import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom, Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChannelsService } from 'src/app/utils/services/channels.service';
import { CommonService } from 'src/app/utils/services/common.service';
import { InventoryService } from 'src/app/utils/services/inventory.service';
import { BcUpdateInventoryComponent } from '../../channel/bc/bc-update-inventory/bc-update-inventory.component';
import {
  CHANNEL_REF_ENUM,
  MANTLE_MODAL_NAME,
} from 'src/app/utils/enums/mantle-enums';
import { ProductService } from 'src/app/utils/services/product.service';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';
import { MantleInventoryAdvancedComponent } from '../mantle-inventory-advanced/mantle-inventory-advanced.component';
import { SettingsService } from 'src/app/utils/services/settings.service';

@Component({
  selector: 'mantle-product-inventory',
  templateUrl: './mantle-product-inventory.component.html',
  styleUrls: ['./mantle-product-inventory.component.scss'],
})
export class MantleProductInventoryComponent implements OnInit, OnDestroy {
  public product_list$: Observable<any>;
  public input$ = new Subject<any | null>();
  @Input() externalData: any;
  child_sku: any;
  //Holds the no# of inventory counts being processed.
  processingChannelsCount = 0;
  productSelected: boolean = false;
  storeList: Array<any> = [];
  salesChannels: Array<any> = [];
  private inventorySubsription1: Subscription;
  private inventorySubsription2: Subscription;
  private inventorySubsription3: Subscription;
  private inventorySubsription4: Subscription;
  private inventorySubsription5: Subscription;
  private inventorySubsription6: Subscription;
  private inventorySubsription7: Subscription;
  private inventorySubsription8: Subscription;
  private inventorySubsription9: Subscription;
  private inventorySubsription10: Subscription;
  private inventorySubsription11: Subscription;
  private inventoryTCGSubsription: Subscription;
  private inventoryBCSubsription: Subscription;
  private inventoryShopifySubsription: Subscription;
  private inventoryAmazonSubsription: Subscription;
  private inventoryCTSubsription: Subscription;
  private confirmSkuSubscription: Subscription;
  private inventoryWCSubsription: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private commonService: CommonService,
    private inventoryService: InventoryService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private channelsService: ChannelsService,
    private productService: ProductService,
    private mantleModalService: MantleModalService,
    private settingsService: SettingsService
  ) {
    this.product_list$ = this.input$.pipe(
      map((term) => this.searchProduct(term))
    );

    this.inventorySubsription1 = this.inventoryService.inventory_item_selected.subscribe(
      (data) => {
        this.externalInit(data);
      }
    );

    this.inventorySubsription2 = this.inventoryService.product_edit_loaded.subscribe(
      (data: boolean) => {
        this.inventory_only_dialog = data;
      }
    );

    this.inventorySubsription3 = this.inventoryService.product_update_btn_clicked.subscribe(
      () => {
        this.addProductInventory();
      }
    );
    this.channelsService.getChannels().subscribe({
      next: (res) => {
        this.salesChannels = res;
      },
    });
    this.processedChannelsCounter(0);
  }

  productForm = this.fb.group({
    inventory_id: [],
    brand_id: ['', Validators.required],
    child_sku: ['', Validators.required],
    parent_sku: [],
    product_name: [],
    quantity: [],
    sold: [],
    available: [],
    product_status_id: [],
    net_charge: [],
    currency_code: ['USD'],
    buy_price: [],
    sell_price: [],
    discount: [],
    quantity_reserve: [],
    reorder_point: [],
    picking_bin: [],
    asin: [],
    avg_cost: [],
  });

  has_inventory_data = false;
  loadingProduct = false;
  product_brand = [];
  currency = [];
  sales_channels = [];
  product_status = [];
  task_description = '';
  links: any = [];
  primary_input_disabled: boolean = false;
  inventory_only_dialog: boolean = true;
  queryIndex: number = 0;
  inventoryLocations: any = [];

  @ViewChildren('sales_channels_view') sales_channels_view: QueryList<any>;

  ngOnInit(): void {
    this.init_values();
  }
  init_values() {
    this.getLookupValues();
  }

  //ngAfterViewInit() {}

  getLookupValues(pre_load?: boolean) {
    this.spinner.show();

    this.inventorySubsription4 = this.commonService.getLookupValues().subscribe(
      (res) => {
        this.spinner.hide();

        this.product_brand = res['product_brand'];
        this.currency = res['currency'];
        this.product_status = res['product_status'];
        this.sales_channels = res['sales_channel'];
        if (pre_load) {
          this.inventoryService.inventory_item_selected.next(null);
        }
        this.changeBrand();

        this.externalInit(null);
      },
      (err) => {
        this.spinner.hide();
        //this.open_dialog()
      }
    );

    this.settingsService.getStores().subscribe({
      next: (res) => {
        this.storeList = res;
      },
      error: (err) => {
        this.spinner.hide();
      },
      complete: () => {
        this.spinner.hide();
      },
    });
  }

  searchProduct(term) {
    this.has_inventory_data = false;
    if (typeof term !== 'string') {
      this.loadingProduct = false;
      return term;
    }

    var brand_id = this.productForm.value['brand_id'];
    const searchTerm = term ? term : '';
    if (searchTerm != '') {
      this.spinner.show();
      this.loadingProduct = true;
      this.inventorySubsription5 = this.inventoryService
        .searchProductInventory({
          brand_id: brand_id,
          search_term: searchTerm,
        })
        .subscribe(
          (res) => {
            this.input$.next(res);
            this.spinner.hide();
          },
          (err) => {
            this.loadingProduct = false;
            this.spinner.hide();
          }
        );
    }
    //this.loadingProduct = false;
  }

  changeBrand() {}

  changeProduct(event) {
    this.productForm.patchValue({
      parent_sku: event?.parent_sku,
      brand_id: parseInt(event['brand_id']),
    });
  }

  addProductInventory(update_links = true) {
    this.spinner.show();
    const req = this.productForm.getRawValue();
    req.inventory_locations = this.inventoryLocations;
    req.product_external_links = this.links;

    this.inventorySubsription6 = this.inventoryService
      .updateInventory(req)
      .subscribe(
        {
          next: async (res) => {
            this.child_sku = res['child_sku'];
            this.productForm.patchValue({ child_sku: this.child_sku });
            delete res['child_sku'];
            delete res['brand_id'];
            this.productForm.patchValue(res);
            this.inventoryLocations = res.inventory_locations ?? [];
            this.links = res.product_external_links ?? [];
            //TEMP await this.updateInventoryLocations();
            //update links
            try {
              /*if (update_links) {
                this.updateLinks(res['inventory_id']);
              } else {
                this.links.forEach((link, index) => {
                  this.links[index]['inventory_id'] = res['inventory_id'];
                });
              }
              //Prevent double saving - Multiple shopify product
              if (update_links) {
                this.updateAllChannels();
              }*/

              if (this.inventory_only_dialog) {
                this.updateProductDetails(req);
              }
            } catch (error) {}
            this.spinner.hide();
            this.toastr.success('Inventory Details Saved', 'Success');
          },
          error: (err) => {
            this.spinner.hide();
            this.toastr.error('Inventory Details Saved', 'An Error Occurred');
          },
          complete: () => {
            this.spinner.hide();
          },
        }
        /*(res) => {
        },
        (err) => {
          this.spinner.hide();

          this.toastr.error('Inventory Details Saved', 'An Error Occurred');
        }*/
      );
  }

  manageInventory() {
    this.spinner.show();
    var req = this.productForm.getRawValue(); //this.productForm.value;
    //remove values from the previous selection
    Object.keys(req).forEach((key) => {
      if (key !== 'child_sku' && key !== 'brand_id' && key !== 'parent_sku') {
        req[key] = null;
      }
    });
    this.inventorySubsription7 = this.inventoryService
      .getInventoryData(req)
      .subscribe(
        (res) => {
          if (res == undefined) res = req;
          this.child_sku = res['child_sku'];
          //delete if a bug fix for ng-select which looses the value
          delete res['child_sku'];
          delete res['brand_id'];

          this.productForm.patchValue(res);
          this.has_inventory_data = true;
          this.inventoryLocations = res.inventory_locations ?? [];
          this.links = res.product_external_links ?? [];
          this.spinner.hide();

          /*try {
            if (
              res['inventory_id'] !== undefined &&
              res['inventory_id'] !== null
            ) {
              this.getInventoryLinks(res['inventory_id']);
            } else {
              this.links = [];
            }
          } catch (error) {}*/
        },
        (err) => {
          this.toastr.error(JSON.stringify(err.error), 'An Error Occurred');
          this.spinner.hide();
        }
      );
  }

  getInventoryLinks(inventory_id: number) {
    if (inventory_id !== null) {
      this.spinner.show();
      this.inventorySubsription7 = this.inventoryService
        .getInventoryChannelLinks(inventory_id)
        .subscribe(
          (res) => {
            this.links = res ?? [];
            this.links.forEach((link, index) => {
              const selected_channel = this.sales_channels.find(
                (x) => x.sales_channel_id == link.sales_channel_id
              );
              //this.sales_channels_view[index].select(selected_channel.sales_channel_id);
            });
            this.spinner.hide();
          },
          (err) => {
            this.spinner.hide();
          }
        );
    }
  }

  updateLinks(inventory_id, i = null) {
    if (inventory_id !== undefined) {
      this.spinner.show();

      this.links.forEach((link, index) => {
        this.links[index]['inventory_id'] = inventory_id;
      });

      var linkPayload = [];
      if (i != null) {
        linkPayload.push(this.links[i]);
      } else {
        linkPayload = this.links;
      }
      this.inventorySubsription8 = this.inventoryService
        .upsertLinks(linkPayload)
        .subscribe(
          (res) => {
            this.spinner.hide();
          },
          (err) => {
            this.spinner.hide();
          }
        );
    }
  }

  onAddLink() {
    this.links.push({
      channel_id: '',
      sales_channel_id: '',
      store_id: '',
      channel_name: '',
      skuid: this.child_sku,
      quantity: 0, //this.calculateChannelQuantity(),
      price: this.productForm.value['sell_price'],
      url: '',
      inventory_id: this.productForm.value['inventory_id'],
    });
  }

  onRemoveLink(i: number) {
    if (this.links[i]['id'] !== undefined) {
      this.inventorySubsription8 = this.inventoryService
        .deleteLink({
          id: this.links[i]['id'],
          inventory_id: this.productForm.value['inventory_id'],
        })
        .subscribe(
          (res) => {
            this.toastr.success('Item removed');
            this.links.splice(i, 1);
          },
          (err) => {
            this.toastr.error(err, 'An Error Ocurred');
          }
        );
    } else {
      this.links.splice(i, 1);
    }
  }

  onChannelChange($event: any, i: number) {
    this.setChannelItemError(i);
    this.links[i].sales_channel_id = $event['sales_channel_id'];

    var exists = this.links.filter(
      (x) => x.sales_channel_id == $event['sales_channel_id']
    );
    if (exists?.length > 1) {
      this.toastr.warning('Sales Channel Already Added');
      this.onRemoveLink(i);
    } else {
      this.spinner.show();

      this.inventorySubsription9 = this.inventoryService
        .validateChannelCatalog({
          channel_option_id: $event['channel_option_id'],
          brand_id: this.productForm.getRawValue().brand_id,
          child_sku: this.productForm.getRawValue().child_sku,
        })
        .subscribe(
          (res) => {
            this.spinner.hide();

            if (res.isValid) {
              this.links[i].skuid = this.child_sku;
              this.links[i].channel_id = $event['channel_ref_id'];
              this.links[i].channel_tcg_id = $event['channel_ref_id'];
              this.links[i].channel_name = $event['sales_channel_description'];
              this.links[i].quantityEdited = true;
              this.links[i].sales_channel = $event.sales_channel;

              if ($event['channel_ref'] == CHANNEL_REF_ENUM.AMAZON_SP) {
                this.validateAmazonAsin($event, i);
              }
            } else {
              if (res.type == 'channel_id') {
                switch ($event['channel_ref']) {
                  case CHANNEL_REF_ENUM.BC:
                  case CHANNEL_REF_ENUM.SHOPIFY:
                  case CHANNEL_REF_ENUM.AMAZON_SP:
                  case CHANNEL_REF_ENUM.WOO_COMMERCE:
                    if (res.field_name != 'ASIN') {
                      this.validateChannelId(res.field_name)
                        .then((validated) => {
                          if (validated) this.onChannelChange($event, i);
                          else {
                            this.toastr.error(res.errorMessage);
                            this.onRemoveLink(i);
                          }
                        })
                        .catch((error) => {});
                      break;
                    }
                  default:
                    this.toastr.error(res.errorMessage);
                    this.onRemoveLink(i);
                    break;
                }
              } else {
                this.toastr.error(res.errorMessage);
                this.onRemoveLink(i);
              }
            }
            this.updateProduct($event, 'Quantity on Hand');
          },
          (err) => {
            this.spinner.hide();
            this.toastr.error('An error occured');
            this.onRemoveLink(i);
          }
        );
    }
  }

  externalInit(data?: any) {
    data = data === null || data == undefined ? this.externalData : data;
    this.externalData = data;

    if (data != null && data != undefined) {
      this.primary_input_disabled = true;
      this.productForm.controls['brand_id'].disable();
      this.productForm.controls['child_sku'].disable();
      this.productForm.patchValue({ parent_sku: data['parent_sku'] });

      let brand = this.product_brand.find(
        (x) => x.id.toString() === data['brand_id'].toString()
      );

      //Sorts the concurrency issue where the page loads before lookup is received
      if (brand === undefined) {
        this.getLookupValues(true);
      } else {
        this.productForm.patchValue({ brand_id: brand['id'] });
        this.inventorySubsription10 = this.inventoryService
          .searchProductInventory({
            brand_id: brand['id'],
            search_term: data['child_sku'],
          })
          .subscribe((res) => {
            let child = res.filter(
              (x) => x.child_sku.toString() === data['child_sku']
            );
            this.input$.next(child);

            this.productForm.patchValue({
              child_sku: data['child_sku'],
            });

            this.manageInventory();
          });
      }
    }
  }

  updateProduct($event, destination) {
    if ($event?.target?.value)
      this.inventoryService.inventory_item_updated.next({
        [destination]: $event.target.value,
      });

    if (
      destination == 'Reserve Quantity' ||
      destination == 'Quantity on Hand' ||
      destination == 'sold'
    ) {
      this.onUpdateReserveQuantity($event);
    }
  }

  updateProductDetails(req) {
    this.inventorySubsription11 = this.productService
      .updateProductDetails({
        'Child Sku': req['child_sku'],
        brand_id: req['brand_id'],
        'Quantity on Hand': req['quantity'],
        'Reserve Quantity': req['quantity_reserve'],
        'Sale Price': req['sell_price'],
        'Picking Bin': req['picking_bin'],
      })
      .subscribe(
        (res) => {},
        (err) => {
          this.toastr.error(err?.error?.message);
        }
      );
  }

  updateAllChannels() {
    this.processedChannelsCounter(this.links.length);

    this.links.forEach((link, index) => {
      this.onUpdateChannel(index, true);
    });
  }

  onUpdateChannel(i: number, updatingAllChannels = false) {
    this.links[i].skuid = this.child_sku;
    if (this.links[i].quantity == null) {
      this.links[i].quantity = this.calculateChannelQuantity(this.links[i]);
    }
    const requestPayload = this.links[i];
    const s_chanel = this.sales_channels.find(
      (x) => x.sales_channel_id == requestPayload.sales_channel_id
    );

    if (requestPayload.inventory_id != undefined) {
      //Prevent infinite loop for updateLinks
      if (!updatingAllChannels) {
        //Only a single channel is being updated
        this.processedChannelsCounter(1);
        this.addProductInventory(false);
        this.updateLinks(requestPayload.inventory_id, i);
      }

      switch (s_chanel?.channel_ref) {
        case CHANNEL_REF_ENUM.TCG:
          this.updateTcgSkuInventory({
            ...requestPayload,
            ...{
              channel_tcg_id: requestPayload.channel_id,
              quantityEdited: true,
            },
          });
          break;
        case CHANNEL_REF_ENUM.BC:
          this.updateBCProduct(requestPayload);
          break;
        case CHANNEL_REF_ENUM.SHOPIFY:
          this.updateShopifyProduct(requestPayload);
          break;
        case CHANNEL_REF_ENUM.AMAZON_SP:
          this.validateAmazonAsin(requestPayload, i);
          this.updateAmazonSpProduct(requestPayload);
          break;
        case CHANNEL_REF_ENUM.CARD_TRADER:
          this.updateCardTraderProduct(requestPayload);
          break;
        case CHANNEL_REF_ENUM.WOO_COMMERCE:
          this.updateWooCommeceProduct(requestPayload);
          break;
        default:
          break;
      }
    } else {
      this.toastr.info(
        'Please Save The Inventory Details',
        'Data Not Yet saved'
      );
    }
  }

  onUpdateReserveQuantity($event) {
    //var totalReserve = 0
    this.links.forEach((link) => {
      //totalReserve+=link.quantity
      link.quantity = this.calculateChannelQuantity(link); //$event.target.value;
    });

    this.productForm.patchValue({
      available: this.calculateAvailableQuantity(),
    });

    //this.updateProduct({target:{value:totalReserve}}, "Reserve Quantity")
  }

  updateTcgSkuInventory(requestPayload) {
    this.spinner.show();
    this.inventoryTCGSubsription = this.channelsService
      .updateTcgSkuInventory(requestPayload)
      .subscribe(
        (res) => {
          this.spinner.hide();
          this.toastr.success('TCG Player Channel Product Updated');
          this.processedChannelsCounter(-1);
        },
        (err) => {
          this.spinner.hide();

          var errMsg = err.error != undefined ? err.error.message : err.message;
          this.toastr.error(errMsg, 'TCG Player  Channel Error');
          this.processedChannelsCounter(-1);
        }
      );
  }

  updateBCProduct(requestPayload) {
    this.spinner.show();
    this.inventoryBCSubsription = this.channelsService
      .updateBCProduct(requestPayload)
      .subscribe(
        (res) => {
          this.spinner.hide();
          this.toastr.success('BigCommerce  Channel Product Updated');
          this.processedChannelsCounter(-1);
        },
        (err) => {
          this.spinner.hide();

          var errMsg = err.error != undefined ? err.error.message : err.message;
          this.toastr.error(errMsg, 'BigCommerce  Channel Error');
          this.processedChannelsCounter(-1);
        }
      );
  }

  updateShopifyProduct(requestPayload) {
    this.spinner.show();
    this.inventoryShopifySubsription = this.channelsService
      .updateShopifyProduct(requestPayload)
      .subscribe(
        (res) => {
          this.spinner.hide();
          this.toastr.success('Shopify Channel Product Updated');
          this.processedChannelsCounter(-1);
        },
        (err) => {
          this.spinner.hide();

          var errMsg = err.error != undefined ? err.error.message : err.message;
          this.toastr.error(errMsg, 'Channel Shopify Error');
          this.processedChannelsCounter(-1);
        }
      );
  }

  updateAmazonSpProduct(requestPayload) {
    this.spinner.show();
    this.inventoryAmazonSubsription = this.channelsService
      .updateAmazonSpProduct(requestPayload)
      .subscribe(
        (res) => {
          this.spinner.hide();
          this.toastr.success('Amazon SP Channel Product Updated');
          this.processedChannelsCounter(-1);
        },
        (err) => {
          this.spinner.hide();

          var errMsg = err.error != undefined ? err.error.message : err.message;
          this.toastr.error(errMsg, 'Channel Amazon SP Error');
          this.processedChannelsCounter(-1);
        }
      );
  }

  updateCardTraderProduct(requestPayload) {
    this.spinner.show();
    this.inventoryCTSubsription = this.channelsService
      .updateCardTraderProduct(requestPayload)
      .subscribe(
        (res) => {
          this.spinner.hide();
          this.toastr.success('CardTrader Channel Product Updated');
          this.processedChannelsCounter(-1);
        },
        (err) => {
          this.spinner.hide();

          var errMsg = err.error != undefined ? err.error.message : err.message;
          this.toastr.error(errMsg, 'Channel CardTrader Error');
          this.processedChannelsCounter(-1);
        }
      );
  }
  updateWooCommeceProduct(requestPayload) {
    this.spinner.show();
    this.inventoryWCSubsription = this.channelsService
      .updateWooCommerceProduct(requestPayload)
      .subscribe(
        (res) => {
          this.spinner.hide();
          this.toastr.success('WooCommerce Channel Product Updated');
          this.processedChannelsCounter(-1);
        },
        (err) => {
          this.spinner.hide();

          var errMsg = err.error != undefined ? err.error.message : err.message;
          this.toastr.error(errMsg, 'WooCommerce CardTrader Error');
          this.processedChannelsCounter(-1);
        }
      );
  }

  getModelValue(val) {
    return val;
  }

  isBChannel(sales_channel_id): boolean {
    const isBC = this.sales_channels.find(
      (x) => x.sales_channel_id == sales_channel_id
    );

    if (isBC?.channel_ref == CHANNEL_REF_ENUM.BC) {
      return true;
    }
    return false;
  }

  onMoreBCDetails(i) {
    const modalRef = this.modalService.open(BcUpdateInventoryComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
    });

    modalRef.componentInstance.product = {
      sales_channel_id: this.links[i].sales_channel_id,
      skuid: this.child_sku,
    };

    this.commonService.modal_close.subscribe((data) => {
      if (data === 'CHANNEL-BC-INVENTORY-MODAL') {
        modalRef.close();
      }
    });
  }

  calculateChannelQuantity(channel) {
    var qtOnHand = 0;
    var qtReserve = 0;
    const channelLocations = this.salesChannels.find(
      (x) =>
        x.sales_channel_id == channel.sales_channel_id &&
        x.sales_channel_locations.length > 0
    )?.sales_channel_locations;

    if (channelLocations?.length > 0) {
      const locations = this.inventoryLocations.filter((l) =>
        channelLocations.some((cl) => cl.store_id == l.store_id)
      );
      qtOnHand = locations.reduce((sum, location) => {
        return sum + parseInt(location.quantity, 10);
      }, 0);

      qtReserve = locations.reduce((sum, location) => {
        return sum + parseInt(location.quantity_reserve, 10);
      }, 0);
    }
    //if no locations, use default inventory
    else {
      qtOnHand = isNaN(parseFloat(this.productForm.value['quantity']))
        ? 0
        : parseFloat(this.productForm.value['quantity']);

      qtReserve = isNaN(parseFloat(this.productForm.value['quantity_reserve']))
        ? 0
        : parseFloat(this.productForm.value['quantity_reserve']);
      var qtSold = isNaN(parseFloat(this.productForm.value['sold']))
        ? 0
        : parseFloat(this.productForm.value['sold']);
      /*if (qtSold == NaN || qtSold == undefined) {
        qtSold = 0;
      }*/
    }

    if (qtOnHand == undefined) {
      this.toastr.error('Quantity on Hand Not Set');
      return null;
    }
    if (qtReserve != undefined) {
      if (qtReserve > qtOnHand) {
        this.toastr.warning('Reserve Quantity should exceed Quantity on Hand');
        return qtOnHand - qtReserve;
      } else {
        return qtOnHand - qtReserve;
      }
    }
  }

  calculateAvailableQuantity() {
    var qtOnHand = isNaN(parseFloat(this.productForm.value['quantity']))
      ? 0
      : parseFloat(this.productForm.value['quantity']);
    var qtReserve = isNaN(
      parseFloat(this.productForm.value['quantity_reserve'])
    )
      ? 0
      : parseFloat(this.productForm.value['quantity_reserve']);
    var qtSold = isNaN(parseFloat(this.productForm.value['sold']))
      ? 0
      : parseFloat(this.productForm.value['sold']);
    /*if (qtSold == NaN || qtSold == undefined) {
      qtSold = 0;
    }*/

    if (qtOnHand == undefined) {
      this.toastr.error('Quantity on Hand Not Set');
      return null;
    }
    if (qtReserve != undefined) {
      if (qtReserve > qtOnHand) {
        this.toastr.warning('Reserve Quantity should exceed Quantity on Hand');
        return qtOnHand - qtReserve;
      } else {
        return qtOnHand - qtReserve;
      }
    }
  }

  processedChannelsCounter(counter: number) {
    if (counter == 0) {
      this.processingChannelsCount = counter;
    } else {
      this.processingChannelsCount += counter;
    }
    this.inventoryService.processing_channel_counter.next(
      this.processingChannelsCount
    );
  }

  validateAmazonAsin($event: any, i: number) {
    var inventoryData = this.productForm.getRawValue();
    var child_sku = inventoryData['child_sku'];

    //$event['channel_id'] = $event.channel_ref_id
    this.channelsService
      .validateAmazonAsin({
        ...$event,
        ...{ child_sku: child_sku, channel_id: $event.channel_ref_id },
      })
      .subscribe(
        (res) => {
          if (res.numberOfResults == undefined || res.numberOfResults < 1) {
            this.setChannelItemError(i, true, res.error);
          }
        },
        (err) => {
          this.toastr.error(err?.error?.error);
          this.setChannelItemError(i, true, err?.error?.error);
        }
      );
  }

  validateChannelId(field_name: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.mantleModalService.confirmDialog(
        'Confirm SKU for Listing',
        'Should the Maltle SKU be used to list this item on the channel?'
      );

      this.confirmSkuSubscription = this.commonService.modal_close.subscribe(
        (data: any) => {
          if (data.name === MANTLE_MODAL_NAME.CONFIRM_DIALOG) {
            if (data.data.action_id == 1) {
              this.spinner.show();
              const frmInventory = this.productForm.getRawValue();
              this.productService
                .updateProductDetails({
                  brand_id: frmInventory.brand_id,
                  'Child Sku': frmInventory.child_sku,
                  [field_name]: frmInventory.child_sku,
                })
                .subscribe(
                  (res) => {
                    this.spinner.hide();
                    this.inventoryService.inventory_item_updated.next({
                      [field_name]: frmInventory.child_sku,
                    });
                    resolve(true);
                  },
                  (err) => {
                    this.spinner.hide();
                    resolve(false);
                  }
                );
            } else {
              resolve(false);
            }
          }
        }
      );
    });
  }

  setChannelItemError(i: number, invalid = false, messsage = '') {
    this.links[i]['invalid'] = invalid;
    this.links[i]['validation_message'] = messsage;
  }

  advancedInventory = () => {
    const modalRef = this.mantleModalService.open(
      MantleInventoryAdvancedComponent,
      MANTLE_MODAL_NAME.INVENTORY_ADVANCED_MODAL,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'xl',
        backdrop: 'static',
      }
    );
    modalRef.componentInstance.inventoryDetails = this.productForm.getRawValue();
  };

  onAddLocation = () => {
    this.inventoryLocations.push({
      store_id: '',
      skuid: this.child_sku,
      quantity: 0,
      quantity_reserve: 0,
      inventory_id: this.productForm.value['inventory_id'],
    });
  };

  onLocationChange = ($event: any, i: number) => {
    this.inventoryLocations[i].store_id = $event['store_id'];

    var exists = this.inventoryLocations.filter(
      (x) => x.store_id == $event['store_id']
    );
    if (exists?.length > 1) {
      this.toastr.warning('Inventory Location Already Added');
      this.onRemoveLocation(i);
    }
  };

  onRemoveLocation = (i: number) => {
    if (this.inventoryLocations[i]['id'] !== undefined) {
      this.spinner.show();
      this.inventoryService
        .deleteInventoryLocation(this.inventoryLocations[i]['id'])
        .subscribe({
          next: (res) => {
            this.toastr.success('Inventory Location removed');
            this.inventoryLocations.splice(i, 1);
          },
          error: (err) => {
            this.spinner.hide();
            this.toastr.error(err, 'An Error Ocurred');
          },
          complete: () => {
            this.spinner.hide();
          },
        });
    } else {
      this.inventoryLocations.splice(i, 1);
    }

    this.updateLocationQuantity(null, -1);
  };

  updateInventoryLocations = async () => {
    this.spinner.show();
    const locationPromises = this.inventoryLocations.map(async (location) => {
      location.inventory_id = this.productForm.value['inventory_id'];
      return lastValueFrom(
        this.inventoryService.upsertInventoryLocation(location)
      );
    });
    Promise.all(locationPromises)
      .then((results) => {
        this.spinner.hide();
        this.inventoryLocations = results ?? [];
      })
      .catch((error) => {
        this.spinner.hide();
        // Handle error if any operation fails
        console.error('Error updating some inventory locations:', error);
      });
  };

  updateLocationQuantity = async ($event, index) => {
    if (index >= 0) {
      this.inventoryLocations[index].quantity_available =
        parseInt(this.inventoryLocations[index].quantity, 10) -
        parseInt(this.inventoryLocations[index].quantity_reserve, 10);
    }
    const totalInventory = this.inventoryLocations.reduce((sum, location) => {
      return sum + parseInt(location.quantity, 10);
    }, 0);

    const totalReseve = this.inventoryLocations.reduce((sum, location) => {
      return sum + parseInt(location.quantity_reserve, 10);
    }, 0);

    this.productForm.patchValue({
      quantity: totalInventory,
      quantity_reserve: totalReseve,
    });
    //TODO - Update quantity for linked channels that have this location
    this.updateProduct($event, 'Quantity on Hand');
  };

  closeModal() {
    this.commonService.modal_close.next(
      MANTLE_MODAL_NAME.PRODUCT_INVENTORY_ONLY_MODAL
    );
  }
  ngOnDestroy(): void {
    /**
     * This was a brute-force to ensure that no active subscription was overlooked
     */
    this.inventorySubsription1?.unsubscribe();
    this.inventorySubsription2?.unsubscribe();
    this.inventorySubsription3?.unsubscribe();
    this.inventorySubsription4?.unsubscribe();
    this.inventorySubsription5?.unsubscribe();
    this.inventorySubsription6?.unsubscribe();
    this.inventorySubsription7?.unsubscribe();
    this.inventorySubsription8?.unsubscribe();
    this.inventorySubsription9?.unsubscribe();
    this.inventorySubsription10?.unsubscribe();
    this.inventorySubsription11?.unsubscribe();
    this.inventoryTCGSubsription?.unsubscribe();
    this.inventoryBCSubsription?.unsubscribe();
    this.inventoryShopifySubsription?.unsubscribe();
    this.inventoryAmazonSubsription?.unsubscribe();
    this.inventoryCTSubsription?.unsubscribe();
    this.confirmSkuSubscription?.unsubscribe();
    this.inventoryWCSubsription?.unsubscribe();
  }
  compareItems(store: any, store_id: any): boolean {
    // Ensure both values are either strings or integers
    return store.store_id == store_id;
  }
}
