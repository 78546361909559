import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
import { CommonService } from 'src/app/utils/services/common.service';
import { InventoryService } from 'src/app/utils/services/inventory.service';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';
import { MantleInventoryTransferDetailsComponent } from './mantle-inventory-transfer-details/mantle-inventory-transfer-details.component';

@Component({
  selector: 'mantle-inventory-transfer',
  templateUrl: './mantle-inventory-transfer.component.html',
  styleUrl: './mantle-inventory-transfer.component.scss',
})
export class MantleInventoryTransferComponent implements OnInit, OnDestroy {
  initialized: boolean = false;
  filterParams: any = {};
  transfersList: Array<any> = [];
  private modalCloseSubscription!: Subscription;
  timezone: string = this.commonService.getTimeZone();

  constructor(
    private inventoryService: InventoryService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private commonService: CommonService,
    private mantleModalService: MantleModalService
  ) {
    this.modalCloseSubscription = this.commonService.modal_close.subscribe(
      (modalName) => {
        if (modalName === MANTLE_MODAL_NAME.INVENTORY_TRANSFER_DETAILS) {
          this.ngOnInit();
        }
      }
    );
  }
  ngOnInit(): void {
    if (!this.initialized) {
      this.initData();
      this.initialized = true;
    }

    this.spinner.show();
    this.inventoryService.getTransfers().subscribe({
      next: (res) => {
        this.transfersList = res.data;
      },
      error: (error) => {
        this.spinner.hide();
      },
      complete: () => {
        this.spinner.hide();
      },
    });
  }
  initData = () => {
    let lastPage = 0;
    let lastSearchText = '';

    try {
      if ($.fn.dataTable.isDataTable('.dataTable')) {
        var tbl = $('.dataTable').DataTable();
        //tbl.destroy();
        //tbl.draw();
        tbl.clear();
        tbl.destroy();
      }
    } catch (error) {
      console.error(error);
    }

    setTimeout(() => {
      $('.dataTable thead tr').clone(true).appendTo('.dataTable thead');
      $('.dataTable thead tr:eq(1) th').each((i, element) => {
        var title = $(element).text();
        var id = $(element).attr('id');
        if (id) {
          $(element).html(
            '<input type="text" style="width:100%" placeholder="Search ' +
              title +
              '" id="' +
              id +
              '"/>'
          );
        }

        $('input', element).on('keyup', (event) => {
          if (event.key === 'Enter') {
            const eValue = (event.target as HTMLInputElement).value;
            if (dataTable.column(i).search() !== eValue) {
              this.filterParams[event.target.id] = eValue;

              if (eValue == undefined || eValue == '') {
                delete this.filterParams[event.target.id];
              }
              dataTable.draw();
            }
          }
        });
      });

      var dataTable = $('.dataTable').DataTable({
        orderCellsTop: true,
        pagingType: 'full_numbers',
        pageLength: 100,
        displayStart: lastPage, // Last Selected Page
        search: { search: lastSearchText }, // Last Searched Text
        serverSide: true,
        processing: true,
        searching: false,
        ajax: (dataTablesParameters: any, callback) => {
          this.spinner.show();
          let offset = dataTablesParameters.start;
          this.inventoryService
            .getTransfers({
              ...this.filterParams,
              ...{
                offset: offset,
                limit: 100,
              },
            })
            .subscribe({
              next: (res) => {
                this.transfersList = res.data;
                callback({
                  recordsTotal: res.count,
                  recordsFiltered: res.count,
                  data: [],
                });
              },
              error: (err) => {
                this.spinner.hide();
              },
              complete: () => {
                this.spinner.hide();
              },
            });
        },
        lengthMenu: [5, 10, 20, 100],
      });
    }, 1);
  };

  viewTransferDetails(transfer_id: any) {
    const modalRef = this.mantleModalService.open(
      MantleInventoryTransferDetailsComponent,
      MANTLE_MODAL_NAME.INVENTORY_TRANSFER_DETAILS,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'xl',
        backdrop: 'static',
      }
    );

    modalRef.componentInstance.transfer_id = transfer_id;
  }

  ngOnDestroy(): void {
    this.modalCloseSubscription?.unsubscribe();
  }
}
