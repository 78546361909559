import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
import { CommonService } from 'src/app/utils/services/common.service';
import { ProductService } from 'src/app/utils/services/product.service';

@Component({
  selector: 'mantle-product-summary',
  templateUrl: './mantle-product-summary.component.html',
  styleUrl: './mantle-product-summary.component.scss',
})
export class MantleProductSummaryComponent implements OnInit, OnDestroy {
  @Input() public data;
  private subscription: Subscription = new Subscription();
  productData: any;

  constructor(
    private commonService: CommonService,
    private productService: ProductService
  ) {}
  onSaveProduct() {
    this.commonService.onSaveProduct.next(true);
  }
  ngOnInit(): void {
    this.subscription.add(
      this.productService
        .getProductDetails({
          brand_id: this.data['brand_id'],
          child_sku: this.data['child_sku'],
          parent_sku: this.data['parent_sku'],
        })
        .subscribe({
          next: (res) => {
            this.productData = this.commonService.JSONNullEmptyCleanup(
              res['mantle_data'],
              true
            );
          },
          error: (err) => {},
          complete: () => {},
        })
    );
  }
  closeModal() {
    this.commonService.modal_close.next(
      MANTLE_MODAL_NAME.PRODUCT_INVENTORY_ONLY_MODAL
    );
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
