<section class="content">
  <div class="container-fluid">
    <ng-container *ngIf="this.has_inventory_data">
      <mantle-product-summary
        [data]="productForm.getRawValue()"
      ></mantle-product-summary>
    </ng-container>
    <div class="card" style="background-color: transparent">
      <form [formGroup]="productForm" (ngSubmit)="addProductInventory()">
        <div class="card-header">
          <h3 class="card-title">Product Inventory</h3>
          <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeModal()">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
        <div class="card-body">
          <ng-container *ngIf="!has_inventory_data">
            <div class="row">
              <div class="form-group col-md-4">
                <input
                  type="hidden"
                  class="form-control form-control-sm"
                  formControlName="id"
                />
                <label for="brand_id" class="control-label"
                  >Product Line *</label
                >
                <ng-select
                  [items]="product_brand"
                  bindLabel="name"
                  bindValue="id"
                  formControlName="brand_id"
                  (change)="changeBrand()"
                >
                </ng-select>
              </div>

              <div class="form-group col-md-6">
                <label for="brand_id" class="control-label"
                  >Product *
                  <small
                    >Type Product name /Set Name / parent or Mantle SKU / Short
                    Code</small
                  ></label
                >
                <ng-select
                  [items]="product_list$ | async"
                  bindLabel="product_name"
                  bindValue="child_sku"
                  formControlName="child_sku"
                  [typeahead]="input$"
                  [loading]="loadingProduct"
                  (change)="changeProduct($event)"
                >
                </ng-select>
              </div>
              <div class="form-group col-md-2">
                <button
                  *ngIf="inventory_only_dialog"
                  type="button"
                  class="btn btn-outline-primary btn-sm mt-4"
                  [disabled]="!productForm.valid"
                  (click)="manageInventory()"
                >
                  Manage Inventory
                </button>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="has_inventory_data">
            <div class="row">
              <div class="col-md-10">
                <div class="row">
                  <div class="form-group col-md-3">
                    <label class="control-label">Quantity on Hand</label>
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      formControlName="quantity"
                      (change)="updateProduct($event, 'Quantity on Hand')"
                      [readOnly]="inventoryLocations?.length > 0"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label class="control-label">Reserve Quantity</label>
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      formControlName="quantity_reserve"
                      [readOnly]="inventoryLocations?.length > 0"
                      (change)="updateProduct($event, 'Reserve Quantity')"
                    />
                  </div>
                  <!--div class="form-group col-md-3">
                    <label class="control-label">Sold</label>
                    <input
                      type="number"
                      readonly
                      class="form-control form-control-sm"
                      formControlName="sold"
                      (change)="updateProduct($event, 'sold')"
                    />
                  </div-->
                  <div class="form-group col-md-3">
                    <label class="control-label">Available For Sale</label>
                    <input
                      type="number"
                      readonly
                      class="form-control form-control-sm"
                      formControlName="available"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label class="control-label">Reorder Point</label>
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      formControlName="reorder_point"
                      (change)="updateProduct($event, 'Reorder Point')"
                    />
                  </div>
                  <!--<div class="form-group col-md-3">
                    <label class="control-label">Product Status</label>
                    <ng-select
                      [items]="product_status"
                      bindLabel="description"
                      bindValue="product_status_id"
                      formControlName="product_status_id"
                    >
                    </ng-select>
                  </div>-->
                </div>
              </div>
              <div class="col-md-2">
                <div class="row">
                  <div class="form-group col-md-12">
                    <label class="control-label small">&nbsp;</label>
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-danger form-control form-control-sm"
                    >
                      <i class="fas fa-radiation-alt text-danger"></i> Disable
                      Product
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!--<div class="row">
              <div class="form-group col-md-3">
                <label class="control-label">Currency</label>
                <ng-select
                  [items]="currency"
                  bindLabel="description"
                  bindValue="currency_code"
                  formControlName="currency_code"
                >
                </ng-select>
              </div>
              <div class="form-group col-md-3">
                <label class="control-label">Sale Price</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  formControlName="sell_price"
                  (change)="updateProduct($event, 'Sale Price')"
                />
              </div>
              <div class="form-group col-md-3">
                <label class="control-label">Buylist Price</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  formControlName="buy_price"
                />
              </div>
              <div class="form-group col-md-3">
                <label class="control-label">Average Cost</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  formControlName="avg_cost"
                />
              </div>
            </div>-->
            <!--<div class="row">
              <div class="form-group col-md-3">
                <label class="control-label">Reorder Point</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  formControlName="reorder_point"
                  (change)="updateProduct($event, 'Reorder Point')"
                />
              </div>
              <!-div class="form-group col-md-3">
                <label class="control-label">Discount %</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  formControlName="discount"
                  max="100"
                  min="1"
                />
              </div->
              <!-<div class="form-group col-md-3">
                <label class="control-label">Picking Bin</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  formControlName="picking_bin"
                  (change)="updateProduct($event, 'Picking Bin')"
                />
              </div>->
              <!-div class="form-group col-md-3">
                <label class="control-label">ASIN</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  formControlName="asin"
                  (change)="updateProduct($event, 'ASIN')"
                />
              </div->
            </div>-->
            <div class="row">
              <div class="col-md-12">
                <div class="card" style="background-color: transparent">
                  <div class="card-header">
                    <h6 class="card-title">Inventory Locations</h6>
                    <div class="card-tools">
                      <button
                        type="button"
                        class="btn btn-outline-primary btn-sm rounded-circle"
                        style="width: 30px; height: 30px"
                        (click)="onAddLocation()"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12 table-responsive small">
                        <table
                          class="table table-striped table-sm hover dataTable"
                        >
                          <thead>
                            <tr>
                              <th>Inventory Location</th>
                              <th>Qty On Hand (PO)</th>
                              <th>Qty Reserve</th>
                              <th>Available for Sale</th>
                              <th>Reorder Point</th>
                              <th>Picking Bin</th>
                              <th>&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="
                                let location of inventoryLocations;
                                let i = index
                              "
                            >
                              <td class="form-group">
                                <ng-select
                                  [items]="storeList"
                                  bindLabel="store_name"
                                  bindValue="store_id"
                                  [(ngModel)]="location.store_id"
                                  (change)="onLocationChange($event, i)"
                                  [ngModelOptions]="{ standalone: true }"
                                  [compareWith]="compareItems"
                                >
                                </ng-select>
                              </td>
                              <td class="form-group">
                                <input
                                  type="number"
                                  [(ngModel)]="location.quantity"
                                  class="form-control form-control-sm"
                                  [ngModelOptions]="{ standalone: true }"
                                  (change)="updateLocationQuantity($event, i)"
                                />
                              </td>
                              <td class="form-group">
                                <input
                                  type="number"
                                  [(ngModel)]="location.quantity_reserve"
                                  class="form-control form-control-sm"
                                  [ngModelOptions]="{ standalone: true }"
                                  (change)="updateLocationQuantity($event, i)"
                                />
                              </td>
                              <td class="form-group">
                                <input
                                  type="number"
                                  [(ngModel)]="location.quantity_available"
                                  class="form-control form-control-sm"
                                  [readOnly]="true"
                                  [ngModelOptions]="{ standalone: true }"
                                />
                              </td>
                              <td class="form-group">
                                <input
                                  type="number"
                                  [(ngModel)]="location.reorder_point"
                                  class="form-control form-control-sm"
                                  [ngModelOptions]="{ standalone: true }"
                                />
                              </td>
                              <td class="form-group">
                                <input
                                  type="text"
                                  [(ngModel)]="location.picking_bin"
                                  class="form-control form-control-sm"
                                  [ngModelOptions]="{ standalone: true }"
                                />
                              </td>
                              <td class="form-group">
                                <button
                                  type="button"
                                  class="btn btn-outline-danger btn-sm rounded-circle"
                                  style="width: 30px; height: 30px"
                                  [disabled]="processingChannelsCount != 0"
                                  (click)="onRemoveLocation(i)"
                                >
                                  <i
                                    class="fas fa-trash"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="card" style="background-color: transparent">
                  <div class="card-header">
                    <h6 class="card-title">Sales Channels</h6>
                    <div class="card-tools">
                      <button
                        type="button"
                        class="btn btn-outline-primary btn-sm mr-2 rounded-circle"
                        style="width: 30px; height: 30px"
                        (click)="onAddLink()"
                      >
                        <i class="fa fa-plus" aria-hidden="true"></i>
                      </button>

                      <button
                        type="button"
                        class="btn btn-outline-info btn-sm"
                        (click)="advancedInventory()"
                      >
                        Advanced inventory data
                      </button>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12 table-responsive small">
                        <table
                          class="table table-striped table-sm hover dataTable"
                        >
                          <thead>
                            <tr>
                              <th>Channel Name</th>
                              <th>Sell Price</th>
                              <th>Reserve</th>
                              <th>Qty Listed</th>
                              <th>Channel ON/OF</th>
                              <th>&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="let link of links; let i = index"
                              [ngClass]="{
                                border: link.invalid,
                                'border-danger': link.invalid
                              }"
                            >
                              <ng-container *ngIf="link.invalid">
                                <div class="col-md-12">
                                  <div class="alert alert-warning" role="alert">
                                    {{ link.validation_message }}
                                  </div>
                                </div>
                              </ng-container>

                              <td class="form-group">
                                <ng-select
                                  [items]="sales_channels"
                                  bindLabel="sales_channel_description"
                                  bindValue="sales_channel_id"
                                  [(ngModel)]="link.sales_channel_id"
                                  (change)="onChannelChange($event, i)"
                                  [ngModelOptions]="{ standalone: true }"
                                  [disabled]="link.id"
                                  [appendTo]="'body'"
                                >
                                </ng-select>
                              </td>
                              <td class="form-group">
                                <input
                                  type="number"
                                  [(ngModel)]="link.price"
                                  class="form-control form-control-sm"
                                  [ngModelOptions]="{ standalone: true }"
                                />
                              </td>
                              <td class="form-group">&nbsp;</td>
                              <td class="form-group">
                                <input
                                  type="number"
                                  [(ngModel)]="link.quantity"
                                  class="form-control form-control-sm"
                                  [ngModelOptions]="{ standalone: true }"
                                  readonly
                                />
                              </td>
                              <td class="form-group">
                                <ng-toggle
                                  [value]="link.sales_channel?.sales_channel_status ?? 0"
                                  [width]="40"
                                  [color]="{
                                    unchecked: '#FF0000',
                                    checked: '#1ba841'
                                  }"
                                  [labels]="{ checked: '', unchecked: '' }"
                                  style="pointer-events: none"
                                ></ng-toggle>
                              </td>
                              <td class="form-group">
                                <button
                                  type="button"
                                  class="btn btn-outline-danger btn-sm rounded-circle"
                                  style="width: 30px; height: 30px"
                                  [disabled]="processingChannelsCount != 0"
                                  (click)="onRemoveLink(i)"
                                >
                                  <i
                                    class="fas fa-trash"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <!--  <div
                      class="row"
                      *ngFor="let link of links; let i = index"
                      [ngClass]="{
                        border: link.invalid,
                        'border-danger': link.invalid
                      }"
                    >
                      <div class="col-md-12">
                        <div class="row">
                          <div class="form-group col-md-3">
                            <label class="control-label">Sales Channel</label>
                            <ng-select
                              [items]="sales_channels"
                              bindLabel="sales_channel_description"
                              bindValue="sales_channel_id"
                              [(ngModel)]="link.sales_channel_id"
                              (change)="onChannelChange($event, i)"
                              [ngModelOptions]="{ standalone: true }"
                              [disabled]="link.id"
                            >
                            </ng-select>
                          </div>
                          <div class="form-group col-md-2">
                            <label class="control-label">Listed Quantity</label>
                            <input
                              type="number"
                              [(ngModel)]="link.quantity"
                              class="form-control form-control-sm"
                              [ngModelOptions]="{ standalone: true }"
                              readonly
                            />
                          </div>
                          <div class="form-group col-md-2">
                            <label class="control-label">Price</label>
                            <input
                              type="number"
                              [(ngModel)]="link.price"
                              class="form-control form-control-sm"
                              [ngModelOptions]="{ standalone: true }"
                            />
                          </div>
                          <div class="form-group col-md-2">
                            <br />
                            <button
                              type="button"
                              class="btn btn-outline-info btn-sm mt-2"
                              (click)="onUpdateChannel(i)"
                              [disabled]="processingChannelsCount != 0"
                            >
                              Update Channel
                              <i class="fas fa-edit" aria-hidden="true"></i>
                            </button>
                          </div>
                          <div
                            class="form-group col-md-1"
                            *ngIf="isBChannel(link.sales_channel_id)"
                          >
                            <br />
                            <button
                              type="button"
                              class="btn btn-outline-info btn-sm mt-2"
                              (click)="onMoreBCDetails(i)"
                            >
                              More <i class="fa fa-cog" aria-hidden="true"></i>
                            </button>
                          </div>
                          <div class="form-group col-md-2">
                            <br />
                            <button
                              type="button"
                              class="btn btn-outline-danger btn-sm mt-2"
                              [disabled]="processingChannelsCount != 0"
                              (click)="onRemoveLink(i)"
                            >
                              Remove
                              <i class="fas fa-trash" aria-hidden="true"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <ng-container *ngIf="link.invalid">
                        <div class="col-md-12">
                          <div class="alert alert-warning" role="alert">
                            {{ link.validation_message }}
                          </div>
                        </div>
                      </ng-container>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="card-footer text-muted" *ngIf="has_inventory_data">
          <div class="row">
            <div class="col-md-6">
              <button
                type="submit"
                class="btn btn-outline-success btn-sm w-50"
                [disabled]="
                  !productForm.valid || processingChannelsCount != 0
                    ? true
                    : false
                "
              >
                Save
              </button>
            </div>
            <div class="col-md-6">
              <!--button type="reset" class="btn btn-outline-secondary btn-sm w-50">Reset</button-->
            </div>
          </div>
        </div>
      </form>
    </div>
    <div *ngIf="this.has_inventory_data">
      <mantle-product-update
        [data]="productForm.getRawValue()"
      ></mantle-product-update>
    </div>
  </div>
</section>
