<form [formGroup]="templateForm">
  <div class="card">
    <div class="card-header">
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="closeModal()">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <h5>Email Template - {{ templateForm?.value?.template_ref }}</h5>
      <i class="small">Template Id: {{ templateForm?.value?.template_id }}</i>
    </div>
    <div class="card-body">
      <div class="row small">
        <div class="col-md-12">
          <ul>
            <li>
              How to Begin Creating Dynamic Email Templates:
              <a
                href="https://handlebarsjs.com/guide/#simple-expressions"
                target="_blank"
                title=""
                >Here</a
              >
            </li>
            <li>
              The data object <strong>must include a "to" </strong>. Multiple
              recipients should be separated using a semicolon (`;`).
            </li>
          </ul>
          <hr />
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label small">Template Name</label>
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="name"
            maxlength="50"
          />
        </div>
        <div class="form-group col-md-6">
          <label class="control-label small">Ref</label>
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="template_ref"
            maxlength="10"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label small">Subject</label>
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="subject"
          />
        </div>
        <div class="form-group form-check col-md-2">
          <div class="icheck-primary">
            <input
              type="checkbox"
              class="form-check-input"
              id="use_report"
              name="use_report"
              formControlName="use_report"
            />

            <label class="control-label small" for="use_report"
              >Use Report</label
            >
          </div>
        </div>
        <div class="form-group col-md-4">
          <label class="control-label small">Template Report</label>
          <ng-select
            [items]="templateReports"
            [multiple]="false"
            bindLabel="name"
            bindValue="report_id"
            formControlName="report_id"
          >
          </ng-select>
        </div>
      </div>
      <div class="row">
        <div class="form-group form-check col-md-2">
          <div class="icheck-primary">
            <input
              type="checkbox"
              class="form-check-input"
              id="scheduled"
              name="scheduled"
              formControlName="scheduled"
              [disabled]="true"
            />

            <label class="control-label small" for="scheduled">Scheduled</label>
          </div>
        </div>
        <div class="form-group col-md-4">
          <label class="control-label small">Frequency</label>
          <ng-select
            [items]="[]"
            [multiple]="false"
            bindLabel="key"
            bindValue="value"
            formControlName="frequency"
            disabled="true"
          >
          </ng-select>
        </div>
        <div class="form-group col-md-3">
          <label class="control-label small">From Date</label>
          <input
            type="datetime-local"
            class="form-control form-control-sm"
            formControlName="from_date"
            disabled="true"
            IsoDateLocal
          />
        </div>
        <div class="form-group col-md-3">
          <label class="control-label small">To Date</label>
          <input
            type="datetime-local"
            class="form-control form-control-sm"
            formControlName="to_date"
            disabled="true"
            IsoDateLocal
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-12">
          <label class="control-label small">Template</label>
          <editor
            formControlName="template"
            apiKey="ajith4u7097a7gw9jkcxng64dnarla672x7m7k51zpjpwnu2"
            [init]="{
              plugins: 'code preview autolink lists link image charmap anchor searchreplace visualblocks fullscreen insertdatetime table',
              toolbar:
              'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | code preview',
              extended_valid_elements: 'style[type|media]',
              valid_children: '+body[style]', 
              valid_elements: '*[*]',
            }"
          ></editor>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-12">
          <label class="control-label small">Data</label> <br />
          <ngx-monaco-editor
            [options]="editorOptions"
            formControlName="data"
          ></ngx-monaco-editor>

          <!--json-editor
            formControlName="data"
            style="min-height: 300px"
            [options]="editorOptions"
          >
          </json-editor-->
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-12">
          <label class="control-label small"
            >Query Filter <i>(For purposetesting only)</i></label
          >
          <br />
          <ngx-monaco-editor
            [options]="editorOptions"
            formControlName="query_params"
          ></ngx-monaco-editor>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-4">
          <button
            type="button"
            class="btn btn-sm btn-info w-50"
            (click)="onPreview()"
          >
            <i class="fas fa-check-double"></i> Preview
          </button>
        </div>
        <div class="form-group col-md-4">
          <button
            type="button"
            class="btn btn-sm btn-info w-50"
            (click)="onSave()"
          >
            <i class="fas fa-check-double"></i> Save
          </button>
        </div>
        <div class="form-group col-md-4">
          <button
            type="button"
            class="btn btn-sm btn-info w-50"
            (click)="onSendEmail()"
          >
            <i class="fas fa-check-double"></i> Send Email
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
