import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  Validators,
  FormBuilder,
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  UntypedFormBuilder,
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MANTLE_MODAL_NAME } from 'src/app/utils/enums/mantle-enums';
import { CommonService } from 'src/app/utils/services/common.service';
import { ProductService } from 'src/app/utils/services/product.service';
import { StorageService } from 'src/app/utils/services/storage.service';
//import formConfig from '../../../../configs/forms/magic_form.json';
import { VendorService } from 'src/app/utils/services/vendor.service';
import { catchError, Subscription } from 'rxjs';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';
import { MantleConfirmDialogComponent } from '../../mantle-confirm-dialog/mantle-confirm-dialog.component';

@Component({
  selector: 'mantle-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProductEditComponent implements OnInit, OnDestroy {
  @Input() fields: any;
  @Input() product: any;
  @Input() isNewRecord: boolean;
  @Input() brand_id: number;
  @Input() duplicateData: boolean = false;
  @Input() duplicateChildSKU: any;
  child_sku: any;
  newRecordJustCreated: boolean = false;
  private subscription: Subscription = new Subscription();
  model: any = {};

  productDetailsForm = this.fb.group({
    brand_id: ['', Validators.required],
  });
  options: FormlyFormOptions = {
    formState: {
      vendors: [],
    },
  };

  constructor(
    private fb: UntypedFormBuilder,
    private commonService: CommonService,
    //private productsService: ProductsService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private storageService: StorageService,
    private productService: ProductService,
    private vendorService: VendorService,
    private mantleModalService: MantleModalService
  ) {}

  ngOnInit(): void {
    this.productService.getFormConfig(this.brand_id).subscribe((res) => {
      //this.fields = formConfig;
      this.fields = res;
      this.getLookupValues();
    });

    this.vendorService.getVendors().subscribe({
      next: (res) => {
        this.options.formState.vendors = res.data.map((x) => {
          return { value: x.vendor_id, label: x.name };
        });
      },
      error: (err) => {},
      complete: () => {},
    });

    this.subscription.add(
      this.commonService.tabularInputRemoved.subscribe((data) => {
        if (data['name'] == 'vendor_product_ids') {
          this.removeVendorIdentifier(data['index'], data['autoRemove']);
        }
      })
    );
  }

  ngAfterViewInit() {}

  getLookupValues = () => {
    this.spinner.show();

    const obs = this.commonService.getLookupValues();
    obs.subscribe(
      (res) => {
        this.spinner.hide();

        this.product['Product Line'] = res['product_brand']?.find(
          (x) => x.id.toString() == this.brand_id?.toString()
        )?.name;

        setTimeout(() => {
          this.productDetailsForm.patchValue(this.product);
          this.setControls();
        }, 1);

        this.duplicateProduct();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  };

  setControls() {
    Object.keys(this.productDetailsForm.controls).forEach((field) => {
      if (this.canEditControl(field))
        this.productDetailsForm.get(field).enable();
      if (field == 'Child Sku') {
        this.productDetailsForm.get(field).valueChanges.subscribe((value) => {
          this.validateSku(value);
        });
      }
    });
  }

  canEditControl(fieldName) {
    if (fieldName == 'Product Line') return false;
    else if (fieldName == 'Child Sku' && !this.isNewRecord) return false;
    else if (fieldName == 'Mantle SKU' && !this.isNewRecord) return false;
    return true;
  }

  getFormData() {
    return this.productDetailsForm.getRawValue();
  }

  updateProductDetails() {
    this.spinner.show();
    this.productService
      .updateProductDetails({
        ...this.getFormData(),
        ...{ is_new_record: this.isNewRecord },
      })
      .subscribe(
        (res) => {
          this.spinner.hide();
          this.toastr.success('Data Saved Successfully');
          if (this.isNewRecord) {
            this.newRecordJustCreated = true;
          }

          this.model = res; //For formArray to work
          this.productDetailsForm.patchValue(res);
          this.isNewRecord = false;
          this.setControls();
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err?.error?.message);
        }
      );
  }

  getProductdetails(copy = false) {
    this.spinner.show();
    this.productService
      .getProductDetails({
        brand_id: this.brand_id,
        child_sku: this.child_sku,
      })
      .subscribe(
        (res) => {
          this.spinner.hide();

          var result = {
            ...this.commonService.JSONNullEmptyCleanup(res?.client_data, true),
            ...this.commonService.JSONNullEmptyCleanup(res?.mantle_data, true),
          };

          if (copy) {
            delete result['Child Sku'];
          }
          this.productDetailsForm.patchValue(result);
        },
        (err) => {
          this.spinner.hide();

          this.toastr.error(JSON.stringify(err));
        }
      );
  }

  validateSku(child_sku) {
    this.productService
      .getProductDetails({
        brand_id: this.brand_id,
        child_sku: child_sku,
      })
      .subscribe((res) => {
        if (res.mantle_data != null) {
          if (!this.newRecordJustCreated) {
            this.toastr.warning(`Product with SKU ${child_sku} already exists`);
          }
          this.isNewRecord = false;
        } else {
          this.isNewRecord = true;
        }
      });
  }

  duplicateProduct() {
    if (this.duplicateData) {
      this.child_sku = this.duplicateChildSKU;
      this.getProductdetails(this.duplicateData);
    }
  }

  removeVendorIdentifier = (index, autoRemove = false) => {
    var vendorId = this.productDetailsForm.getRawValue().vendor_product_ids[
      index
    ];
    if (!autoRemove) {
      const modalRef = this.mantleModalService.open(
        MantleConfirmDialogComponent,
        MANTLE_MODAL_NAME.CONFIRM_DIALOG,
        {
          ariaLabelledBy: 'modal-basic-title',
          size: 'sm',
          backdrop: 'static',
        }
      );
      modalRef.componentInstance.header = 'Remove Vendor Indetifier';
      modalRef.componentInstance.message =
        'By confriming this, Vendor Indetifier item will be deleted';
      var deleteSubscription = this.commonService.modal_close.subscribe(
        (data: any) => {
          if (data.name === MANTLE_MODAL_NAME.CONFIRM_DIALOG) {
            if (data.data.action_id == 1) {
              if (vendorId.id != undefined) {
                //delete
                this.spinner.show();
                this.productService
                  .deleteProductVendorId(vendorId.id)
                  .subscribe({
                    next: (res) => {
                      this.spinner.hide();
                    },
                    error: (err) => {
                      this.spinner.hide();
                      this.toastr.error(err.error.message);
                    },
                    complete: () => {
                      this.spinner.hide();
                      deleteSubscription.unsubscribe();
                    },
                  });
              }
              //this.frmFilters().removeAt(index);
            } else {
              //return back the item to list
              this.model.vendor_product_ids.splice(index, 0, vendorId);
            }
            modalRef.close();
          }
        }
      );
      this.subscription.add(deleteSubscription);
    } else {
      if (vendorId.id != undefined) {
        //delete
        this.spinner.show();
        this.productService.deleteProductVendorId(vendorId.id).subscribe({
          next: (res) => {
            this.spinner.hide();
          },
          error: (err) => {
            this.spinner.hide();
            this.toastr.error(err.error.message);
          },
          complete: () => {
            this.spinner.hide();
            deleteSubscription.unsubscribe();
          },
        });
      }
    }
  };

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  closeModal() {
    this.commonService.modal_close.next(MANTLE_MODAL_NAME.PRODUCT_EDIT_MODAL);
  }
}
