<form [formGroup]="configForm">
  <div class="card">
    <div class="card-header">
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="closeModal()">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <h5>WooCommerce sales channel</h5>
    </div>
    <div class="card-body">
      <div class="row small">
        <div class="col-md-12">
          <span
            >How can I acquire WooCommerce Authentication Keys? <br />
            <a
              href="https://woocommerce.github.io/woocommerce-rest-api-docs/#rest-api-keys"
              target="_blank"
              title=""
              >REST API keys</a
            ></span
          >
          <hr />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label small">Description</label>
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="channel_wc_description"
            maxlength="50"
          />
        </div>
        <div class="form-group col-md-6">
          <label class="control-label small">Inventory Store Location</label>
          <ng-select
            [items]="storeList"
            [multiple]="true"
            bindLabel="store_name"
            bindValue="store_id"
            formControlName="store_ids"
          >
          </ng-select>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label small">WordPress Url</label>
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="base_url"
          />
        </div>
        <div class="form-group col-md-6">
          <label class="control-label small">Port</label>
          <input
            type="number"
            class="form-control form-control-sm"
            formControlName="port"
          />
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label small">Consumer Key</label>
          <input
            type="password"
            class="form-control form-control-sm"
            formControlName="consumer_key"
          />
        </div>
        <div class="form-group col-md-6">
          <label class="control-label small">Consumer Secret</label>
          <input
            type="password"
            class="form-control form-control-sm"
            formControlName="consumer_secret"
          />
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label small">Version</label>
          <ng-select
            [items]="api_versions"
            bindLabel="value"
            bindValue="id"
            formControlName="version"
          >
          </ng-select>
        </div>
        <div class="form-group col-md-6">
          <label class="control-label small">Store Id</label>
          <input
            type="text"
            class="form-control form-control-sm"
            readonly
            formControlName="wp_store_id"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label small">Orders Since</label>
          <input
            type="datetime-local"
            class="form-control form-control-sm"
            formControlName="created_at_min"
            IsoDateLocal
          />
        </div>
        <div class="form-group col-md-6">
          <label class="control-label small">Update Frequency (Minutes)</label>
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="update_frequency"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <button
            type="button"
            class="btn btn-sm btn-info w-50"
            (click)="onValidate()"
            [disabled]="!configForm.valid"
          >
            <i class="fas fa-check-double"></i> Validate
          </button>
        </div>
        <div class="form-group col-md-6">
          <button
            *ngIf="validated && !configForm.value.channel_wc_id"
            type="button"
            class="btn btn-sm btn-success w-50"
            (click)="onSave()"
            [disabled]="!configForm.valid"
          >
            <i class="far fa-save"></i> Save
          </button>
          <button
            *ngIf="validated && configForm.value.channel_wc_id"
            type="button"
            class="btn btn-sm btn-success w-50"
            (click)="onUpdate()"
            [disabled]="!configForm.valid"
          >
            <i class="far fa-save"></i> Update
          </button>
          <button
            *ngIf="configForm.value.channel_wc_id"
            type="button"
            class="btn btn-sm btn-success w-50"
            (click)="onUpdateFrequency()"
          >
            <i class="far fa-save"></i> Update Frequency
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
