import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppState } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';
import {
  selectStateStatus,
  selectTemplateList,
  selectTemplateByid,
} from './state/mailing_template.selectors';
import {
  MANTLE_MODAL_NAME,
  STATE_STATUS,
} from 'src/app/utils/enums/mantle-enums';
import {
  deleteEmailTemplate,
  loadEmailTemplates,
  sendEmail,
} from './state/mailing_template.actions';
import { MantleEmailTemplateDetailsComponent } from '../mantle-email-template-details/mantle-email-template-details.component';

@Component({
  selector: 'mantle-email-template-list',
  templateUrl: './mantle-email-template-list.component.html',
  styleUrl: './mantle-email-template-list.component.scss',
})
export class MantleEmailTemplateListComponent implements OnInit, OnDestroy {
  public templateList$: Observable<any>;
  constructor(
    private store: Store<AppState>,
    private spinner: NgxSpinnerService,
    private mantleModalService: MantleModalService
  ) {
    this.templateList$ = this.store.select(selectTemplateList);

    this.store.select(selectStateStatus).subscribe({
      next: (status) => {
        if (status == STATE_STATUS.LOADING) {
          this.spinner.show();
        } else {
          this.spinner.hide();
        }
      },
    });
    this.store.dispatch(loadEmailTemplates());
  }
  viewDetails(template_id: any) {
    const tSub = this.store
      .select(selectTemplateByid(template_id))
      .subscribe((template) => {
        this.onNewTemplate(template);
      });
    tSub.unsubscribe();
  }
  ngOnInit(): void {}
  ngOnDestroy(): void {}
  onNewTemplate(template = null) {
    const modalRef = this.mantleModalService.open(
      MantleEmailTemplateDetailsComponent,
      MANTLE_MODAL_NAME.EMAIL_TEMPLATE_DETAILS,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'xl',
        backdrop: 'static',
      }
    );
    modalRef.componentInstance.template = template;
  }

  onDelete(template_id: any) {
    this.mantleModalService.confirmDialog(
      `Delete Email Template`,
      'By confirming this action, The selected email template will be removed.',
      this.onDeleteConfirmed,
      [template_id],
      'fas fa-exclamation-triangle me-2',
      'alert alert-warning'
    );
  }
  onDeleteConfirmed = (template_id) => {
    this.store.dispatch(deleteEmailTemplate({ template_id }));
  };

  onSendEmail(template_id: any) {
    this.mantleModalService.confirmDialog(
      `Send Email`,
      'By confirming this action, The email template will be send to the specified recipients.',
      this.sendEmail,
      [template_id],
      'fas fa-exclamation-triangle me-2',
      'alert alert-warning'
    );
  }

  sendEmail = (template_id) => {
    const requestPayload = { template_id: template_id };
    this.store.dispatch(sendEmail({ requestPayload }));
  };
}
