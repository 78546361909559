<ng-container *ngFor="let order of orderList">
  <div class="row small">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">{{ order.status_description }}</h5>

          <div class="card-tools">
            <button
              type="button"
              class="btn btn-tool"
              data-card-widget="collapse"
              (click)="onCollapseToggle($event, order.order_status_id)"
            >
              <i class="fas fa-minus"></i>
            </button>
          </div>
        </div>
        <!-- /.card-header -->

        <div class="card-body" id="{{ order.order_status_id }}">
          <div class="row">
            <div class="col-md-12">
              <!-- Table row -->
              <div class="row">
                <div class="col-md-12 table-responsive">
                  <table class="table table-striped dataTable">
                    <thead>
                      <tr>
                        <td>&nbsp;</td>
                        <th>Order No#</th>
                        <th>Date</th>
                        <th>Vendor</th>
                        <th>Status</th>
                        <th>Total</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let statusOrders of order.orders">
                        <tr>
                          <td>
                            <input
                              type="checkbox"
                              class="select-checkbox"
                              (change)="
                                checkOrder($event, statusOrders.order_id)
                              "
                            />
                          </td>
                          <td>{{ statusOrders.order_number }}</td>
                          <td>
                            {{
                              statusOrders.order_date | date: 'short':timezone
                            }}
                          </td>
                          <td>
                            {{ statusOrders?.vendor?.name }}
                          </td>
                          <td>
                            {{ statusOrders?.purchase_status?.description }}
                          </td>
                          <td>
                            {{ statusOrders.currency_code }}
                            {{ statusOrders.order_total }}
                          </td>
                          <td>
                            <div class="dropdown" data-bs-toggle="dropdown">
                              <button
                                class="btn btn-outline btn-sm dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                Action Order
                              </button>
                              <div
                                class="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <button
                                  type="button"
                                  class="dropdown-item"
                                  (click)="
                                    viewOrderDetails(statusOrders.order_id)
                                  "
                                >
                                  <i class="fas fa-info-circle"></i> View
                                  Details
                                </button>
                                <button
                                  type="button"
                                  class="dropdown-item"
                                  (click)="viewInvoice(statusOrders.order_id)"
                                >
                                  <i class="fas fa-file-invoice"></i> Print
                                  Invoice
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
                <!-- /.col -->
              </div>
              <!-- /.row -->

              <!-- /.chart-responsive -->
            </div>
            <!-- /.col -->

            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- ./card-body -->
      </div>
      <!-- /.card -->
    </div>
    <!-- /.col -->
  </div>
  <!-- /.row -->
</ng-container>
