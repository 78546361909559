import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MailingTemplateState } from 'src/app/utils/interfaces/app.state';

export const selectMailingTemplateState = createFeatureSelector<MailingTemplateState>(
  'mailingTemplate'
);
export const selectStateStatus = createSelector(
  selectMailingTemplateState,
  (state) => state.status
);

export const selectTemplateList = createSelector(
  selectMailingTemplateState,
  (state) => state.templates
);
export const selectedActiveRecord = createSelector(
  selectMailingTemplateState,
  (state) => state.activeRecord
);

export const selectTemplateByid = (template_id) =>
  createSelector(selectMailingTemplateState, (state) =>
    state.templates.find((t) => t.template_id === template_id)
  );
