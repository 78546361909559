import { Component, Input, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  UntypedFormBuilder,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileSaverService } from 'ngx-filesaver';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/utils/services/api.service';
import { CommonService } from 'src/app/utils/services/common.service';
declare var $: any;

@Component({
  selector: 'app-mantle-subscription',
  templateUrl: './mantle-subscription.component.html',
  styleUrls: ['./mantle-subscription.component.scss'],
})
export class MantleSubscriptionComponent implements OnInit {
  @Input() public task_data;

  constructor(
    private fb: UntypedFormBuilder,
    private apiService: ApiService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private _FileSaverService: FileSaverService
  ) {}

  subscriptionForm = this.fb.group({
    task_id: [],
    task_description: ['', Validators.required],
    brand_id: ['', Validators.required],
    set_id: [],
    condition_id: [],
    rarity_id: [],
    language: [],
    product_name: [],
    is_active: [],
    frequency_id: ['', Validators.required],
  });

  //data = {}
  is_update = false;
  product_brand = [];
  product_sets = [];
  product_sets_filtered: any = [];
  product_conditions = [];
  product_conditions_filtered: any = [];
  product_rarity = [];
  product_rarity_filtered: any = [];
  frequencies = [];
  download_logs: any = [];
  task_description = '';
  active: any;

  ngOnInit(): void {
    this.init_values();
  }

  init_values() {
    this.getLookupValues();

    this.getData();

    this.changeBrand();
  }

  getLookupValues() {
    this.spinner.show();

    const obs = this.commonService.getLookupValues();
    obs.subscribe(
      (res) => {
        this.spinner.hide();

        this.product_brand = res['product_brand'];
        this.product_sets = res['product_set'];
        this.product_conditions = res['product_condition'];
        this.product_rarity = res['product_rarity'];
        this.frequencies = res['task_frequency'];

        this.changeBrand();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  getData() {
    if (this.task_data != null) {
      this.is_update = true;
      this.active = this.task_data.is_active == 1 ? 0 : 1;
      var form_data = {};
      var filter_params = JSON.parse(this.task_data.filter_params);
      let key;

      for (key in filter_params) {
        if (filter_params.hasOwnProperty(key)) {
          form_data[key] = filter_params[key];
        }
      }

      for (key in this.task_data) {
        if (this.task_data.hasOwnProperty(key)) {
          form_data[key] = this.task_data[key];
        }
      }

      this.subscriptionForm.patchValue(form_data);

      this.task_description = this.subscriptionForm.value['task_description'];

      this.getTaskHist();
    }
  }

  getTaskHist() {
    this.spinner.show();
    const task_id = this.subscriptionForm.value['task_id'];
    this.apiService.get('/api/v1/task/hist/' + task_id).subscribe({
      next: (res) => {
        this.download_logs = res;
      },
      error: (err) => {
        this.spinner.hide();
      },
      complete: () => {
        this.spinner.hide();
      },
    });
  }

  onSubmitSubscription() {
    this.spinner.show();
    var request_payload = this.subscriptionForm.value;
    request_payload.is_active = 1;
    this.apiService.post('/api/v1/task/schedule', request_payload).subscribe({
      next: (res) => {
        if (res.length > 0) {
          this.task_data = res[0];
          this.getData();
        }
      },
      error: (err) => {
        this.spinner.hide();
      },
      complete: () => {
        this.spinner.hide();
      },
    });
  }

  onUpdateSubscription(active = null) {
    this.spinner.show();
    var request_payload = this.subscriptionForm.value;
    if (active != null) {
      request_payload.is_active = active;
    }
    this.apiService.patch('/api/v1/task', request_payload).subscribe({
      next: (res) => {
        if (res.length > 0) {
          this.task_data = res[0];
          this.getData();
        }
      },
      error: (err) => {
        this.spinner.hide();
      },
      complete: () => {
        this.spinner.hide();
      },
    });
  }

  onSubmitDraftSubscription() {
    this.spinner.show();
    var request_payload = this.subscriptionForm.value;
    request_payload.is_active = 0;

    this.apiService
      .post('/api/v1/task/schedule', this.subscriptionForm.value)
      .subscribe({
        next: (res) => {
          if (res.length > 0) {
            this.task_data = res[0];
            this.getData();
          }
        },
        error: (err) => {
          this.spinner.hide();
        },
        complete: () => {
          this.spinner.hide();
        },
      });
  }

  onDeleteSubscription() {
    var task_id = this.subscriptionForm.value['task_id'];
    if (task_id) {
      this.apiService.delete('/api/v1/task/' + task_id).subscribe({
        next: (res) => {
          this.closeModal();
        },
        error: (err) => {
          this.spinner.hide();
        },
        complete: () => {
          this.spinner.hide();
        },
      });
    }
  }

  onRefreshTable() {
    //this.getLookupValues()
    this.init_values();
  }
  onDownloadHistCSV(task_hist_id) {
    const file_name = this.subscriptionForm.value['task_description'] + '.csv';
    this.spinner.show();
    this.apiService
      .download('/api/v1/task/download/' + task_hist_id)
      .subscribe({
        next: (res) => {
          this._FileSaverService.save(<any>res, file_name);
        },
        error: (err) => {
          this.spinner.hide();
        },
        complete: () => {
          this.spinner.hide();
        },
      });
  }

  changeBrand() {
    var brand_id = this.subscriptionForm.value['brand_id'];
    if (brand_id) {
      this.product_sets_filtered = this.product_sets.filter(
        (x) => x.parent == brand_id && x.name && x.name.trim()
      );
      this.product_conditions_filtered = this.product_conditions.filter(
        (x) => x.parent == brand_id && x.name && x.name.trim()
      );
      this.product_rarity_filtered = this.product_rarity.filter(
        (x) => x.parent == brand_id && x.name && x.name.trim()
      );
    }
  }

  changeSet() {}
  closeModal() {
    this.modalService.dismissAll();
  }
}
