import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import {
  MANTLE_MODAL_NAME,
  REPORTING_LOOKUP,
} from 'src/app/utils/enums/mantle-enums';
import { AppState } from 'src/app/utils/interfaces/app.state';
import { CommonService } from 'src/app/utils/services/common.service';
import { selectedActiveRecord } from '../mantle-email-template-list/state/mailing_template.selectors';
import {
  sendEmail,
  upsertEmailTemplate,
} from '../mantle-email-template-list/state/mailing_template.actions';
import { ReportingService } from 'src/app/utils/services/reporting.service';
import { MantleModalService } from 'src/app/utils/services/mantle-modal.service';

@Component({
  selector: 'mantle-email-template-details',
  templateUrl: './mantle-email-template-details.component.html',
  styleUrl: './mantle-email-template-details.component.scss',
})
export class MantleEmailTemplateDetailsComponent implements OnInit {
  /*public editorOptions: JsonEditorOptions;
  @ViewChild(JsonEditorComponent, { static: false })
  editor: JsonEditorComponent;*/
  @Input() template: any;
  private subscription: Subscription = new Subscription();
  public editorOptions = {
    theme: 'vs-light', // Light theme
    language: 'json', // JSON language
    automaticLayout: true, // Adjust layout automatically
    tabSize: 2, // Indentation with 2 spaces
    formatOnType: true, // Format code as you type
    formatOnPaste: true, // Format code when pasted
    validate: true, // Enable validation for JSON
  };
  code: any;
  templateReports: any = [];
  constructor(
    private fb: UntypedFormBuilder,
    private commonService: CommonService,
    private reportingService: ReportingService,
    private mantleModalService: MantleModalService,
    private store: Store<AppState>
  ) {
    this.subscription.add(
      this.store.select(selectedActiveRecord).subscribe({
        next: (template) => {
          if (template != null) this.templateForm.patchValue(template);
        },
      })
    );

    this.subscription.add(
      this.reportingService.getReports({ report_type_id: '5' }).subscribe({
        next: (res) => {
          this.templateReports = res.data;
        },
      })
    );
    //this.editorOptions = new JsonEditorOptions();
    //this.editorOptions.modes = ['code', 'tree', 'view'];
  }
  ngOnInit(): void {
    if (this.template != undefined) this.templateForm.patchValue(this.template);
  }
  templateForm = this.fb.group({
    template_id: [''],
    template_ref: [],
    name: [''],
    subject: [''],
    template: [''],
    report_id: [],
    use_default_sender: [true],
    from: [],
    scheduled: [false],
    use_report: [true],
    frequency: [],
    from_date: [],
    to_date: [],
    data: [
      '{"to":"test@email.com;test2@email.com", "subject":"test", "item1":"items"}',
    ],
    query_params: ['{"key":"value"}'],
  });

  onPreview() {
    throw new Error('Method not implemented.');
  }
  onSave() {
    var requestPayload = this.templateForm.getRawValue();
    if (requestPayload.template_id == null || requestPayload.template_id == '')
      delete requestPayload.template_id;

    this.store.dispatch(upsertEmailTemplate(requestPayload));
  }
  onSendEmail = () => {
    this.onSave();
    this.mantleModalService.confirmDialog(
      `Send Email`,
      'By confirming this action, The email template will be send to the specified recipients.',
      this.sendEmail,
      [],
      'fas fa-exclamation-triangle me-2',
      'alert alert-warning'
    );
  };
  sendEmail = () => {
    var requestPayload = this.templateForm.getRawValue();
    requestPayload = {
      template_id: requestPayload.template_id,
      query_params: JSON.parse(requestPayload.query_params),
    };
    this.store.dispatch(sendEmail({ requestPayload }));
  };

  closeModal() {
    this.commonService.modal_close.next(
      MANTLE_MODAL_NAME.EMAIL_TEMPLATE_DETAILS
    );
  }
}
