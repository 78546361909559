<section [ngClass]="!notDialog ? 'content' : ''">
  <div [ngClass]="!notDialog ? 'container-fluid' : ''">
    <div [ngClass]="!notDialog ? 'card small' : 'small'">
      <form [formGroup]="productSearchForm">
        <div class="card-header" *ngIf="!notDialog">
          <h3 class="card-title">Search Product Inventory</h3>
          <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeModal()">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
        <div class="card-body" [ngClass]="!notDialog ? 'card-body' : ''">
          <div class="row">
            <div class="form-group col-md-4">
              <label for="brand_id" class="control-label">Product Line *</label>
              <ng-select
                [items]="product_brand"
                bindLabel="name"
                bindValue="id"
                formControlName="brand_id"
                (change)="onChangeProductLine($event)"
              >
              </ng-select>
            </div>

            <div class="form-group col-md-6">
              <label for="brand_id" class="control-label"
                >Product *
                <small
                  >Type Product name /Set Name / parent or Mantle SKU / Short
                  Code</small
                ></label
              >
              <ng-select
                [items]="product_list$ | async"
                bindLabel="product_name"
                bindValue="child_sku"
                formControlName="child_sku"
                [typeahead]="input$"
                [loading]="loadingProduct"
                (change)="changeProduct($event)"
              >
              </ng-select>
            </div>
            <div class="form-group col-md-2">
              <button
                type="button"
                class="btn btn-outline-primary btn-sm mt-4"
                [disabled]="!productSearchForm.valid"
                (click)="onSelectProduct()"
              >
                Add Item
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
