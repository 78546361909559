<form [formGroup]="configForm">
  <div class="card">
    <div class="card-header">
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="closeModal()">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <h5>POS sales channel</h5>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label small">Description</label>
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="channel_pos_description"
            maxlength="50"
          />
        </div>
        <div class="form-group col-md-6">
          <label class="control-label small">Inventory Store Location</label>
          <ng-select
            [items]="storeList"
            [multiple]="true"
            bindLabel="store_name"
            bindValue="store_id"
            formControlName="store_ids"
            [maxSelectedItems]="1"
          >
          </ng-select>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label small">Orders Since</label>
          <input
            type="datetime-local"
            readonly
            class="form-control form-control-sm"
            formControlName="created_at_min"
            IsoDateLocal
          />
        </div>
        <div class="form-group col-md-6">
          <label class="control-label small">Update Frequency (Minutes)</label>
          <input
            type="text"
            readonly
            class="form-control form-control-sm"
            formControlName="update_frequency"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 text-muted mb-2">
          <h6>Linked Cash Registers</h6>
        </div>
        <div class="col-md-6">
          <button
            type="button"
            class="btn btn-outline-primary btn-sm"
            (click)="onAddCashRegister()"
          >
            Add <i class="fa fa-plus" aria-hidden="true"></i>
          </button>
        </div>
      </div>
      <div class="row" *ngFor="let link of linkedCashRegisters; let i = index">
        <div class="col-md-12">
          <div class="row">
            <div class="form-group col-md-6">
              <label class="control-label">Cash Register</label>
              <ng-select
                [disabled]="link.cash_register_id"
                [items]="cashRegisterList"
                bindLabel="description"
                bindValue="cash_register_id"
                [(ngModel)]="link.cash_register_id"
                (change)="onChangeRegister($event, i)"
                [ngModelOptions]="{ standalone: true }"
              >
              </ng-select>
            </div>
            <div class="form-group col-md-3">
              <br />
              <button
                type="button"
                class="btn btn-outline-primary btn-sm mt-2"
                (click)="onViewRegister(i)"
              >
                View <i class="fa fa-eye" aria-hidden="true"></i>
              </button>
            </div>
            <div class="form-group col-md-3">
              <br />
              <button
                type="button"
                class="btn btn-outline-danger btn-sm mt-2"
                (click)="onRemoveRegister(i)"
              >
                Remove <i class="fas fa-trash" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <button
            type="button"
            class="btn btn-sm btn-info w-50"
            (click)="onValidate()"
            [disabled]="!configForm.valid"
          >
            <i class="fas fa-check-double"></i> Validate
          </button>
        </div>
        <div class="form-group col-md-3">
          <button
            *ngIf="configForm.value.channel_pos_id"
            type="button"
            class="btn btn-sm btn-info"
            (click)="onAllocateInventories()"
          >
            <i class="fas fa-code-branch"></i> Allocate to Inventories
          </button>
        </div>
        <div class="form-group col-md-3">
          <button
            *ngIf="validated && !configForm.value.channel_pos_id"
            type="button"
            class="btn btn-sm btn-success w-50"
            (click)="onSave()"
            [disabled]="!configForm.valid"
          >
            <i class="far fa-save"></i> Save
          </button>
          <button
            *ngIf="validated && configForm.value.channel_pos_id"
            type="button"
            class="btn btn-sm btn-success w-50"
            (click)="onUpdate()"
            [disabled]="!configForm.valid"
          >
            <i class="far fa-save"></i> Update
          </button>
          <button
            *ngIf="configForm.value.channel_pos_id"
            type="button"
            class="btn btn-sm btn-success w-50"
            (click)="onUpdateFrequency()"
          >
            <i class="far fa-save"></i> Update Frequency
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
